import React, { useState, useEffect, useRef } from 'react';
import { getI18n, useTranslation } from 'react-i18next';

import { useFormatCurrency } from '@krea/common/hooks';
import {
  requestedRealEstateLoanAmountValuesDefault,
  requestedRealEstateLoanAmountValuesLarge,
  requestedLoanAmountValuesDefault,
  requestedLoanAmountValuesLarge,
  requestedLoanAmountValues,
} from '@krea/common/services/formService';
import { settings } from '@krea/common/settings';
import { amountValuesList, COUNTRY_CODE } from '@krea/common/utils';

import { FormatNumber } from '../../Formatters';
import Slider from '../../Slider';
import LabeledRadioButton from '../labeled-radio-button';

const selector = (isRealEstate: boolean, countryCode: typeof COUNTRY_CODE) => {
  const i18n = getI18n();
  const productType = !isRealEstate ? 'loanApplication' : 'realEstate';
  const [, currency] = useFormatCurrency({ currency: settings.currency });

  return [
    {
      title: i18n.t(
        `applicationForm.commons.sliderSelector.${productType}.defaultTitle.${countryCode}`,
        { currency },
      ),
      slider: 'default',
    },
    {
      title: i18n.t(
        `applicationForm.commons.sliderSelector.${productType}.largeTitle.${countryCode}`,
        { currency },
      ),
      slider: 'large',
    },
  ];
};

const AmountSliderSelector = ({
  onChange,
  currentSlider,
  isRealEstate,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  currentSlider: string;
  isRealEstate: boolean;
}) => {
  return (
    <div className="row mb-4 mx-n2">
      {selector(isRealEstate, settings.countryCode).map(
        ({ title, slider }, index) => (
          <div key={index} className="col-12 col-md-6 px-2 d-flex">
            <LabeledRadioButton
              title={title}
              name="amount-radio"
              value={slider}
              checked={currentSlider === slider}
              onChange={onChange}
            />
          </div>
        ),
      )}
    </div>
  );
};

// Same as Formatters.FormatCurrency but hacked to show "Välj belopp" instead of 0
const customFormatCurrency = (
  value: string | number,
  formattedCurrencyValue: (value: string | number) => string,
) => {
  const { t } = getI18n();

  if (value === '' || value === 0) {
    return t('applicationForm.commons.requestedAmountLabel');
  }

  const formattedValue = formattedCurrencyValue(value);

  return formattedValue ? formattedValue.replace(/\./g, ' ') : formattedValue; // edge formats with dots instead of spaces
};

const AmountSlider = ({
  name,
  value,
  touched,
  isPreliminaryBid,
  label,
  isRealEstate,
  setInputValue,
  errors,
  shouldAnimate = true,
}: {
  name?: string;
  value: number;
  touched: boolean;
  errors?: string;
  isRealEstate: boolean;
  isPreliminaryBid?: boolean;
  label?: string;
  shouldAnimate?: boolean;
  setInputValue: (name: string, value: number, shouldValidate: boolean) => void;
}) => {
  const { t } = useTranslation();

  const LoanAmountValues = () => {
    const values = (ratio: number) => ({
      default: amountValuesList(
        !isRealEstate
          ? isPreliminaryBid
            ? requestedLoanAmountValues
            : requestedLoanAmountValuesDefault
          : requestedRealEstateLoanAmountValuesDefault,
        ratio,
      ),
      extended: amountValuesList(
        !isRealEstate
          ? isPreliminaryBid
            ? requestedLoanAmountValues
            : requestedLoanAmountValuesLarge
          : requestedRealEstateLoanAmountValuesLarge,
        ratio,
      ),
    });

    switch (settings.countryCode) {
      case COUNTRY_CODE.SE:
        return values(1);

      case COUNTRY_CODE.FI:
        return values(10);

      default:
        return null;
    }
  };

  const [sliderValue, setSliderValue] = useState(
    !isRealEstate ? value : LoanAmountValues()!.default[11],
  );
  const [isCustomRequestedLoan, setIsCustomRequestedLoan] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const [currentSlider, setCurrentSlider] = useState('default');
  const [initSelector, setInitSelector] = useState(false);

  const [sliderValues, setSliderValues] = useState(LoanAmountValues()!.default);

  const currency = settings.currency;
  const [formattedCurrencyValue, currencyValue] = useFormatCurrency({
    currency,
  });

  const sliderTimer = useRef(null);
  const disableAnimationSliderTimer = useRef(null);

  const requestedLoanAmount = (value: number, shouldValidate: boolean) => {
    const inputName = name || 'requested_loan_amount';
    setInputValue(inputName, value, shouldValidate);
  };

  const disableAnimation = () => {
    setIsAnimating(false);
  };

  const animationSlider = (startValue: number, endValue: number) => {
    // Animate slider to `initialRequestedLoan` in these cases
    setSliderValue(startValue);
    requestedLoanAmount(startValue, false);

    (sliderTimer.current as unknown as NodeJS.Timeout) = setTimeout(() => {
      setIsAnimating(true);
      setSliderValue(endValue);
      requestedLoanAmount(endValue, false);
      (disableAnimationSliderTimer.current as unknown as NodeJS.Timeout) =
        setTimeout(disableAnimation, 500);
    }, 900);

    initSelector && setIsAnimating(true);
  };

  useEffect(() => {
    setSliderValues(
      currentSlider === 'default'
        ? LoanAmountValues()!.default
        : LoanAmountValues()!.extended,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlider]);

  useEffect(() => {
    if (touched || !shouldAnimate) {
      const sliderSegment = sliderValues?.find((num) => num === value);

      if (!sliderSegment) {
        onRequestedLoanToggle(currentSlider);
      } else {
        setSliderValue(sliderSegment);
        requestedLoanAmount(sliderSegment, false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlider]);

  useEffect(() => {
    // Handle setting initial values when not using slider when initial animation
    if (!shouldAnimate) {
      let valueToSet = value;

      if (!isCustomRequestedLoan) {
        if (currentSlider === 'default') {
          let max =
            LoanAmountValues()!.default[LoanAmountValues()!.default.length - 1];
          let min = LoanAmountValues()!.default[0];

          // if value is not between min and max
          if (value && (value < min || value > max)) {
            if (isRealEstate) {
              valueToSet = LoanAmountValues()!.default[11];
            } else {
              valueToSet = LoanAmountValues()!.default[15];
            }
          }
        } else {
          let max =
            LoanAmountValues()!.extended[
              LoanAmountValues()!.extended.length - 1
            ];
          let min = LoanAmountValues()!.extended[0];

          // if value is not between min and max.
          if (value && (value < min || value > max)) {
            if (isRealEstate) {
              valueToSet = LoanAmountValues()!.extended[6];
            } else {
              valueToSet = LoanAmountValues()!.extended[5];
            }
          }
        }
      }

      setSliderValue(valueToSet);
      requestedLoanAmount(valueToSet, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlider, isCustomRequestedLoan, value]);

  useEffect(() => {
    // Handle animation
    if (shouldAnimate) {
      if (currentSlider === 'default') {
        !isRealEstate
          ? animationSlider(
              0,
              touched ? value : LoanAmountValues()!.default[15],
            )
          : animationSlider(0, LoanAmountValues()!.default[11]);
      } else {
        !isRealEstate
          ? animationSlider(
              LoanAmountValues()!.extended[0],
              touched ? value : LoanAmountValues()!.extended[5],
            )
          : animationSlider(
              LoanAmountValues()!.extended[0],
              LoanAmountValues()!.extended[6],
            );
      }
    }

    return () => {
      if (sliderTimer.current) {
        clearTimeout(sliderTimer.current);
      }

      if (disableAnimationSliderTimer.current) {
        clearTimeout(disableAnimationSliderTimer.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlider]);

  const onRequestedLoanToggle = (currentSlider: string) => {
    if (isCustomRequestedLoan) {
      const number = numberClosestToValue(
        sliderValues!,
        sliderValue!,
        currentSlider,
      );
      setSliderValue(number);
      requestedLoanAmount(number, false);
    }

    setIsCustomRequestedLoan(!isCustomRequestedLoan);
  };

  const onInputLoanAmountChange = ({ target }: { target: EventTarget }) => {
    const parsed = parseFloat(
      (target as HTMLInputElement).value.trim().replace(/\s/g, ''),
    );
    const final = Number.isNaN(parsed) ? 0 : parsed;

    setSliderValue(final);
    requestedLoanAmount(final, false);
  };

  const onSliderLoanAmountChange = ([value]: Array<number>) => {
    const sliderValue =
      currentSlider === 'default'
        ? LoanAmountValues()!.default[value]
        : LoanAmountValues()!.extended[value];

    setSliderValue(sliderValue);
    requestedLoanAmount(sliderValue!, false);
  };

  const numberClosestToValue = (
    numbers: Array<number>,
    value: number,
    currentSlider: string,
  ) => {
    return numbers.reduce((prev, curr) => {
      // return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
      return value < numbers[currentSlider === 'default' ? 1 : 0]
        ? numbers[currentSlider === 'default' ? 1 : 0]
        : Math.abs(curr - value) < Math.abs(prev - value)
          ? curr
          : prev;
    });
  };

  return (
    <div>
      {!isPreliminaryBid && (
        <AmountSliderSelector
          currentSlider={currentSlider}
          isRealEstate={isRealEstate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentSlider(e.target.value);
            setInitSelector(true);
          }}
        />
      )}

      <Slider
        id="loan-amount-slider"
        label={label || t('applicationForm.commons.requestedAmountPreLabel')}
        name={name || 'requested_loan_amount'}
        htmlFor="requestedLoanAmount"
        labelClassName="align-items-center"
        onSliderChange={onSliderLoanAmountChange}
        onValueChange={onInputLoanAmountChange}
        value={sliderValue! < 0 ? 0 : sliderValue}
        values={sliderValues}
        formatter={(valueForFormat: string | number) =>
          customFormatCurrency(valueForFormat, formattedCurrencyValue)
        }
        inputFormatter={FormatNumber}
        placeholder={t(
          'applicationForm.commons.exactRequestedAmountPlaceholder',
          {
            currency: settings.currency,
          },
        )}
        postfix={value !== 0 ? currencyValue : ''}
        isCustomInput={isCustomRequestedLoan}
        onCustomToggle={() => onRequestedLoanToggle(currentSlider)}
        error={touched && errors ? errors : ''}
        animating={isAnimating}
        inputOnBlur={() => {}}
      />
    </div>
  );
};

export default AmountSlider;
