import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Preloader from '@krea/common/shared-components/preloader';

import { useSelectCurrentLoanApplication } from 'store/current-loan-application/hooks/mutations';
import { APP_ABSOLUTE_PATHS } from 'utils';

export const CurrentApplicationPage = () => {
  const { loanApplicationID } = useParams();
  const { mutate: changeCurrentLoanApplication } =
    useSelectCurrentLoanApplication();
  const navigate = useNavigate();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectPath = searchParams.get('path');
  const path =
    APP_ABSOLUTE_PATHS[redirectPath as keyof typeof APP_ABSOLUTE_PATHS];

  const handleRedirect = useCallback(() => {
    if (path) {
      navigate(path);
    } else {
      navigate('/');
    }
  }, [navigate, path]);

  useEffect(() => {
    if (loanApplicationID) {
      changeCurrentLoanApplication(
        { loanApplicationID },
        {
          onSuccess: () => {
            handleRedirect();
          },
        },
      );
    }
  }, []);

  return (
    <Preloader
      className="text-primary bg-white position-fixed w-100 h-100"
      size="xl"
    />
  );
};
