import { useTranslation } from 'react-i18next';

import Button from '@krea/common/shared-components/button';
import {
  DialogActions,
  DialogContent,
} from '@krea/common/shared-components/dialog';
import { useModalStore } from '@krea/common/store/modal/hooks';

import { useSelectCurrentLoanApplication } from 'store/current-loan-application/hooks/mutations';
import { useGetCurrentLoanApplication } from 'store/current-loan-application/hooks/queries';
import { useGetLoanApplications } from 'store/loan-applications/hooks/queries';

import {
  ApplicationsSelectorItem,
  ApplicationsSelectorList,
} from '../applications-selector';

const ChangeApplicationModal = () => {
  const { t } = useTranslation();
  const closeModal = useModalStore(({ closeModal }) => closeModal);

  const { data: activeLoanApplications = [] } = useGetLoanApplications();
  const { data: { id: currentLoanApplicationID } = {} } =
    useGetCurrentLoanApplication();
  const { mutate: changeCurrentLoanApplication } =
    useSelectCurrentLoanApplication();

  const onChangeCurrentApplication = (id) => {
    if (currentLoanApplicationID !== id) {
      changeCurrentLoanApplication({ loanApplicationID: id });
    }
  };

  return (
    <>
      <DialogContent>
        <ApplicationsSelectorList>
          {activeLoanApplications.map((item) => (
            <ApplicationsSelectorItem
              key={item.id}
              data={item}
              checked={item.id === currentLoanApplicationID}
              onClick={() => onChangeCurrentApplication(item.id)}
            />
          ))}
        </ApplicationsSelectorList>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => closeModal()}
          id="modal-cancel-button"
        >
          {t('applicationSelector.cancel')}
        </Button>
      </DialogActions>
    </>
  );
};

export default ChangeApplicationModal;
