import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import LayoutOneColumn from '@krea/common/shared-components/app/one-column';
import LayoutOneColumnHeader from '@krea/common/shared-components/app/one-column/LayoutOneColumnHeader';
import { FieldRequestForm } from '@krea/common/shared-components/field-request/GetFieldRequestForm';
import Preloader from '@krea/common/shared-components/preloader';
import { Text } from '@krea/common/shared-components/text';
import { useGetCachedCurrentLoanApplication } from '@krea/common/store/current-loan-application/hooks';
import { useGetDelegatedFieldRequests } from '@krea/common/store/field-requests/hooks/queries';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';
import { ICurrentLoanApplication } from '@krea/common/types/loanApplication';
import { IFieldRequest } from '@krea/common/types/fieldRequest';

export const DelegatedInformation = () => {
  const { t } = useTranslation();
  const { data: { firstName, lastName } = {} } = useGetUserData();
  const { data: currentLoanApplication = {} } =
    useGetCachedCurrentLoanApplication();
  const { data: fieldRequests = [], isLoading } =
    useGetDelegatedFieldRequests();

  const customerCompany =
    (currentLoanApplication as ICurrentLoanApplication)?.applyingCompany
      ?.customerCompany || {};

  if (isLoading && isEmpty(customerCompany)) {
    return (
      <Preloader
        className={classnames(
          'tw-text-primary tw-bg-white tw-fixed tw-w-full tw-h-full',
        )}
        size="xl"
      />
    );
  }

  return (
    <LayoutOneColumn>
      <LayoutOneColumnHeader title={t('delegatedInformationPage.heading')} />

      <div className="tw-pb-8">
        {customerCompany && (
          <Text className="tw-mb-9 xl:tw-mb-12 tw-font-bold tw-text-center xl:tw-text-left">
            {firstName} {lastName} {t('delegatedInformationPage.description')}{' '}
            {customerCompany.name}.
          </Text>
        )}
        {fieldRequests.map((step: IFieldRequest, i: number) => (
          <div
            className={classnames({
              'tw-mb-7 xl:tw-mb-8': fieldRequests.length !== i + 1,
            })}
            key={step.field}
          >
            <section className="tw-flex tw-flex-col">
              <h3 className="tw-text-[1.25rem] tw-pr-9 tw-order-0">
                {step.displayName}
              </h3>

              <div
                className={classnames(
                  'tw-relative tw-border tw-border-primary tw-p-6 tw-w-full tw-order-2 tw-mt-1 tw-rounded-[10px]',
                )}
              >
                {step.subtitle && (
                  <Text size="sm" className="tw-mb-3 tw-font-bold">
                    {step.subtitle}
                  </Text>
                )}
                <FieldRequestForm isDelegated={true} fieldRequestID={step.id} />
              </div>
            </section>
          </div>
        ))}
      </div>
    </LayoutOneColumn>
  );
};
