import { isNumber, toNumber, isNaN as _isNaN } from 'lodash';
import { useCallback, useMemo } from 'react';

import { getPrimaryLanguage } from '@krea/common/utils';

const defaultOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const useFormatCurrency = (options?: Intl.NumberFormatOptions) => {
  const locale = getPrimaryLanguage();

  const getValueWithCurrency = useCallback(
    (value: string | number | null) => {
      if (value !== null && typeof value !== 'undefined') {
        if (isNumber(value)) {
          return new Intl.NumberFormat(locale, {
            ...defaultOptions,
            ...options,
          }).format(value);
        } else {
          const valueToNumber = toNumber(value);

          if (_isNaN(valueToNumber)) {
            return '';
          } else {
            return new Intl.NumberFormat(locale, {
              ...defaultOptions,
              ...options,
            }).format(valueToNumber);
          }
        }
      } else {
        return '';
      }
    },
    [locale, options],
  );

  const currency = useMemo(() => {
    if (!options?.currency) return null;
    const locale = getPrimaryLanguage();

    return new Intl.NumberFormat(locale, { ...defaultOptions, ...options })
      .formatToParts(0)
      .find(({ type }) => type === 'currency')?.value;
  }, [options]);

  return [getValueWithCurrency, currency] as const;
};
