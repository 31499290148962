import i18n from 'i18next';
import * as Yup from 'yup';
import { isUrlRegex } from '../utils';

export const LeasingValidation = () => {
  return Yup.object().shape(
    {
      leasing_amount: Yup.string().required(
        `${i18n.t('applicationForm.leasing.fields.amount.errors.required')}`,
      ),
      leasing_object_type: Yup.string().required(
        `${i18n.t('applicationForm.leasing.fields.objectType.errors.required')}`,
      ),
      leasing_object_offer_url: Yup.string()
        .when('leasing_object_offer_files', {
          is: (val: number) => val < 1,
          then: Yup.string().defined(
            `${i18n.t(
              'applicationForm.leasing.fields.offer.links.link.errors.empty',
            )}`,
          ),
          otherwise: Yup.string().optional().nullable(),
        } as any)
        .matches(isUrlRegex, {
          excludeEmptyString: true,
          message: i18n.t(
            'applicationForm.leasing.fields.offer.links.link.errors.notUrl',
          ),
        }),
      leasing_object_offer_files: Yup.array().when('leasing_object_offer_url', {
        is: (val: string) => val === '',
        then: Yup.array().min(
          1,
          `${i18n.t('applicationForm.leasing.fields.offer.errors.empty')}`,
        ),
        otherwise: Yup.array().optional().nullable(),
      } as any),
    },
    [
      ['leasing_amount', ''],
      ['leasing_object_type', ''],
      ['leasing_object_offer_url', 'leasing_object_offer_files'],
    ],
  );
};

export const LeasingBaseValidation = () => {
  return Yup.object().shape({
    leasing_amount: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(
        `${i18n.t('applicationForm.leasing.fields.amount.errors.required')}`,
      ),
    leasing_object_type: Yup.string().required(
      `${i18n.t('applicationForm.leasing.fields.objectType.errors.required')}`,
    ),
  });
};
