import i18n from 'i18next';
import * as Yup from 'yup';

export const UnpaidInvoicesBaseValidation = () => {
  return Yup.object().shape({
    estimatedInvoiceAmount: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(
        `${i18n.t(
          'applicationForm.commons.unpaidInvoicesValidation.unpaidInvoicesEstimatedRequired',
        )}`,
      )
      .typeError(
        `${i18n.t(
          'applicationForm.commons.unpaidInvoicesValidation.unpaidInvoicesEstimatedInvalid',
        )}`,
      ),

    numberOfInvoices: Yup.number()
      .typeError(
        `${i18n.t(
          'applicationForm.commons.unpaidInvoicesValidation.numberOfUnpaidInvoicesInvalid',
        )}`,
      )
      .required(
        `${i18n.t(
          'applicationForm.commons.unpaidInvoicesValidation.numberOfUnpaidInvoicesRequired',
        )}`,
      ),
    averagePaymentTimeDays: Yup.number(),
  });
};

export const UnpaidInvoicesDescriptionValidation = () => {
  return Yup.string().required(
    `${i18n.t(
      'applicationForm.commons.unpaidInvoicesValidation.descriptionUnpaidInvoicesRequired',
    )}`,
  );
};
