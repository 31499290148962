import { useQuery } from '@tanstack/react-query';

import { hasOngoingApplication } from '@krea/common/store/current-loan-application/api';
import { HAS_ONGOING_APPLICATION_STORE_KEY } from '@krea/common/store/queryClient';

export const useHasOngoingApplication = (
  countryCode,
  email,
  organisationNumber,
) => {
  return useQuery({
    queryKey: [
      HAS_ONGOING_APPLICATION_STORE_KEY,
      countryCode,
      email,
      organisationNumber,
    ],
    queryFn: async () => {
      try {
        const hasHit = await hasOngoingApplication(
          countryCode,
          email,
          organisationNumber,
        );

        return { hasHit };
      } catch (err) {
        console.error('Failed to check ongoing application: err', err);

        return {};
      }
    },
    enabled: !!countryCode && !!email && !!organisationNumber,
  });
};
