import React from 'react';
import { Navigate } from 'react-router-dom';

import { LOAN_APPLICATION_STATES } from '@krea/common/utils';

import { useGetCurrentLoanApplication } from 'store/current-loan-application/hooks/queries';

const {
  DRAFT,
  CREATED,
  OPEN_FOR_BIDS,
  REJECTED,
  SUBJECT_TO_EU_SANCTIONS,
  NO_BIDS_RECEIVED,
  BIDS_REJECTED_BY_CUSTOMER,
  BID_SELECTED,
  PAYOUT_RECEIVED_BY_CUSTOMER,
  ADVICE,
} = LOAN_APPLICATION_STATES;

function RootRedirect() {
  const { data: { state } = {} } = useGetCurrentLoanApplication();

  switch (state) {
    case DRAFT:
      return <Navigate replace={true} to="improve-application-questions" />;
    case CREATED:
    case OPEN_FOR_BIDS:
    case REJECTED:
    case SUBJECT_TO_EU_SANCTIONS:
    case NO_BIDS_RECEIVED:
    case BIDS_REJECTED_BY_CUSTOMER:
      return <Navigate replace={true} to="dashboard" />;
    case BID_SELECTED:
    case PAYOUT_RECEIVED_BY_CUSTOMER:
      return <Navigate replace={true} to="selected-lender-response" />;
    case ADVICE:
      return <Navigate replace={true} to="advice" />;
    default:
      return <Navigate replace={true} to="/application" />;
  }
}

export default RootRedirect;
