import classnames from 'classnames';
import { size, filter, forEach, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useMobile } from '@krea/common/hooks';
import ProgressBarItem from '@krea/common/shared-components/app/progress-bar/ProgressBarItem';
import ProgressBarMobile from '@krea/common/shared-components/app/progress-bar/ProgressBarMobile';
import { useGetFieldRequests } from '@krea/common/store/field-requests/hooks/queries';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';
import {
  ALLOWED_STATES_FOR_DASHBOARD,
  ALLOWED_STATES_FOR_SELECTED_BID,
  LOAN_APPLICATION_STATES,
  getFilteredFieldRequests,
} from '@krea/common/utils';

import { MenuContext } from 'components/header';
import { useGetCurrentLoanApplication } from 'store/current-loan-application/hooks/queries';

import styles from './ProgressBar.module.scss';

const DesktopStepItem = ({
  step,
  state,
  number,
  disabled,
  activeOnlyWhenExact,
  last,
  isTitle,
  count,
  hideStepsMenu,
}) => {
  const isAllowedForRoute = () => {
    if (step.id === 'dashboard') {
      return ALLOWED_STATES_FOR_DASHBOARD.includes(state);
    } else if (step.id === 'selected') {
      return ALLOWED_STATES_FOR_SELECTED_BID.includes(state);
    } else if (step.id === 'advice') {
      return state === LOAN_APPLICATION_STATES.ADVICE;
    }

    return true;
  };

  return (
    <ProgressBarItem
      step={step}
      number={number}
      last={last}
      isAllowed={isAllowedForRoute()}
      disabled={disabled}
      link={step.link}
      activeOnlyWhenExact={activeOnlyWhenExact}
      isTitle={isTitle}
      count={count}
      hideStepsMenu={hideStepsMenu}
    />
  );
};

const ImproveApplicationQuestionsDescription = () => {
  const { t } = useTranslation();
  const { data: { triageFields } = {} } = useGetCurrentLoanApplication();
  const { data: fieldRequests = [] } = useGetFieldRequests();

  const triageFieldRequests = getFilteredFieldRequests(
    triageFields,
    fieldRequests,
  );
  const triageFRCount = size(triageFieldRequests);
  const triageSubmittedFRCount = size(
    filter(triageFieldRequests, (fieldRequest) => fieldRequest.isSubmitted),
  );

  if (!triageFRCount) {
    return null;
  }

  return t('commons.processSteps.improveLoanApplicationSubheading', {
    numAnswered: triageSubmittedFRCount,
    numQuestions: triageFRCount,
  });
};

const ProgressBar = ({ className, menuForMobile, isOpen, hideStepsMenu }) => {
  const { t } = useTranslation();
  const { data: user = {} } = useGetUserData();
  const { data: { state } = {} } = useGetCurrentLoanApplication();

  const dashboardOrAdvice =
    state === LOAN_APPLICATION_STATES.ADVICE ? 'advice' : 'dashboard';

  const steps = [
    {
      id: 'preview',
      link: '/direct/improve-application-questions',
      title: t('commons.processSteps.improveLoanApplication'),
      description: t('commons.processSteps.improveLoanApplicationSubheading', {
        numAnswered: 0,
        numQuestions: 0,
      }),
      onlyAuthenticated: false,
    },
    {
      id: dashboardOrAdvice,
      link: `/direct/${dashboardOrAdvice}`,
      title: t('commons.processSteps.compareBids'),
      description: '',
      onlyAuthenticated: true,
    },
    {
      id: 'selected',
      link: '/direct/selected-lender-response',
      title: t('commons.processSteps.bidSelected'),
      description: '',
      onlyAuthenticated: true,
    },
  ];

  const isMobile = useMobile();
  const location = useLocation();

  forEach(steps, (step) => {
    if (step.link === '/direct/improve-application-questions') {
      if (
        state === LOAN_APPLICATION_STATES.DRAFT &&
        location.pathname !== '/direct/improve-application-questions/preview'
      ) {
        step.description = <ImproveApplicationQuestionsDescription />;
      } else {
        delete step.description;
      }
    }

    if (
      isEmpty(user) &&
      step.link === '/direct/improve-application-questions'
    ) {
      switch (true) {
        case location.pathname === '/direct/loan-application-form':
          step.link = '/direct/loan-application-form';
          break;
        case location.pathname === '/direct/factoring-application-form':
          step.link = '/direct/factoring-application-form';
          break;
        case location.pathname === '/direct/real-estate-application-form':
          step.link = '/direct/real-estate-application-form';
          break;
        case location.pathname === '/direct/leasing-application-form':
          step.link = '/direct/leasing-application-form';
          break;
        default:
          step.link = '/direct/loan-application-form';
      }
    }
  });

  return !isMobile ? (
    <ul
      className={classnames(
        styles.desktopList,
        'd-flex align-items-center justify-content-between list-unstyled m-0 w-100',
        className,
      )}
      style={{ maxWidth: '54rem' }}
    >
      {steps.map((step, index) => (
        <DesktopStepItem
          key={step.link}
          step={step}
          number={index + 1}
          disabled={isEmpty(user) && step.onlyAuthenticated}
          state={state}
          activeOnlyWhenExact={false}
          last={step === steps[steps.length - 1]}
        />
      ))}
    </ul>
  ) : (
    <>
      {!menuForMobile ? (
        <ProgressBarMobile
          isOpen={isOpen}
          context={MenuContext}
          title={steps.map((step, index) => (
            <DesktopStepItem
              isTitle
              key={step.link}
              step={step}
              number={index + 1}
              disabled={isEmpty(user) && step.onlyAuthenticated}
              state={state}
              count={steps.length}
            />
          ))}
        >
          <ul className="list-unstyled p-0 m-0">
            {steps.map((step, index) => (
              <DesktopStepItem
                key={step.link}
                step={step}
                number={index + 1}
                disabled={isEmpty(user) && step.onlyAuthenticated}
                state={state}
                count={steps.length}
                hideStepsMenu={hideStepsMenu}
              />
            ))}
          </ul>
        </ProgressBarMobile>
      ) : null}
    </>
  );
};

export default ProgressBar;
