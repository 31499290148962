import loadable from '@loadable/component';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReward } from 'react-rewards';
import { Navigate, useNavigate } from 'react-router-dom';

import { useMobile } from '@krea/common/hooks';
import partyPopper from '@krea/common/images/svg/party-popper.svg';
import { AccordionItem } from '@krea/common/shared-components/accordion/AccordionItem';
import { FORM_STEP } from '@krea/common/shared-components/application-form';
import Button from '@krea/common/shared-components/button';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import PreliminaryBidResult from '@krea/common/shared-components/preliminary-bid-result/PreliminaryBidResult';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
// import AppFormPromotion from '@krea/common/shared-components/loan-application-form/AppFormPromotion';
import { router } from 'router';

import SVG from 'react-inlinesvg';

const AppFormPromotion = loadable(
  () =>
    import(
      '@krea/common/shared-components/loan-application-form/AppFormPromotion'
    ),
);

export const PreliminaryBidResultPage = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/amount-estimator');
  };

  const preliminaryBid = useLoanApplicationFormStore(
    (state) => state.preliminaryBid,
  );
  const positiveResult =
    preliminaryBid.probabilityLoanApproved === 'high' ||
    preliminaryBid.probabilityLoanApproved === 'medium';
  const highProbability = preliminaryBid.probabilityLoanApproved === 'high';
  const exceedsMaxEstimateAmount = preliminaryBid.useEdgeCaseLogic;

  const isFirstRender = useRef(true);
  const [isAnimatingPartyPopper, setIsAnimatingPartyPopper] = useState(false);

  const config = {
    elementCount: 100,
    spread: 150,
    angle: 40,
    zIndex: 9999,
    lifetime: 300,
    colors: ['#00BAB5', '#7742C4', '#DEBDED', '#FBF2FF'],
  };
  const { reward } = useReward('rewardId', 'confetti', config);

  const throwConfetti = useCallback(() => {
    reward();
    setIsAnimatingPartyPopper(true);
    setTimeout(() => {
      setIsAnimatingPartyPopper(false);
    }, 500);
  }, [reward]);

  useEffect(() => {
    if (isFirstRender.current) {
      !isMobile && highProbability && throwConfetti();
    }
    isFirstRender.current = false;
  }, [isMobile, highProbability, throwConfetti]);

  const handleSubmit = async () => {
    const { setCurrentStep } = useLoanApplicationFormStore.getState();
    // Since we have all the data needed in the BASIC_DETAILS step
    // We could transition directly into the PRIMARY_PURPOSE step.
    // But for now, we will transition to the BASIC_DETAILS step to ensure
    // That the customer has the opportunity to change their contact infos (KREA-2730)
    setCurrentStep(FORM_STEP.BASIC_DETAILS);

    router.navigate('/application');
  };

  const resultHeading = (
    <>
      {positiveResult
        ? t('preliminaryBidForm.youAreEligible')
        : t('preliminaryBidForm.youAreNotEligible')}
      {highProbability && (
        <>
          <span id="rewardId" />
          <SVG
            src={partyPopper}
            style={
              !isMobile
                ? { width: '40px', height: 'auto' }
                : { width: '30px', height: 'auto' }
            }
            className={classNames('tw-inline tw-mb-3 tw-ml-3 xl:tw-ml-4', {
              'tw-animate-pulse': isAnimatingPartyPopper,
            })}
            onClick={throwConfetti}
          ></SVG>
        </>
      )}
    </>
  );
  const innerForm = (
    <>
      <div className="tw-flex xl:tw-mb-8 tw-mb-4">
        {isMobile ? (
          <h3 className="tw-inline">{resultHeading}</h3>
        ) : (
          <h1 className="tw-inline">{resultHeading}</h1>
        )}
      </div>

      <Button onClick={goBack} variant="text" className="tw-font-bold tw-mb-2">
        {t('preliminaryBidForm.makeAnotherEstimate')}
      </Button>

      <PreliminaryBidResult isOnResultPage />

      <div
        onClick={handleSubmit}
        style={{ backgroundColor: '#FBF2FF' }}
        className="tw-text-[1.25rem] tw-border-gray-light tw-border tw-cursor-pointer tw-rounded tw-px-4 tw-py-4 md:tw-px-6 md:tw-py-4 tw-m-0"
      >
        {t('preliminaryBidForm.doItForReal')}{' '}
        <b className="tw-text-primary">{t('preliminaryBidForm.clickHere')}</b>
      </div>

      <AccordionItem
        className="tw-mt-7 tw-mb-5 tw-border-gray-light"
        title={t('preliminaryBidForm.seeHowWeCalculated')}
      >
        <p>{t('preliminaryBidForm.howDidWeCalculate.description1')}</p>
        <p>{t('preliminaryBidForm.howDidWeCalculate.description2')}</p>
        <p className="mb-0">
          {t('preliminaryBidForm.howDidWeCalculate.description3')}
        </p>
      </AccordionItem>

      {!exceedsMaxEstimateAmount && positiveResult && (
        <AccordionItem
          className="tw-mt-7 tw-mb-5 tw-border-gray-light"
          title={t('preliminaryBidForm.higherLimitsPossible')}
        >
          <p>{t('preliminaryBidForm.higherLimitsPossibleDescription')}</p>
        </AccordionItem>
      )}

      {!positiveResult && (
        <AccordionItem
          className="tw-mt-0 tw-mb-8 tw-border-gray"
          title={t('preliminaryBidForm.howCanYouImproveYourChances')}
        >
          <p>{t('preliminaryBidForm.improveYourChancesDescription1')}</p>
          <p>{t('preliminaryBidForm.improveYourChancesDescription2')}</p>
          <p>{t('preliminaryBidForm.improveYourChancesDescription3')}</p>
          <ul className="pl-4 tw-list-disc">
            <li>
              <p>{t('preliminaryBidForm.improveYourChancesDescription4')}</p>
            </li>
            <li>
              <p>{t('preliminaryBidForm.improveYourChancesDescription5')}</p>
            </li>
            <li>
              <p>{t('preliminaryBidForm.improveYourChancesDescription6')}</p>
            </li>
          </ul>

          <p>{t('preliminaryBidForm.improveYourChancesDescription7')}</p>
        </AccordionItem>
      )}

      {exceedsMaxEstimateAmount ? (
        <div className="tw-mb-6 tw-border tw-border-gray-light tw-rounded tw-p-5 lg:tw-p-7">
          <h4>{t('preliminaryBidForm.talkToAnAdvisorHeading')}</h4>

          <p
            className="mt-3"
            style={isMobile ? { fontSize: '16px' } : { fontSize: '18px' }}
          >
            {t('preliminaryBidForm.talkToAnAdvisorSubheading')}
          </p>
          <a
            href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2DhjGbnKA4cdtHdwTgSSzNGPNJYeC8Xw8IXXJj4-iLnHvM6wn1j76ER0o8aJcJV64g2QJPFl95##follow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              block
              size="lg"
              variant="outlined"
              className="tw-text-center tw-my-3"
            >
              {t('preliminaryBidForm.bookAMeeting')}
            </Button>
          </a>
        </div>
      ) : (
        !isMobile && (
          <div className="tw-mt-10">
            <h2>{t('applicationForm.common.startApplicationHeading')}</h2>

            <p
              className="tw-mt-3"
              style={isMobile ? { fontSize: '16px' } : { fontSize: '18px' }}
            >
              {t('applicationForm.common.startApplicationSubheading')}
            </p>
          </div>
        )
      )}
    </>
  );

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      {!isEmpty(preliminaryBid) ? (
        <div className="tw-flex">
          <div style={isMobile ? { width: '100%' } : { width: '65%' }}>
            <div
              className="tw-mx-auto tw-flex"
              style={!isMobile ? { width: '80%' } : {}}
            >
              <LoanApplicationFormLayout
                innerForm={innerForm}
                hideAdvisorCTABanner
                hasTestimonials
                borderlessInnerForm
                goToNextStep={handleSubmit}
                customButtonText={t('applicationForm.preliminaryBid.heading')}
                heading={''}
                goToPreviousStep={() => {}}
              />
            </div>
          </div>

          {!isMobile && (
            <div
              className="tw-mx-2"
              style={{
                minWidth: '400px',
                maxWidth: '500px',
                minHeight: '855px',
                maxHeight: '950px',
              }}
            >
              <AppFormPromotion />
            </div>
          )}
        </div>
      ) : (
        <Navigate to="/amount-estimator" />
      )}
    </form>
  );
};
