import axios from 'axios';

import { client } from '@krea/common/graphql';

export const fetchCurrentLoanApplicationData = async () => {
  return client.request(`{
    me {
      currentLoanApplication {
        id,
        state,
        requestedLoanAmount,
        requestedPaybackTimeMonths,
        biddingEndsAt,
        eligibleForLeasingBids,
        shouldTrackDraftConversion,
        shouldTrackRateForecastExecutedConversion,
        product,
        advisoryAcceptedAt,
        adviceCategory,
        isDigitalAdvice,
        initialNumberOfAttractivenessImprovements,
        applicationAttractiveness,
        customerPreferredRate,
        alternativeRate,
        verificationFailureReasons,
        latestDuplicatedApplicationInfo {
          id
          submittedAt
          numBids
        }
        attractivenessImprovements {
          field,
          displayText
        },
        assignedToAdminUser {
          id,
          email,
          firstName,
          lastName,
          mobilePhoneNumber,
          profileImage {
            lowResolutionUrl
          }
        },
        contactPerson {
          id,
          ssn,
          firstName,
          lastName,
          email
        },
        triageFields,
        previewFields,
        applyingCompany {
          id,
          customerCompany {
            id,
            name,
            organisationNumber,
            typeDisplayName
          },
          boardMembers {
            ssn,
            firstName,
            lastName
            roles {
              roleCode,
              roleName
            }
          }
        }
      }
    }
  }`);
};

export const fetchSelectedLenderResponseData = async () => {
  return client.request(`{
    me {
      currentLoanApplication {
        selectedLenderResponse {
          id,
          signingUrl,
          eligibleForPaymentAt,
          invoicePurchaseBid {
            totalPaidOut,
            purchasedInvoiceAmount,
            commissionPercent,
            comparableMonthlyInterestRate,
            handlingFeePerInvoice,
            monthlyFee,
            purchasedAmountPercent,
            totalMonthlyCost,
            purchaseLimit,
            limitFeePerYearPercent,
            setupFee,
            withRegress,
            regressNumDaysAfterDueDate,
            paidOutPerInvoice,
            paidOutPerInvoicePercent
          },

          lenderApplication {
            id,
            createdAt,
            updatedAt,
            lenderCompany {
              id,
              name,
              description,
              registeredAtFinansinspektionenAt,
              signingUrlTemplate,
              customerContactPhoneNumber,
              customerContactEmail,
              image {
                lowResolutionUrl
              }
            }
          },
          bid {
            monthlyCostWithoutSetupFee,
            amortizationMethodDisplayName,
            amortizationMethodDescription,
            totalLoanCost,
            monthlyCost,
            setupFee,
            postalFee,
            directDebitFee,
            otherFees {
              type,
              fee,
              interval,
              name
            },
            paybackTimeMonths,
            amortizationMethod,
            lenderMonthlyInterest,
            earlyRepaymentFee,
            amount
          },
          realEstateBid {
            id,
            monthlyCostWithoutSetupFee,
            amortizationMethodDisplayName,
            amortizationMethodDescription,
            totalLoanCost,
            monthlyCost,
            setupFee,
            postalFee,
            directDebitFee,
            paybackTimeMonths,
            amortizationMethod,
            lenderMonthlyInterest,
            earlyRepaymentFee,
            amount,
            loanToValueRatio
          },
          leasingBid {
            id,
            amount,
            paybackTimeMonths,
            monthlyPayment,
            totalCost,
            downPayment,
            terminalValue,
            setupFee,
            contractType,
            postalFee,
            insuranceMonthlyCost
          },
          revolvingCreditBid {
            id,
            earlyRepaymentFee,
            directDebitFee,
            amount,
            monthlyAmortisation,
            lenderMonthlyInterest,
            withdrawalFee,
            setupFee,
            limitFeePerYear,
            postalFee,
            monthlyPayment,
            monthlyCost
          },
          fieldRequests {
            id,
            type,
            field,
            displayName,
            displayText,
            required,
            isSubmitted,
            subtitle,
            isCollectedByLender,
            submittedData,
            lenderComment
          },
          processSteps {
            text
          }
        }
      }
    }
  }`);
};

export const selectCurrentLoanApplication = async (id) => {
  await axios.post('/api/v1/current-loan-application/select', {
    loan_application_id: id,
  });
};

export const submitApplication = async () => {
  return axios.post('/api/v1/current-loan-application/submit');
};

export const acceptAdvice = async () => {
  return axios.post('api/v1/current-loan-application/accept-advisory');
};

export const withdrawAccept = async () => {
  return axios.delete('api/v1/current-loan-application/accept-advisory');
};

export const trackLoanApplicationDraftConversion = async ({
  loanApplicationID,
}) => {
  const query = `mutation TrackLoanApplicationDraftMutation($id: ID!) {
		trackLoanApplicationDraft(id: $id) {
      tracking {
        currency
        conversionValue
      }
		}
	}`;

  return client.request(query, { id: loanApplicationID });
};

export const trackLoanApplicationRateForecastExecuted = async ({
  loanApplicationID,
}) => {
  const query = `mutation TrackLoanApplicationRateForecastExecutedMutation($id: ID!) {
    trackLoanApplicationRateForecastExecuted(id: $id) {
      tracking {
        currency
        conversionValue
      }
    }
  }`;

  return client.request(query, { id: loanApplicationID });
};
