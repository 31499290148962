import { useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import { settings } from '@krea/common/settings';
import {
  ApplicationForm,
  FORM_STEP,
  useApplicationFormNavigation,
} from '@krea/common/shared-components/application-form';
import IdentificationStep from '@krea/common/shared-components/application-form/IdentificationStep';
import { useApplicationFlowForm } from '@krea/common/shared-components/application-form/hooks/useApplicationFlowForm';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import LoginContainer from '@krea/common/shared-components/login/LoginContainer';
import Preloader from '@krea/common/shared-components/preloader';
import { useBankIDStore } from '@krea/common/store/bankID/hooks';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { BANKID_AUTHENTICATE_STORE_KEY } from '@krea/common/store/queryClient';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';
import { COUNTRY_CODE } from '@krea/common/utils';

import { useCreateApplication } from 'store/loan-application-form/hooks/mutations';

export const ApplicationFormPage = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const queryClient = useQueryClient();
  const { getPreviousStep } = useApplicationFormNavigation();

  const resetBankIDStore = useBankIDStore((state) => state.resetBankIDStore);
  const { setCurrentStep, currentStep } = useLoanApplicationFormStore(
    (state) => ({
      currentStep: state.currentStep,
      setCurrentStep: state.setCurrentStep,
    }),
  );

  const applicationFormProps = useApplicationFlowForm();

  const onCancel = () => {
    queryClient.resetQueries({ queryKey: [BANKID_AUTHENTICATE_STORE_KEY] });
    resetBankIDStore();
    setCurrentStep(getPreviousStep());
  };

  const onBack = () => {
    resetBankIDStore();
    setCurrentStep(getPreviousStep());
  };

  const { data: user } = useGetUserData();
  const { mutate: createApplication, isLoading } = useCreateApplication(() =>
    onCancel(),
  );

  const onSuccessBankID = useCallback(() => {
    createApplication();
  }, [createApplication]);

  const onSuccessForm = useCallback(() => {
    // If logged in, we can create the application right away
    if (!isEmpty(user)) {
      createApplication();
    }
  }, [createApplication, user]);

  useEffect(() => {
    // Scroll to top on step change on mobile
    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, [currentStep, isMobile]);

  useEffect(() => {
    if (window.MessageBirdChatWidget) {
      window.MessageBirdChatWidget.hide();
    }

    return () => {
      if (
        window.MessageBirdChatWidget &&
        window.MessageBirdChatWidget.hasOwnProperty('show')
      ) {
        window.MessageBirdChatWidget.show();
      }
    };
  }, []);

  const companyName = useLoanApplicationFormStore(
    (state) => state.organisation.name,
  );
  const { errorCode } = useLoanApplicationFormStore((state) => ({
    errorCode: state.formErrors.errorCode,
  }));

  if (isLoading) {
    return (
      <LoanApplicationFormLayout
        heading={t('applicationForm.common.identificationHeading')}
        subheading={t('applicationForm.common.weAreObligedToIdentifyYou', {
          companyName,
        })}
        upperInfoText={
          settings.countryCode === COUNTRY_CODE.SE
            ? t('applicationForm.common.noApplicationOrCreditCheck')
            : t('applicationForm.common.noApplication')
        }
        innerForm={
          <div className="my-7">
            <LoginContainer title={t('login.pleaseWait')} />
            <Preloader size="lg" className="text-primary" />
          </div>
        }
        hideNavigation={isLoading}
        hideAdvisorCTABanner
        warningInfoTextIndex={1}
      />
    );
  }

  return (
    <div>
      {currentStep === FORM_STEP.COMPLETED ? (
        <IdentificationStep
          onSuccessBankID={onSuccessBankID}
          onCancel={() => onCancel()}
          onBack={() => onBack()}
          errorCode={errorCode}
        />
      ) : (
        <ApplicationForm
          applicationFormProps={applicationFormProps}
          onComplete={() => onSuccessForm()}
        />
      )}
    </div>
  );
};
