import React, { MouseEventHandler } from 'react';

export const ApplicationTypeSelectorButton = ({
  text,
  id,
  value,
  setInputValue,
  className,
  onClick,
  onHover,
  disabled,
  preloader,
  selected,
  ...rest
}: {
  text: string;
  id: string;
  value: string;
  setInputValue: (value: string) => void;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onHover?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  preloader?: boolean;
  selected?: boolean;
}) => {
  return (
    <button
      {...rest}
      className={
        className
          ? className
          : 'tw-w-1/3 tw-rounded tw-my-6 tw-mx-2 tw-justify-center tw-text-primary tw-mt-2'
      }
      style={{
        backgroundColor: value === id ? '#FBF2FF' : 'white',
        border: value === id ? '1.5px solid #BDADEA' : '1.5px solid #EBE8EF',
        boxShadow:
          value === id
            ? '0px 3.5px 3.5px #BDADEA'
            : '0px 3.5px 3.5px rgba(0, 0, 0, 0.1)',
        justifyContent: 'center',
        height: '50px',
        transition: 'background-color 0.3s ease-in-out',
        outline: 'none',
        fontWeight: 'bold',
      }}
      onMouseEnter={(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      ) => {
        (event.target as HTMLButtonElement).style.boxShadow =
          '0px 3.5px 3.5px #BDADEA';
      }}
      onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => {
        if (value !== id) {
          (event.target as HTMLButtonElement).style.boxShadow =
            '0px 3.5px 3.5px rgba(0, 0, 0, 0.1)';
        }
      }}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setInputValue(id);
        event.currentTarget.style.backgroundColor = '#FBF2FF';
        if (onClick) {
          onClick(event);
        }
      }}
    >
      {text}
    </button>
  );
};
