import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useModalStore } from '@krea/common/store/modal/hooks';
import { USER_STORE_KEY } from '@krea/common/store/queryClient';

import { selectCurrentLoanApplication } from 'services/api/loanApplication';

interface ISelectCurrentLoanApplicationParams {
  loanApplicationID: string;
}

export const useSelectCurrentLoanApplication = () => {
  const queryClient = useQueryClient();
  const closeModal = useModalStore(({ closeModal }) => closeModal);

  return useMutation<null, Error, ISelectCurrentLoanApplicationParams>({
    mutationFn: async ({ loanApplicationID }) => {
      await selectCurrentLoanApplication(loanApplicationID);

      return null;
    },
    onSuccess: async () => {
      closeModal();

      await queryClient.invalidateQueries({ queryKey: [USER_STORE_KEY] });
    },
    onError: (err) => {
      console.error('Failed to select loan application: err', err);
      Sentry.captureException(err);
    },
  });
};
