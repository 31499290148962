import loadable from '@loadable/component';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import Button from '@krea/common/shared-components/button';
import { Text } from '@krea/common/shared-components/text';
import { useModalStore } from '@krea/common/store/modal/hooks';

import ChangeApplicationModal from 'krea-direct/components/modals/ChangeApplicationModal';
import { useGetCurrentLoanApplication } from 'store/current-loan-application/hooks/queries';
import { useGetLoanApplications } from 'store/loan-applications/hooks/queries';

import { MenuContext } from './../header';

const AppTooltip = loadable(
  () => import('@krea/common/shared-components/tooltip'),
);

const ApplicationSelector = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const openModal = useModalStore(({ openModal }) => openModal);

  const { data = [] } = useGetLoanApplications();
  const { data: { applyingCompany } = {} } = useGetCurrentLoanApplication();

  const buttonText = t('applicationSummary.change');
  const activeLoanApplicationsCount = data.length;
  const companyName = applyingCompany?.customerCompany?.name;

  const onChangeApplication = () => {
    openModal({ modal: <ChangeApplicationModal /> });
  };

  return (
    <>
      {!isMobile ? (
        <div className={classnames('d-flex ml-4')}>
          {companyName && (
            <div
              className={classnames('d-flex align-items-center', {
                'pr-3': activeLoanApplicationsCount > 1,
              })}
              style={{ maxWidth: '10rem' }}
            >
              <AppTooltip content={companyName} className="w-100">
                <Text
                  size="sm"
                  className="w-100 font-weight-bold text-truncate text-secondary"
                >
                  {companyName}
                </Text>
              </AppTooltip>
            </div>
          )}

          {activeLoanApplicationsCount > 1 && (
            <MenuContext.Consumer>
              {({ toggleMenu }) => (
                <Button
                  variant="outlined"
                  className="shrink-0"
                  onClick={() => {
                    onChangeApplication();
                    toggleMenu();
                  }}
                >
                  {buttonText}
                </Button>
              )}
            </MenuContext.Consumer>
          )}
        </div>
      ) : (
        <div className="w-100 flex-md-grow-1" style={{ minWidth: '0px' }}>
          <div className="d-flex flex-column flex-md-row align-items-center rounded bg-gray pb-4 p-md-0">
            <div
              className="pb-5 pb-md-0 pr-md-4 text-center text-md-left w-100 grow-1"
              style={{ minWidth: '0px' }}
            >
              {companyName && (
                <>
                  <Text size="sm" className="font-weight-bold text-gray-dark">
                    {t('applicationSummary.companyNameLabel')}
                  </Text>
                  <Text className="font-weight-bold text-truncate">
                    {companyName}
                  </Text>
                </>
              )}
            </div>

            {activeLoanApplicationsCount > 1 && (
              <div className="shrink-0">
                <MenuContext.Consumer>
                  {({ toggleMenu }) => (
                    <Button
                      variant="text"
                      className="px-4"
                      onClick={() => {
                        onChangeApplication();
                        toggleMenu();
                      }}
                    >
                      {buttonText}
                    </Button>
                  )}
                </MenuContext.Consumer>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicationSelector;
