import { parseIncompletePhoneNumber } from 'libphonenumber-js';

import { getUtmDataJSONString } from '@krea/common/services/formService';

import { settings } from '../../settings';
import { useLoanApplicationFormStore } from '../loan-application-form/hooks';

export const getLeadInput = () => {
  const formData = useLoanApplicationFormStore.getState();
  const utm_data_json_string = getUtmDataJSONString(formData.utmCampaign);

  const input = {
    organisationNumber: formData.organisation.organisationNumber,
    organisationName: formData.organisation.name,
    type: 'PRE_DRAFT_LEAD',
    countryCode: settings.countryCode,
    email: formData.email,
    phoneNumber: parseIncompletePhoneNumber(formData.mobilePhoneNumber),
    investmentAmount: null,
    amortizationMethod: null,
    paybackTimeMonths: null,
    yearlyInterestRate: null,
    residualValuePercent: null,
    estimatedMonthlyCostResult: null,
    numberOfInvoicesPerMonth: null,
    invoicesAveragePaymentTimeDays: null,
    estimatedRevenueLastYear: null,
    utmData: utm_data_json_string ? JSON.parse(utm_data_json_string) : null,
  };

  // Add payload specific to the reason/product flow the user took

  if (
    formData.reason === 'real_estate_financing' &&
    formData.realEstateReason
  ) {
    input.investmentAmount = formData.realEstateRequestedLoanAmount || null;
  } else if (formData.reason === 'invoice_financing') {
    const unpaidInvoices = formData.unpaidInvoices;

    input.investmentAmount = unpaidInvoices.estimatedInvoiceAmount || null;
    input.numberOfInvoicesPerMonth = unpaidInvoices.numberOfInvoices || null;
    input.invoicesAveragePaymentTimeDays =
      unpaidInvoices.averagePaymentTimeDays || null;
  } else if (formData.reason === 'object_financing') {
    input.investmentAmount = formData.leasing?.leasing_amount || null;
  } else {
    // If it's not a specific product, we use the requestedLoanAmount for the investmentAmount
    // for all other reasons (if reason has been supplied)
    if (formData.reason) {
      input.investmentAmount = formData.requestedLoanAmount || null;
    }
  }

  return input;
};
