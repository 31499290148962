import { getI18n } from 'react-i18next';

import { PRODUCT_TYPE } from '@krea/common/utils';

export const getProducts = () => {
  const i18n = getI18n();

  return [
    {
      type: PRODUCT_TYPE.COMPANY_LOAN,
      path: '/direct/loan-application-form',
      name: i18n.t('applicationForm.formSwitcher.companyLoanTab'),
    },
    {
      type: PRODUCT_TYPE.FACTORING,
      path: '/direct/factoring-application-form',
      name: i18n.t('applicationForm.formSwitcher.factoringTab'),
    },
    {
      type: PRODUCT_TYPE.REAL_ESTATE,
      path: '/direct/real-estate-application-form',
      name: i18n.t('applicationForm.formSwitcher.realEstateLoanTab'),
    },
    {
      type: PRODUCT_TYPE.LEASING,
      path: '/direct/leasing-application-form',
      name: i18n.t('applicationForm.formSwitcher.leasingTab'),
    },
  ];
};

export const APP_ABSOLUTE_PATHS = {
  preview: '/direct/improve-application-questions/preview',
  dashboard: '/dashboard',
  postSelect: '/selected-lender-response',
} as const;
