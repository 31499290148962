import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import { settings } from '@krea/common/settings';
import Button from '@krea/common/shared-components/button';
import { Text } from '@krea/common/shared-components/text';
import { privacyPolicyLink, TEST_IDS } from '@krea/common/utils';

const LoanApplicationFormNavigation = ({
  customButtonText,
  nextButtonVariant,
  goToNextStep,
  goToPreviousStep,
  hasTestimonials = false,
  hidePrivacyLinkText = false,
  disabledNavigationBeforeFormCompletion = false,
}: {
  customButtonText?: string | null;
  nextButtonVariant?: 'contained' | 'text' | 'outlined';
  goToNextStep?: () => void;
  goToPreviousStep?: () => void;
  hasTestimonials?: boolean;
  hidePrivacyLinkText?: boolean;
  disabledNavigationBeforeFormCompletion?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMobile();

  return (
    <div
      style={
        isMobile
          ? {
              zIndex: '100000',
              position: 'fixed',
              left: '0',
              bottom: '0',
              height: '100px',
              backgroundColor: 'white',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.10)',
              width: '100%',
            }
          : {}
      }
    >
      <div
        className={classNames({
          'md:tw-px-8 tw-my-3 tw-mx-7': isMobile && !hasTestimonials,
          'tw-mt-4': !isMobile && !hasTestimonials,
          'tw-flex': !hasTestimonials,
          'tw-mx-6 md:tw-mx-[65px]': isMobile,
        })}
        style={
          !hasTestimonials
            ? goToPreviousStep
              ? { justifyContent: 'space-between' }
              : isMobile
                ? { justifyContent: 'center' }
                : { justifyContent: 'end' }
            : {}
        }
      >
        {!hasTestimonials && goToPreviousStep && (
          <Button
            size="lg"
            variant="text"
            className={classNames('', { 'tw-text-left tw-mt-3': isMobile })}
            onClick={goToPreviousStep}
          >
            {t('applicationForm.common.back')}
          </Button>
        )}

        {goToNextStep && (
          <Button
            block={hasTestimonials || (!goToPreviousStep && isMobile)}
            size="lg"
            tabIndex={0}
            className={classNames('tw-text-center', {
              'tw-h-[55px] tw-mt-3': isMobile,

              // Question pages
              'tw-mt-2': !hasTestimonials && !isMobile,
              'tw-mt-3 tw-w-[70%]':
                !hasTestimonials && isMobile && goToPreviousStep, // has two buttons
              'tw-w-[70%] tw-ml-auto':
                !hasTestimonials && isMobile && !goToPreviousStep, // has one button

              // Application pages with testimonials (not form question pages)
              'tw-w-full': hasTestimonials,

              'tw-mt-3':
                hasTestimonials && !customButtonText && !hidePrivacyLinkText,
              'tw-mt-6':
                hasTestimonials && customButtonText && hidePrivacyLinkText,
            })}
            onClick={goToNextStep}
            disabled={disabledNavigationBeforeFormCompletion}
            data-test-id={
              hasTestimonials
                ? TEST_IDS.common.forms.loanApplication.getStartedButton
                : TEST_IDS.common.forms.continueButton
            }
            type="submit"
            variant={nextButtonVariant ? nextButtonVariant : 'contained'}
          >
            {customButtonText
              ? customButtonText
              : hasTestimonials
                ? t('applicationForm.common.getStarted')
                : t('applicationForm.common.continue')}
          </Button>
        )}

        {!hidePrivacyLinkText && hasTestimonials && (
          <Text
            size={isMobile ? 'xs' : 'sm'}
            className={classNames(
              'tw-text-center xl:tw-pr-1 xl:tw-mt-3 xl:tw-mb-10 tw-mt-1',
              {
                'tw-text-gray-disabled': disabledNavigationBeforeFormCompletion,
                'tw-text-black': !disabledNavigationBeforeFormCompletion,
              },
            )}
          >
            {t('applicationForm.common.byContinuingYouAccept')}
            <a
              target="_blank"
              href={privacyPolicyLink(
                settings.countryCode,
                settings.countryWebsiteUrl,
                i18n.language,
              )}
              rel="noopener noreferrer"
              className={classNames('font-weight-bold', {
                'tw-text-gray-disabled': disabledNavigationBeforeFormCompletion,
                'tw-text-primary': !disabledNavigationBeforeFormCompletion,
              })}
            >
              {t('applicationForm.common.privacyPolicy')}
            </a>
          </Text>
        )}
      </div>
    </div>
  );
};

export default LoanApplicationFormNavigation;
