import { client } from '@krea/common/graphql';

export const fetchLoanApplicationsData = async () => {
  return await client.request(`{
    loanApplications {
      id,
      requestedLoanAmount,
      requestedPaybackTimeMonths,
      applyingCompany {
        customerCompany {
          name,
          organisationNumber,
          id
        }
      }
    }
  }`);
};
