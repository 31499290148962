import axios from 'axios';

import { client } from '../../graphql';

export const createLead = async (input) => {
  const query = `
    mutation CreateLead($input: LeadInput!) {
      createLead(input: $input) {
        id
      }
    }
  `;
  const data = await client.request(query, { input });

  return data?.createLead;
};

export const updateLead = async (id, input) => {
  const query = `
    mutation UpdateLead($id: ID!, $input: LeadInput!) {
      updateLead(id: $id, input: $input) {
        id
      }
    }
  `;
  const data = await client.request(query, { id, input });

  return data?.updateLead;
};
