import classnames from 'classnames';
import React from 'react';

import styles from './ApplicationsSelectorList.module.scss';

const ApplicationsSelectorList = ({ children }) => {
  return <div className={classnames(styles.root, 'pt-2')}>{children}</div>;
};

export default ApplicationsSelectorList;
