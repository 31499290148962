import React from 'react';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextArea from '@krea/common/shared-components/fields/textarea';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';

import { TEST_IDS } from '../../../utils';

const FactoringExtendedForm = ({
  control,
  goToPreviousStep,
  goToNextStep,
  currentStep,
}: {
  control: Control<FieldValues>;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  getValues: UseFormGetValues<FieldValues>;
  currentStep: string;
}) => {
  const { t } = useTranslation();

  const { unpaidInvoicesDescription, setUnpaidInvoicesDescription } =
    useLoanApplicationFormStore((state) => ({
      setUnpaidInvoicesDescription: state.setUnpaidInvoicesDescription,
      unpaidInvoicesDescription: state.unpaidInvoicesDescription,
    }));

  const {
    field: { value, onChange: onInputChange },
    fieldState,
  } = useController({
    name: 'unpaidInvoicesDescription',
    control,
  });

  const onChange = (value: string) => {
    onInputChange(value);
    setUnpaidInvoicesDescription(value);
  };

  const innerForm = (
    <div>
      <p
        className="tw-my-3 xl:tw-mt-6 lg:tw-ml-6 tw-font-bold"
        style={{ fontSize: '18px' }}
      >
        {t('applicationForm.common.largestThreeInvoices')}
      </p>
      <TextArea
        size="lg"
        id="descriptionOfInvoices"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        type="text"
        name="descriptionOfInvoices"
        error={
          fieldState.isTouched && fieldState.error?.message
            ? fieldState.error?.message
            : null
        }
        placeholder={t('applicationForm.common.largestInvoicesPlaceholder')}
        className="lg:tw-mx-6 lg:tw-mb-7"
        style={{ fontWeight: 'normal' }}
        data-test-id={
          TEST_IDS.common.forms.factoringForm.invoiceDescriptionInput
        }
      />
    </div>
  );

  return (
    <>
      <LoanApplicationFormLayout
        heading={t('applicationForm.common.invoiceFinanceHeading')}
        subheading={t('applicationForm.common.describeLargestInvoices')}
        innerForm={innerForm}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        disabledNavigationBeforeFormCompletion={!unpaidInvoicesDescription}
      />
    </>
  );
};

export default FactoringExtendedForm;
