import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks/useLoanApplicationFormStore';

import { updateLead } from '../../api';
import { getLeadInput } from '../../utils';

export const useUpdateLead = () => {
  const setPreApplicationLead = useLoanApplicationFormStore(
    (state) => state.setPreApplicationLead,
  );

  return useMutation({
    mutationFn: (id) => {
      // We track the PRE_DRAFT_LEAD event in Google Tag Manager
      const input = getLeadInput();

      return updateLead(id, input);
    },
    onSuccess: async (data) => {
      if (data) {
        setPreApplicationLead(data);
      }
    },
    onError: (err, id) => {
      console.error(`Failed updating the lead ${id}`, err);
      Sentry.captureException(err);
    },
  });
};
