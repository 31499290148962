import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Elfantminer from '@krea/common/images/Elfantminer.png';
import ElfantminerWebp from '@krea/common/images/Elfantminer.webp';
import SaltoMarin from '@krea/common/images/Salto_Marin_AB.png';
import SaltoMarinWebp from '@krea/common/images/Salto_Marin_AB.webp';
import TheItalianCousins from '@krea/common/images/The_Italian_Cousins.png';
import TheItalianCousinsWebp from '@krea/common/images/The_Italian_Cousins.webp';
import WillBrand from '@krea/common/images/Will_Brand_AB.png';
import WillBrandWebp from '@krea/common/images/Will_Brand_AB.webp';
import arrowLeft from '@krea/common/images/svg/arrow-left-short.svg';
import arrowRight from '@krea/common/images/svg/arrow-right-short.svg';
import { settings } from '@krea/common/settings';
import ContactsGoogle from '@krea/common/shared-components/app/contacts/ContactsGoogle';
import ContactsTrustpilot from '@krea/common/shared-components/app/contacts/ContactsTrustpilot';
import { COUNTRY_CODE } from '@krea/common/utils';
import { useFormatCurrency } from '@krea/common/hooks';

import styles from './AppFormPromotion.module.scss';

const AppFormPromotion = () => {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(0);

  const [isAnimating, setIsAnimating] = useState(false);

  const animate = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 2500);
  };
  const currency = settings.currency;
  const [, currencyValue] = useFormatCurrency({ currency });

  const theItalianCousins = {
    index: 0,
    image: TheItalianCousins,
    webpImage: TheItalianCousinsWebp,
    organization: t(
      'applicationForm.testimonials.theItalianCousins.organization',
    ),
    type: t('applicationForm.testimonials.theItalianCousins.type'),
    turnover: `${t(
      'applicationForm.testimonials.theItalianCousins.turnover',
    )} ${
      settings.countryCode === COUNTRY_CODE.SE ? '15-20M' : '1.5-2M'
    } ${currencyValue}`,
    loan: `${t('applicationForm.testimonials.theItalianCousins.loan')} ${
      settings.countryCode === COUNTRY_CODE.SE ? '2-3M' : '200t-300t'
    } ${currencyValue}`,
    text: t('applicationForm.testimonials.theItalianCousins.text'),
    author: t('applicationForm.testimonials.theItalianCousins.author'),
  };

  const saltoMarin = {
    index: 1,
    image: SaltoMarin,
    webpImage: SaltoMarinWebp,
    organization: t('applicationForm.testimonials.saltoMarin.organization'),
    type: t('applicationForm.testimonials.saltoMarin.type'),
    turnover: `${t('applicationForm.testimonials.saltoMarin.turnover')} ${
      settings.countryCode === COUNTRY_CODE.SE ? '10-15M' : '1-1.5M'
    } ${currencyValue}`,
    loan: `${t('applicationForm.testimonials.saltoMarin.loan')}  ${
      settings.countryCode === COUNTRY_CODE.SE ? '5-10M' : '0.5-1M'
    } ${currencyValue}`,
    text: t('applicationForm.testimonials.saltoMarin.text'),
    author: t('applicationForm.testimonials.saltoMarin.author'),
  };

  const willBrand = {
    index: 2,
    image: WillBrand,
    webpImage: WillBrandWebp,
    organization: t('applicationForm.testimonials.willBrand.organization'),
    type: t('applicationForm.testimonials.willBrand.type'),
    turnover: `${t('applicationForm.testimonials.willBrand.turnover')} ${
      settings.countryCode === COUNTRY_CODE.SE ? '5-10M' : '0.5-1M'
    } ${currencyValue}`,
    loan: `${t('applicationForm.testimonials.willBrand.loan')} ${
      settings.countryCode === COUNTRY_CODE.SE ? '0.5M' : '50 000'
    } ${currencyValue}`,
    text: t('applicationForm.testimonials.willBrand.text'),
    author: t('applicationForm.testimonials.willBrand.author'),
  };

  const elfantminer = {
    index: 3,
    image: Elfantminer,
    webpImage: ElfantminerWebp,
    organization: t('applicationForm.testimonials.elfantminer.organization'),
    type: t('applicationForm.testimonials.elfantminer.type'),
    turnover: t('applicationForm.testimonials.elfantminer.turnover'),
    loan: `${t('applicationForm.testimonials.elfantminer.loan')} ${
      settings.countryCode === COUNTRY_CODE.SE ? '1M' : '100 000'
    } ${currencyValue}`,
    text: t('applicationForm.testimonials.elfantminer.text'),
    author: t('applicationForm.testimonials.elfantminer.author'),
  };

  const testimonials =
    settings.countryCode === COUNTRY_CODE.SE
      ? [theItalianCousins, saltoMarin, willBrand]
      : [theItalianCousins, saltoMarin, willBrand, elfantminer];

  var timer;
  useEffect(() => {
    testimonials.forEach((testimonial) => {
      const webpImg = new Image();
      webpImg.src = testimonial.webpImage;

      const img = new Image();
      img.src = testimonial.image;
    });

    timer = setTimeout(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1,
      );
      animate();
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [testimonials, activeIndex]);

  const handleLeftClick = (index) => {
    clearTimeout(timer);

    if (index === 0) {
      setActiveIndex(testimonials.length - 1);
    } else {
      setActiveIndex(index - 1);
    }

    timer = setTimeout(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1,
      );
    }, 10000);
  };

  const handleRightClick = (index) => {
    clearTimeout(timer);

    if (index === testimonials.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index + 1);
    }
    timer = setTimeout(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1,
      );
    }, 10000);
  };

  const testimonialContent = (testimonial) => {
    return (
      <div className="w-100">
        <div className="my-6 mx-2 d-flex">
          <div
            style={{
              height: '25px',
              width: '25px',
              backgroundColor: 'white',
              borderRadius: '50%',
              border: '1px solid #EBE8EF',
              zIndex: '1',
              marginTop: '115px',
              cursor: 'pointer',
            }}
            onClick={() => handleLeftClick(testimonial.index)}
          >
            <SVG src={arrowLeft} className="tw-h-full tw-w-full"></SVG>
          </div>

          <div className="w-100 mx-auto">
            <picture>
              <source srcSet={testimonial.webpImage} type="image/webp"></source>
              <img
                style={{
                  objectFit: 'cover',
                  objectPosition: testimonial.index === 0 ? 'center' : 'top',
                  height: '255px',
                  marginLeft: '-12.5px',
                  willChange: 'opacity',
                }}
                src={testimonial.image}
                alt={testimonial.organization}
                className={classnames('rounded-lg w-100', {
                  [styles.fade]: isAnimating,
                })}
              />
            </picture>
          </div>

          <div
            style={{
              height: '25px',
              width: '25px',
              backgroundColor: 'white',
              borderRadius: '50%',
              border: '1px solid #EBE8EF',
              marginLeft: '-25px',
              marginTop: '115px',
              cursor: 'pointer',
              zIndex: '1',
            }}
            onClick={() => handleRightClick(testimonial.index)}
          >
            <SVG src={arrowRight} className="tw-h-full tw-w-full"></SVG>
          </div>
        </div>
        <div className="mx-5">
          <h4>{testimonial.organization}</h4>

          <div className="d-flex my-4">
            <p
              className="py-1 px-2 mr-2"
              style={{
                borderRadius: '14px',
                fontSize: '11px',
                backgroundColor: '#D9D9D9',
              }}
            >
              {testimonial.type}
            </p>
            <p
              className="py-1 px-2 mr-2"
              style={{
                borderRadius: '14px',
                fontSize: '11px',
                backgroundColor: '#D9D9D9',
              }}
            >
              {testimonial.turnover}
            </p>
            <p
              className="py-1 px-2"
              style={{
                borderRadius: '14px',
                fontSize: '11px',
                backgroundColor: '#D9D9D9',
              }}
            >
              {testimonial.loan}
            </p>
          </div>

          <p>{testimonial.text}</p>
          <p>
            <b>{testimonial.author}</b>
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="text-gray-dark w-100 h-100 rounded-lg py-8">
      <div
        className="text-gray-dark rounded-lg h-100 px-4 py-8"
        style={{ border: '1px solid #EBE8EF', width: '100%' }}
      >
        <div className="mx-5">
          <h4>{t('applicationForm.testimonials.smallBusinessesTrustUs')}</h4>
          {settings.countryCode === 'FI' ? (
            <ContactsGoogle className="my-2" />
          ) : (
            <>
              <ContactsTrustpilot className="mt-5" />
              <ContactsGoogle className="my-2" />
            </>
          )}
        </div>
        {testimonialContent(testimonials[activeIndex])}
      </div>
    </div>
  );
};

export default AppFormPromotion;
