import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMobile } from '@krea/common/hooks';
import twMerge from '@krea/common/utils/tailwind-utils';

interface ILoanApplicationFormWrapperProps {
  hasTestimonials?: boolean;
}

export const LoanApplicationFormWrapper = ({
  children,
  hasTestimonials = false,
}: PropsWithChildren<ILoanApplicationFormWrapperProps>) => {
  const isMobile = useMobile();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="tw-mb-[120px] tw-mx-6 xl:tw-mx-7">
      <div
        className={twMerge('tw-h-full tw-mt-3 xl:tw-mt-8', {
          'tw-mx-auto md:tw-mx-8': isMobile,
          'tw-mx-auto xl:tw-w-2/3': !hasTestimonials && !isMobile,
        })}
      >
        {children}
      </div>
    </div>
  );
};
