import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import GoogleSVG from '@krea/common/images/svg/google/google-logo.svg';
import GoogleStarsSVG from '@krea/common/images/svg/google/google-stars.svg';
import { Text } from '@krea/common/shared-components/text';
import { COUNTRY_CODE, getCountryCode } from '@krea/common/utils';

import styles from './ContactsTrustpilot.module.scss';
const countryCode = getCountryCode();

const googleScore = countryCode === COUNTRY_CODE.FI ? '4.8 / 5' : '4.8 / 5';
const numberOfReviews = countryCode === COUNTRY_CODE.FI ? '32' : '205';
const linkToReviews =
  countryCode === COUNTRY_CODE.FI
    ? 'https://www.google.fi/search?q=kredita+oy&sca_esv=559344913&source=hp&ei=KcjlZN-OMIOSxc8Pw7iOkA8&iflsig=AD69kcEAAAAAZOXWOaf18IlCAADblheTMlWq3Ugx6zzE&ved=0ahUKEwifx_6Ls_KAAxUDSfEDHUOcA_IQ4dUDCAk&uact=5&oq=kredita+oy&gs_lp=Egdnd3Mtd2l6IgprcmVkaXRhIG95MgcQABgTGIAESL4MUABYlwhwAHgAkAEAmAFKoAGqBKoBAjEwuAEDyAEA-AEBwgIREC4YgAQYsQMYgwEYxwEYrwHCAgsQABiKBRixAxiDAcICCxAAGIAEGLEDGIMBwgILEC4YgAQYxwEY0QPCAgsQLhiABBixAxiDAcICCBAAGIAEGLEDwgIFEAAYgATCAggQLhiABBixA8ICCxAuGIMBGLEDGIAEwgIOEC4YgAQYsQMYxwEYrwHCAgcQABiABBgKwgIJEAAYExiABBgKwgIGEAAYHhgTwgIIEAAYHhgTGArCAgcQABgNGIAEwgINEC4YDRiABBjHARivAcICCRAAGA0YgAQYCg&sclient=gws-wiz#lrd=0xaf3b5115781e147b:0x9603c740b26dc66a,1,,'
    : 'https://www.google.com/search?q=krea&rlz=1C5CHFA_enSE1079SE1079&oq=krea++&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQABiABDIHCAIQABiABDIHCAMQABiABDIGCAQQRRg8MgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEIMTQwOWowajSoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x465f9da2eaf1bd2b:0xdeb87fa3293950cd,1,,,,';

const googleText = (i18n) => {
  switch (true) {
    case i18n.language === 'sv':
      return { reviews: 'recensioner' };
    case i18n.language === 'en':
      return { reviews: 'reviews' };
    case i18n.language === 'fi':
      return { reviews: 'arvostelua' };
    default:
      return 'reviews';
  }
};

const ContactsGoogle = ({ className }) => {
  const { i18n } = useTranslation();

  return (
    <a
      href={linkToReviews}
      target="_blank"
      style={{ textDecoration: 'none', color: 'inherit' }}
      rel="noreferrer"
    >
      <div
        className={classnames(
          styles.text,
          'd-flex align-items-center',
          className,
        )}
      >
        <div style={{ height: '25px', width: '115px' }}>
          <SVG src={GoogleStarsSVG} className="w-100 h-auto mt-0 p-0" />
        </div>
        <Text
          size="sm"
          className="shrink-0 text-nowrap font-weight-bold mx-2"
        >{`${googleScore}`}</Text>
        <div className={classnames(styles.logo, 'position-relative')}>
          <SVG src={GoogleSVG} className="position-absolute w-100 h-auto" />
        </div>
        <Text size="sm" className="shrink-0 text-nowrap font-weight-bold ml-1">
          Google
        </Text>
        <Text
          size="sm"
          className="font-weight-bold mx-2"
          style={{ opacity: '0.6' }}
        >
          |
        </Text>
        <Text
          size="sm"
          className="shrink-0 text-nowrap font-weight-bold"
          style={{ opacity: '0.6' }}
        >
          {`${numberOfReviews} ${googleText(i18n).reviews}`}
        </Text>
      </div>
    </a>
  );
};

export default ContactsGoogle;
