import { useQuery } from '@tanstack/react-query';

import {
  LOAN_APPLICATIONS_STORE_KEY,
  queryClient,
} from '@krea/common/store/queryClient';

import { fetchLoanApplicationsData } from 'store/loan-applications/api';

const getQueryArguments = () => ({
  queryKey: [LOAN_APPLICATIONS_STORE_KEY],
  queryFn: async () => {
    const response = await fetchLoanApplicationsData();

    return response?.loanApplications || [];
  },
});

const useGetLoanApplications = () => {
  const queryArguments = getQueryArguments();

  return useQuery(queryArguments);
};

const prefetchLoanApplications = async () => {
  const queryArguments = getQueryArguments();

  await queryClient.prefetchQuery(queryArguments);
};

export { useGetLoanApplications, prefetchLoanApplications };
