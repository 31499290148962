import React from 'react';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import { ApplicationTypeSelectorButton } from '@krea/common/shared-components/application-type-button';
import { Error } from '@krea/common/shared-components/fields/error';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { TEST_IDS } from '@krea/common/utils';

import { PRIMARY_REASONS } from '../../loan-application-form/fields/reason';
import { chunkArray } from '../utils';

interface IPrimaryPurposeFormProps {}

const PrimaryPurposeForm = ({
  control,
  goToPreviousStep,
  goToNextStep,
  currentStep,
}: {
  control: Control<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  currentStep: string;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}) => {
  const isMobile = useMobile();
  const { t } = useTranslation();

  const { field: { value, onChange: onInputChange } = {}, fieldState } =
    useController({
      name: 'reason',
      control,
    });

  const { reason, setReason, setRealEstateReason } =
    useLoanApplicationFormStore((state) => ({
      reason: state.reason,
      setReason: state.setReason,
      setRealEstateReason: state.setRealEstateReason,
    }));

  const onChange = (value: string) => {
    onInputChange!(value);
    setReason(value);

    if (value !== 'real_estate_financing') {
      // Reset real estate reason if not real estate financing
      setRealEstateReason('');
    }
  };

  const chunkSize = 3;
  const reasonChunks = chunkArray(PRIMARY_REASONS, chunkSize);

  const innerForm = (
    <div data-test-id={TEST_IDS.common.forms.reasonForm.container}>
      {fieldState.error?.message && fieldState.isTouched && (
        <Error className="tw-mt-2 tw-text-center">
          {fieldState.error?.message}
        </Error>
      )}
      {!isMobile && (
        <div>
          {reasonChunks.map((chunk, index) => (
            <div
              key={index}
              className={`tw-flex ${index === 0 ? 'tw-mt-4' : index === reasonChunks.length - 1 ? 'tw-mb-8' : ''}`}
              style={{ justifyContent: 'space-evenly', height: '75px' }}
            >
              {chunk.map((reason) => (
                <ApplicationTypeSelectorButton
                  key={reason.value}
                  text={reason.name}
                  id={reason.value}
                  setInputValue={onChange}
                  value={value}
                  data-test-id={TEST_IDS.common.forms.reasonForm.reasonValue(
                    reason.value,
                  )}
                />
              ))}
            </div>
          ))}
        </div>
      )}
      {isMobile && (
        <div className="tw-pt-4 tw-pb-5">
          {PRIMARY_REASONS.map((reason) => (
            <ApplicationTypeSelectorButton
              key={reason.value}
              text={reason.name}
              id={reason.value}
              setInputValue={onChange}
              value={value}
              className={'w-100 my-2 rounded text-primary'}
              data-test-id={TEST_IDS.common.forms.reasonForm.reasonValue(
                reason.value,
              )}
            />
          ))}
        </div>
      )}
    </div>
  );

  return (
    <>
      <LoanApplicationFormLayout
        heading={t('applicationForm.common.primaryPurposeHeading')}
        innerForm={innerForm}
        infoText={t('applicationForm.common.selectMainReason')}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        disabledNavigationBeforeFormCompletion={!reason}
      />
    </>
  );
};

export default PrimaryPurposeForm;
