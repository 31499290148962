import { isEmpty } from 'lodash';
import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useGetUserData } from '@krea/common/store/user/hooks/queries';
import {
  ALLOWED_STATES_FOR_DASHBOARD,
  ALLOWED_STATES_FOR_SELECTED_BID,
  LOAN_APPLICATION_STATES,
  LOAN_APPLICATION_STATES_WITH_DISABLED_PREVIEW_EDIT,
} from '@krea/common/utils';

import { useGetCurrentLoanApplication } from 'store/current-loan-application/hooks/queries';

interface IPrivateRouteProps {
  restrictedBasedOnState?: boolean;
  redirectOnPermissionDenied?: string;
}

const PrivateRoute = ({
  children,
  restrictedBasedOnState,
  redirectOnPermissionDenied = '/',
}: PropsWithChildren<IPrivateRouteProps>) => {
  const { pathname, search } = useLocation();

  const { data: user = {} } = useGetUserData();
  const { data: { state } = {} } = useGetCurrentLoanApplication();

  const isAllowed = () => {
    // Remove fist and last slash
    // Example: /profile/ => profile
    const currentPathWithoutSlash = pathname.replace(/^\/|\/$/g, '');
    const splitPath = currentPathWithoutSlash.split('/');
    const onlyRouteName = splitPath[1];

    if (state) {
      switch (onlyRouteName) {
        case 'dashboard':
          return ALLOWED_STATES_FOR_DASHBOARD.includes(state);
        case 'selected-lender-response':
          return ALLOWED_STATES_FOR_SELECTED_BID.includes(state);
        case 'advice':
          return state === LOAN_APPLICATION_STATES.ADVICE;
        case 'improve-application-questions':
          if (splitPath.length === 2 || splitPath[2] === 'preview') {
            return true;
          }

          return !LOAN_APPLICATION_STATES_WITH_DISABLED_PREVIEW_EDIT.includes(
            state,
          );
        default:
          return true;
      }
    }
  };

  if (isEmpty(user)) {
    // When trying to directly access pages behind log in, redirect to login.
    window.localStorage.setItem(
      'redirect',
      JSON.stringify({
        path: pathname + search,
        date: Date.now(),
        minuteDuration: 10,
      }),
    );

    return <Navigate replace={true} to="/login" />;
  }

  if (restrictedBasedOnState && !isAllowed()) {
    return <Navigate replace={true} to={redirectOnPermissionDenied} />;
  }

  return children;
};

export default PrivateRoute;
