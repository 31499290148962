import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormatCurrency } from '@krea/common/hooks';
import CheckSVG from '@krea/common/images/svg/check-colorless.svg';
import Button from '@krea/common/shared-components/button';

import settings from '../../../settings';

import styles from './ApplicationsSelectorItem.module.scss';

const ApplicationsSelectorItem = ({ data, checked, onClick }) => {
  const title = get(data, 'applyingCompany.customerCompany.name');
  const ssn = get(data, 'applyingCompany.customerCompany.organisationNumber');
  const currency = settings.currency;
  const [formattedCurrencyValue] = useFormatCurrency({ currency });

  const loanDetails = `${formattedCurrencyValue(data.requestedLoanAmount)} / ${data.requestedPaybackTimeMonths} mo`;
  const { t } = useTranslation();

  return (
    <div
      className={classnames(
        styles.root,
        'd-flex align-items-center bg-gray rounded overflow-hidden py-3 px-4',
      )}
      onClick={onClick}
      role="button"
      tabIndex="0"
      aria-label={title}
    >
      <dl className={classnames(styles.application, 'w-100 m-0 p-0 pr-3')}>
        <dt className="font-weight-bold">
          {title} ({ssn})
        </dt>
        <dd className="font-weight-bold m-0">{loanDetails}</dd>
      </dl>
      {checked ? (
        <div
          className={classnames(
            styles.check,
            'shrink-0 d-flex justify-content-center align-items-center rounded-circle',
          )}
        >
          <div className={classnames(styles.icon, 'position-relative')}>
            <SVG className="position-absolute w-100 h-auto" src={CheckSVG} />
          </div>
        </div>
      ) : (
        <div
          className={classnames(
            'shrink-0 d-flex justify-content-center align-items-center',
          )}
        >
          <Button variant="text">{t('applicationSelector.choose')}</Button>
        </div>
      )}
    </div>
  );
};

export default ApplicationsSelectorItem;
