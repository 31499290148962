import { yupResolver } from '@hookform/resolvers/yup';
import loadable from '@loadable/component';
import axios from 'axios';
import classNames from 'classnames';
import { parseIncompletePhoneNumber } from 'libphonenumber-js';
import { isEmpty, isObject } from 'lodash';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import { useController, useForm, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useShallow } from 'zustand/react/shallow';

import { EmailValidation } from '@krea/common/form-validation/EmailValidation';
import { OrganisationValidation } from '@krea/common/form-validation/OrganisationValidation';
import { PhoneNumberValidation } from '@krea/common/form-validation/PhoneNumberValidation';
import { RequestedLoanAmountValidation } from '@krea/common/form-validation/RequestedLoanAmountValidation';
import { useMobile } from '@krea/common/hooks';
import info from '@krea/common/images/svg/information-icon.svg';
import {
  IErrorResponse,
  ICustomExceptionErrors,
} from '@krea/common/models/commonInterfaces';
import { getUtmDataJSONString } from '@krea/common/services/formService';
import { settings } from '@krea/common/settings';
import Button from '@krea/common/shared-components/button';
import AmountSlider from '@krea/common/shared-components/loan-application-form/fields/amount-slider';
import EmailInput from '@krea/common/shared-components/loan-application-form/fields/email';
import OrganisationInput from '@krea/common/shared-components/loan-application-form/fields/organisation-number';
import PhoneInput from '@krea/common/shared-components/loan-application-form/fields/phone';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import { Text } from '@krea/common/shared-components/text';
import { useHasOngoingApplication } from '@krea/common/store/current-loan-application/hooks/useHasOngoingApplication';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { COUNTRY_CODE } from '@krea/common/utils';
import SVG from 'react-inlinesvg';
// import AppFormPromotion from '@krea/common/shared-components/loan-application-form/AppFormPromotion';

import { router } from 'router';
import CloudLoader from 'static/images-new/CloudLoader.gif';

const ValidationSchema = Yup.object().shape({
  organisation: OrganisationValidation(),
  email: EmailValidation(),
  mobilePhoneNumber: PhoneNumberValidation(),
  requestedLoanAmount: RequestedLoanAmountValidation(),
});

type ValidationSchemaType = Yup.InferType<typeof ValidationSchema>;

const AppFormPromotion = loadable(
  () =>
    import(
      '@krea/common/shared-components/loan-application-form/AppFormPromotion'
    ),
);

export const PreliminaryBidFormPage = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [, setStatus] = useState({ success: false });
  const { t, i18n } = useTranslation();
  const isMobile = useMobile();

  const {
    organisation: { name: organisationName, organisationNumber },
    email,
    mobilePhoneNumber,
    requestedLoanAmount,
    setOrganisation,
    setEmail,
    setMobilePhoneNumber,
    setRequestedLoanAmount,
    setPreliminaryBid,
    formErrors,
    removeFormError,
    utmCampaign,
  } = useLoanApplicationFormStore(
    useShallow((state) => ({
      organisation: state.organisation,
      email: state.email,
      mobilePhoneNumber: state.mobilePhoneNumber,
      requestedLoanAmount: state.requestedLoanAmount,
      setOrganisation: state.setOrganisation,
      setEmail: state.setEmail,
      setMobilePhoneNumber: state.setMobilePhoneNumber,
      setRequestedLoanAmount: state.setRequestedLoanAmount,
      setPreliminaryBid: state.setPreliminaryBid,
      formErrors: state.formErrors,
      removeFormError: state.removeFormError,
      utmCampaign: state.utmCampaign,
    })),
  );

  const { data } = useHasOngoingApplication(
    settings.countryCode,
    email,
    organisationNumber,
  );
  const ongoingApplicationExists = data?.hasHit;

  const { control, handleSubmit, getValues, setValue, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      organisation: {
        name: organisationName,
        organisationNumber: organisationNumber,
      },
      email,
      mobilePhoneNumber,
      requestedLoanAmount,
    },
    resolver: yupResolver(ValidationSchema),
  });

  const onSubmit = async () => {
    setSubmitting(true);

    const startTime = Date.now();
    const utm_data_json_string = getUtmDataJSONString(utmCampaign);
    const payload = {
      countryCode: settings.countryCode,
      email,
      mobilePhoneNumber,
      requestedLoanAmount,
      organisationName,
      organisationNumber,
      utmData: utm_data_json_string ? utm_data_json_string : null,
    };

    try {
      const response = await axios.post(
        `${settings.kreaBaseUrl}/api/v1/preliminary-loan-amount`,
        payload,
      );

      const finishTime = Date.now();
      const timeDiff = finishTime - startTime;

      const conversionData = {
        conversionValue: requestedLoanAmount,
        currency: settings.currency,
      };

      TagManager.dataLayer({
        dataLayer: {
          event: 'LeadGenerated',
          leadGeneratedConversion: conversionData,
        },
      });

      setStatus({ success: true });

      if (timeDiff >= 3000) {
        setSubmitting(false);
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 3000 - timeDiff);
      }

      setOrganisation({
        name: organisationName,
        organisationNumber: organisationNumber,
      });
      setEmail(email);
      setMobilePhoneNumber(parseIncompletePhoneNumber(mobilePhoneNumber));
      setRequestedLoanAmount(
        response.data?.useEdgeCaseLogic
          ? requestedLoanAmount
          : response.data?.estimatedMaxLoanAmount,
      );
      setPreliminaryBid(response.data);

      router.navigate('/amount-estimator/result');
    } catch (error: unknown) {
      const emailVerificationError = error as IErrorResponse;

      if (
        emailVerificationError &&
        emailVerificationError.data.errors.find(
          (error: ICustomExceptionErrors) => error.code === '1006',
        )
      ) {
        const translatedErrorMessage = t(
          'applicationForm.commons.emailErrors.validationError',
        );
        setError('email', { message: translatedErrorMessage });
      }
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const { field: organisationField, fieldState: organisationFieldState } =
    useController({
      name: 'organisation',
      control,
    });

  const { field: emailField, fieldState: emailFieldState } = useController({
    name: 'email',
    control,
    defaultValue: '',
  });

  const { field: phoneNumberField, fieldState: phoneNumberFieldState } =
    useController({
      name: 'mobilePhoneNumber',
      control,
    });

  const {
    field: { value, onChange: onInputChange },
    fieldState,
  } = useController({
    name: 'requestedLoanAmount',
    control,
  });

  const onChange = (name: string, value: number) => {
    onInputChange(value);
    setRequestedLoanAmount(value);
  };

  let isLimitedCompany = false;

  if (
    organisationName &&
    isEmpty(organisationFieldState.error) &&
    organisationFieldState.isTouched
  ) {
    const organisationSplitName = organisationName?.toLowerCase().split(' ');

    switch (settings.countryCode) {
      case COUNTRY_CODE.SE:
        isLimitedCompany =
          organisationName.replace(' ', '') !== 'ab' &&
          organisationSplitName.includes('ab');
        break;
      case COUNTRY_CODE.FI:
        isLimitedCompany =
          organisationName.replace(' ', '') !== 'oy' &&
          organisationSplitName.includes('oy');
        break;
      default:
        isLimitedCompany = false;
    }
  }

  const infoText = (
    <span>
      {t('applicationForm.common.businessNotFoundContactSupport')}
      {isMobile && <br />}
      {t('applicationForm.common.callUs')}{' '}
      <Button
        variant="text"
        style={{ whiteSpace: 'nowrap' }}
        className="tw-font-bold tw-text-primary"
        size={isMobile ? 'sm' : 'md'}
        onClick={() =>
          window.open(`tel:${settings.contacts.phone.link}`, '_self')
        }
      >
        {settings.contacts.phone.value}
      </Button>
    </span>
  );

  const disableNavigation = !(
    (organisationField.value.name ||
      organisationField.value.organisationNumber) &&
    emailField.value &&
    phoneNumberField.value &&
    requestedLoanAmount &&
    isEmpty(organisationFieldState.error) &&
    isEmpty(emailFieldState.error) &&
    isEmpty(phoneNumberFieldState.error) &&
    isEmpty(fieldState.error)
  );

  const triggerFieldValidation = (fields: Path<ValidationSchemaType>[]) => {
    for (const field of fields) {
      const fieldValue = getValues(field);

      if (isObject(fieldValue)) {
        Object.keys(fieldValue).forEach((key) => {
          const nestedKey = `${field}.${key}`;
          // @ts-ignore
          setValue(nestedKey, fieldValue[key], {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        });
      } else {
        setValue(field, fieldValue, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    }
  };

  const innerForm = (
    <>
      {isSubmitting && (
        <div
          className="tw-border-primary tw-w-full tw-my-6 tw-rounded-lg tw-px-4"
          style={{ border: '1.5px solid', justifyContent: 'center' }}
        >
          <div className="tw-w-full tw-h-[75%] tw-flex tw-flex-col tw-items-center tw-mt-7">
            <div className="tw-text-[1.5rem] tw-m-0 tw-text-center">
              {i18n.t('preliminaryBidForm.loadingText')}
            </div>
            <div className={classNames('tw-w-full tw-inset-0')}>
              <img
                className="tw-relative tw-w-200 tw-h-auto tw-mx-auto"
                src={CloudLoader}
                alt="Loader"
              />
            </div>
          </div>
        </div>
      )}

      {!isSubmitting && (
        <div
          className="tw-border-primary tw-w-full tw-my-6 tw-rounded-lg tw-px-4"
          style={{ border: '1.5px solid', justifyContent: 'center' }}
        >
          <div className="tw-mt-2">
            <OrganisationInput
              value={organisationField.value}
              errors={
                !isEmpty(organisationFieldState.error) && {
                  organisationNumber:
                    // @ts-ignore
                    organisationFieldState.error?.organisationNumber?.message,
                  // @ts-ignore
                  name: organisationFieldState.error?.name?.message,
                }
              }
              touched={
                organisationFieldState.isTouched && {
                  organisationNumber: organisationFieldState.isTouched,
                  name: organisationFieldState.isTouched,
                }
              }
              setInputValue={(name: string, value: any) => {
                let updatedOrganisation = null;
                const prevValues = getValues('organisation');
                const lowerCasedName = name.toLowerCase();

                if (lowerCasedName.includes('name')) {
                  updatedOrganisation = { ...prevValues, name: value };
                } else if (lowerCasedName.includes('number')) {
                  updatedOrganisation = {
                    ...prevValues,
                    organisationNumber: value,
                  };
                }

                if (updatedOrganisation) {
                  organisationField.onChange(updatedOrganisation);
                  setOrganisation(updatedOrganisation);
                }
              }}
              isLimitedCompany={isLimitedCompany}
              onBlur={() => {
                triggerFieldValidation(['organisation']);
              }}
            />
          </div>
          <div className="tw-mx-1 tw-mt-7">
            <AmountSlider
              name={'requestedLoanAmount'}
              value={value}
              setInputValue={onChange}
              errors={fieldState.error?.message}
              touched={fieldState.isTouched}
              shouldAnimate={false}
              isRealEstate={false}
            />
          </div>
          <div className="tw-mt-5">
            <EmailInput
              label={t('applicationForm.commons.emailLabel', {
                ns: 'common',
              })}
              value={emailField.value}
              errors={emailFieldState.error?.message}
              touched={formErrors.email || emailFieldState.isTouched}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                emailField.onChange(e.target.value);
                setEmail(e.target.value);
                removeFormError('email');
              }}
              onBlur={() => {
                triggerFieldValidation(['email']);
              }}
            />
          </div>

          {ongoingApplicationExists && (
            <div
              className={classNames('tw-rounded tw-mt-3 tw-mb-5 tw-flex', {
                'tw-p-1': isMobile,
                'tw-py-2 tw-px-1': !isMobile,
              })}
              style={{ border: '1px solid #EBE8EF' }}
            >
              <SVG
                src={info}
                style={
                  isMobile
                    ? { width: '22.5px', marginRight: '10px' }
                    : { width: '20px' }
                }
                className="tw-ml-3 tw-h-auto"
              ></SVG>

              <div className={isMobile ? 'tw-ml-1' : 'tw-ml-3'}>
                <Text
                  style={{ lineHeight: '1.5' }}
                  size={isMobile ? 'xs' : 'sm'}
                >
                  {t('applicationForm.common.applicationExists')}
                </Text>

                <Text size={isMobile ? 'xs' : 'sm'} className="tw-font-bold">
                  <a href="/login#bankid-container">
                    {t('applicationForm.common.loginToApplication')}
                  </a>
                </Text>
              </div>
            </div>
          )}

          <div className="mb-4 mt-5">
            <PhoneInput
              label={t('applicationForm.commons.phoneLabel', {
                ns: 'common',
              })}
              value={phoneNumberField.value}
              touched={phoneNumberFieldState.isTouched}
              errors={phoneNumberFieldState.error?.message}
              handleChange={(e: { target: { value: unknown } }) => {
                setMobilePhoneNumber(e.target.value);
                phoneNumberField.onChange(e.target.value);
              }}
              onBlur={() => {
                triggerFieldValidation(['mobilePhoneNumber']);
              }}
            />
          </div>
        </div>
      )}
    </>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="tw-flex">
        <div style={isMobile ? { width: '100%' } : { width: '65%' }}>
          <div className="tw-mx-auto" style={!isMobile ? { width: '80%' } : {}}>
            <LoanApplicationFormLayout
              heading={t('preliminaryBidForm.preliminaryBidHeading')}
              subheading={
                <>
                  <span className="tw-font-bold">
                    {settings.countryCode === COUNTRY_CODE.FI
                      ? t(
                          'preliminaryBidForm.preliminaryBidSubheadingFinnishVersion',
                        )
                      : t('preliminaryBidForm.preliminaryBidSubheading')}
                  </span>
                  <br />
                  <span>{t('preliminaryBidForm.weCollectPublicData')}</span>
                </>
              }
              innerForm={innerForm}
              bottomInfoText={infoText}
              hideAdvisorCTABanner
              hasTestimonials
              hideNavigation={isSubmitting}
              disabledNavigationBeforeFormCompletion={disableNavigation}
              customButtonText={
                settings.countryCode === COUNTRY_CODE.FI
                  ? t('preliminaryBidForm.estimateLimitFinnishVersion')
                  : t('preliminaryBidForm.estimateLimit')
              }
              borderlessInnerForm
              goToNextStep={async () => {
                // Ensures validation is triggered when an incorrect value has been cached and set as the default value of an input field
                triggerFieldValidation([
                  'organisation',
                  'email',
                  'mobilePhoneNumber',
                  'requestedLoanAmount',
                ]);
              }}
              goToPreviousStep={() => {}}
            />
          </div>
        </div>

        {!isMobile && (
          <div
            className="tw-mx-2"
            style={{
              minWidth: '400px',
              maxWidth: '500px',
              minHeight: '855px',
              maxHeight: '950px',
            }}
          >
            <AppFormPromotion />
          </div>
        )}
      </div>
    </form>
  );
};
