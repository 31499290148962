import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';
import { useShallow } from 'zustand/react/shallow';

import { settings } from '@krea/common/settings';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks/useLoanApplicationFormStore';

import { createLead } from '../../api';
import { getLeadInput } from '../../utils';

export const useCreateLead = () => {
  const setPreApplicationLead = useLoanApplicationFormStore(
    (state) => state.setPreApplicationLead,
  );

  return useMutation({
    mutationFn: () => {
      const input = getLeadInput();

      // We track the PRE_DRAFT_LEAD event in Google Tag Manager
      TagManager.dataLayer({
        dataLayer: {
          event: 'LeadGenerated',
        },
      });

      return createLead(input);
    },
    onSuccess: async (data) => {
      if (data) {
        setPreApplicationLead(data);
      }
    },
    onError: (err) => {
      console.error(`Failed creating a lead`, err);
      Sentry.captureException(err);
    },
  });
};
