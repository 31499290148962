import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { EmailValidation } from '../../../form-validation/EmailValidation';
import { LeasingBaseValidation } from '../../../form-validation/LeasingValidation';
import { OrganisationValidation } from '../../../form-validation/OrganisationValidation';
import { PhoneNumberValidation } from '../../../form-validation/PhoneNumberValidation';
import { PropertyUnitValidation } from '../../../form-validation/PropertyUnitValidation';
import { ReasonValidation } from '../../../form-validation/ReasonValidation';
import { RequestedLoanAmountValidation } from '../../../form-validation/RequestedLoanAmountValidation';
import {
  UnpaidInvoicesBaseValidation,
  UnpaidInvoicesDescriptionValidation,
} from '../../../form-validation/UnpaidInvoicesValidation';
import { getDefaultValues } from '../utils';

const getValidationSchema = () =>
  Yup.object().shape({
    organisation: OrganisationValidation(),
    email: EmailValidation(),
    mobilePhoneNumber: PhoneNumberValidation(),
    reason: ReasonValidation(),
    realEstateReason: ReasonValidation(),
    unpaidInvoices: UnpaidInvoicesBaseValidation(),
    unpaidInvoicesDescription: UnpaidInvoicesDescriptionValidation(),
    requestedLoanAmount: RequestedLoanAmountValidation(),
    propertyUnitDesignation: PropertyUnitValidation(),
    leasing: LeasingBaseValidation(),
    realEstateRequestedLoanAmount: RequestedLoanAmountValidation(),
  });

export const useApplicationFlowForm = () => {
  return useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(),
    resolver: yupResolver(getValidationSchema()),
  });
};
