import { useGetCachedCurrentLoanApplication } from '@krea/common/store/current-loan-application/hooks';
import { useTranslation } from 'react-i18next';
import { useMobile } from '@krea/common/hooks';
import { settings } from '@krea/common/settings';
import advisorIconKredita from '@krea/common/images/company/Anna_Eronen.png';
import advisorIcon from '@krea/common/images/company/Ella_Almstedt.jpg';
import phoneIcon from '@krea/common/images/svg/contacts/new/phone.svg';
import { Text } from '@krea/common/shared-components/text';
import { MouseEventHandler } from 'react';
import SVG from 'react-inlinesvg';

const ContactSection = ({
  icon,
  onClick,
  value,
  href,
}: {
  icon: string;
  onClick: MouseEventHandler<HTMLAnchorElement> | undefined;
  value: string | undefined;
  href: string;
}) => {
  const isMobile = useMobile();

  return (
    <div className="tw-mb-1">
      <div className="tw-flex tw-mr-6">
        <SVG
          className="tw-block tw-text-primary tw-mt-[7px] xl:tw-mt-1"
          style={
            isMobile
              ? { marginLeft: '-15px', height: '15px' }
              : { marginLeft: '-13px', height: '20px' }
          }
          src={icon}
          onClick={onClick as unknown as MouseEventHandler<SVGElement>}
        />
        <Text size="md">
          <a
            className="tw-font-bold tw-text-primary tw-cursor-pointer tw--ml-[5px] lg:tw--ml-[3px]"
            onClick={onClick}
            href={href}
            style={isMobile ? { fontSize: '13px' } : {}}
          >
            {value}
          </a>
        </Text>
      </div>
    </div>
  );
};

export const AdvisorCTABanner = () => {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const countryCode = settings.countryCode;

  const { data: { assignedToAdminUser: person } = {} } =
    useGetCachedCurrentLoanApplication();

  const { mobilePhoneNumber, profileImage } = person || {};

  return (
    <div className="tw-border tw-border-solid tw-border-[#EBE8EF] lg:tw-justify-between tw-align-middle lg:tw-flex tw-my-8 tw-rounded-lg tw-p-0">
      <div className="tw-flex">
        <div className="xl:tw-mt-5 tw-m-4 tw-h-auto tw-align-middle tw-shrink-0 tw-w-[50px]">
          <img
            className="tw-h-full tw-w-full"
            src={
              profileImage
                ? profileImage.lowResolutionUrl
                : countryCode === 'FI'
                  ? advisorIconKredita
                  : advisorIcon
            }
          />
        </div>

        <div className="lg:tw-my-auto tw-mt-3">
          <p
            className="tw-font-bold tw-m-0"
            style={isMobile ? { fontSize: '14px' } : {}}
          >
            {t('applicationForm.common.anyQuestions')}
          </p>
          <p className="tw-mb-0" style={isMobile ? { fontSize: '12px' } : {}}>
            {t('applicationForm.common.happyToHelp')}
          </p>
        </div>
      </div>

      <div className="lg:tw-my-auto tw-ml-[82px] tw-mt-0 sm:tw--mt-6 ">
        <ContactSection
          icon={phoneIcon}
          onClick={() =>
            window.open(
              `tel:${mobilePhoneNumber ? mobilePhoneNumber.replace(/[^+\d]/g, '') : settings.contacts.phone.link}`,
              '_self',
            )
          }
          href={`tel:${mobilePhoneNumber ? mobilePhoneNumber.replace(/[^+\d]/g, '') : settings.contacts.phone.link}`}
          value={
            mobilePhoneNumber
              ? mobilePhoneNumber
              : settings.contacts.phone.value
          }
        />
      </div>
    </div>
  );
};
