import classNames from 'classnames';

import { useMobile } from '@krea/common/hooks';
import { AdvisorCTABanner } from '@krea/common/shared-components/advisor-cta-banner';
import LoanApplicationFormNavigation from '@krea/common/shared-components/loan-application-form-navigation';
import { LoanApplicationFormWrapper } from '@krea/common/shared-components/loan-application-form-wrapper';
import PreliminaryBidResult from '@krea/common/shared-components/preliminary-bid-result/PreliminaryBidResult';
import { Toast } from '@krea/common/shared-components/toast/Toast';
import { TEST_IDS } from '@krea/common/utils';

const LoanApplicationFormLayout = ({
  heading,
  subheading,
  upperInfoText,
  innerForm,
  infoText,
  bottomInfoText,
  warningInfoTextIndex,
  customButtonText,
  goToNextStep,
  goToPreviousStep,
  hideAdvisorCTABanner = false,
  hasTestimonials = false,
  hideNavigation = false,
  hidePrivacyLinkText = false,
  disabledNavigationBeforeFormCompletion = false,
  borderlessInnerForm = false,
  showPreliminaryBidResult = false,
}: {
  heading: string;
  subheading?: string | null | React.ReactNode;
  upperInfoText?: React.ReactNode;
  innerForm: React.ReactNode;
  infoText?: React.ReactNode;
  bottomInfoText?: React.ReactNode;
  warningInfoTextIndex?: number;
  customButtonText?: string | null;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  hideAdvisorCTABanner?: boolean;
  hasTestimonials?: boolean;
  hideNavigation?: boolean;
  hidePrivacyLinkText?: boolean;
  disabledNavigationBeforeFormCompletion?: boolean;
  borderlessInnerForm?: boolean;
  showPreliminaryBidResult?: boolean;
}) => {
  const isMobile = useMobile();

  const infoBanner = (text: React.ReactNode, warningInfoTextIndex: boolean) => (
    <Toast
      innerText={{
        subtitle: text,
      }}
      isWarning={warningInfoTextIndex}
    />
  );

  return (
    <LoanApplicationFormWrapper hasTestimonials={hasTestimonials}>
      {isMobile ? (
        <h3 data-test-id={TEST_IDS.common.forms.loanApplication.heading}>
          {heading}
        </h3>
      ) : (
        <h1 data-test-id={TEST_IDS.common.forms.loanApplication.heading}>
          {heading}
        </h1>
      )}
      {subheading && (
        <p
          className="tw-mt-3"
          style={isMobile ? { fontSize: '16px' } : { fontSize: '18px' }}
        >
          {subheading}
        </p>
      )}
      {upperInfoText && infoBanner(upperInfoText, warningInfoTextIndex === 1)}
      {showPreliminaryBidResult && (
        <div className="tw-my-3">
          <PreliminaryBidResult />
        </div>
      )}
      {innerForm && (
        <div
          className={classNames('tw-w-full', {
            'tw-rounded-lg tw-border-primary tw-px-4 tw-my-6':
              !borderlessInnerForm,
          })}
          style={!borderlessInnerForm ? { border: '1.5px solid' } : {}}
        >
          {innerForm}
        </div>
      )}
      {infoText && infoBanner(infoText, warningInfoTextIndex === 2)}
      {!hideNavigation && (
        <LoanApplicationFormNavigation
          customButtonText={customButtonText}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          hasTestimonials={hasTestimonials}
          hidePrivacyLinkText={hidePrivacyLinkText}
          disabledNavigationBeforeFormCompletion={
            disabledNavigationBeforeFormCompletion
          }
        />
      )}
      {bottomInfoText && infoBanner(bottomInfoText, warningInfoTextIndex === 3)}
      {!hideAdvisorCTABanner && <AdvisorCTABanner />}
    </LoanApplicationFormWrapper>
  );
};

export default LoanApplicationFormLayout;
