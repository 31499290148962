import classNames from 'classnames';
import React from 'react';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import AmountSlider from '@krea/common/shared-components/loan-application-form/fields/amount-slider';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';

const FinancingAmountForm = ({
  control,
  goToPreviousStep,
  goToNextStep,
}: {
  control: Control<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  currentStep: string;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}) => {
  const isMobile = useMobile();
  const { t } = useTranslation();

  const setRequestedLoanAmount = useLoanApplicationFormStore(
    (state) => state.setRequestedLoanAmount,
  );

  const {
    field: { value, onChange: onInputChange },
    fieldState,
  } = useController({
    name: 'requestedLoanAmount',
    control,
  });

  const onChange = (_: unknown, value: number) => {
    onInputChange(value);
    setRequestedLoanAmount(value);
  };

  const innerForm = (
    <div>
      <div className={classNames('tw-mt-6 tw-mb-4', { 'tw-mx-2': !isMobile })}>
        <AmountSlider
          name={'requestedLoanAmount'}
          value={value}
          setInputValue={onChange}
          errors={fieldState.error?.message}
          touched={fieldState.isTouched}
          shouldAnimate={false}
          isRealEstate={false}
        />
      </div>
    </div>
  );

  return (
    <>
      <LoanApplicationFormLayout
        heading={t('applicationForm.common.financingAmountHeading')}
        innerForm={innerForm}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        hideAdvisorCTABanner
        showPreliminaryBidResult
      />
    </>
  );
};

export default FinancingAmountForm;
