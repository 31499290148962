import { sortBy } from 'lodash';
import React, { Children } from 'react';
import { withTranslation, getI18n, useTranslation } from 'react-i18next';

import { Select } from '@krea/common/shared-components/fields/select/';
import { Helper } from '@krea/common/shared-components/helper';

const i18n = getI18n();

export const REAL_ESTATE_REASONS = [
  {
    value: 'bridge_financing_shorter_payment_time',
    get name() {
      return i18n.t(
        'applicationForm.commons.reasons.bridge_financing_shorter_payment_time',
      );
    },
  },
  {
    value: 'project',
    get name() {
      return i18n.t('applicationForm.commons.reasons.project_finance');
    },
  },
  {
    value: 'purchase_real_estate',
    get name() {
      return i18n.t('applicationForm.commons.reasons.purchase_real_estate');
    },
  },
  {
    value: 'refinance_current_property_loan',
    get name() {
      return i18n.t(
        'applicationForm.commons.reasons.refinance_current_property_loan',
      );
    },
  },
  {
    value: 'real_estate_new_construction_or_renovation',
    get name() {
      return i18n.t(
        'applicationForm.commons.reasons.real_estate_new_construction_or_renovation',
      );
    },
  },
  {
    value: 'other_property_finance',
    get name() {
      return i18n.t('applicationForm.commons.reasons.other_property_finance');
    },
  },
];

export const PRIMARY_REASONS = [
  {
    value: 'object_financing',
    get name() {
      return i18n.t('applicationForm.commons.reasons.object_financing');
    },
  },
  {
    value: 'supply',
    get name() {
      return i18n.t('applicationForm.commons.reasons.supply');
    },
  },
  {
    value: 'other_expansion',
    get name() {
      return i18n.t('applicationForm.commons.reasons.other_expansion');
    },
  },
  {
    value: 'other_liquidity_needs',
    get name() {
      return i18n.t('applicationForm.commons.reasons.other_liquidity_needs');
    },
  },
  {
    value: 'debt',
    get name() {
      return i18n.t('applicationForm.commons.reasons.debt');
    },
  },
  {
    value: 'real_estate_financing',
    get name() {
      return i18n.t('applicationForm.commons.reasons.real_estate_financing');
    },
  },
  {
    value: 'invoice_financing',
    get name() {
      return i18n.t('applicationForm.commons.reasons.invoice_financing');
    },
  },
  {
    value: 'purchase',
    get name() {
      return i18n.t('applicationForm.commons.reasons.purchase');
    },
  },
  {
    value: 'other',
    get name() {
      return i18n.t('applicationForm.commons.reasons.other');
    },
  },
];

// Combine all valid reasons, used for general reason dropdowns.
// "real_estate_financing" is not included here, since it's a special case, a psuedo reason in the flow to later pick an actual real estate reason.
export const REASONS = [
  ...PRIMARY_REASONS.filter((r) => r.value !== 'real_estate_financing'),
  ...REAL_ESTATE_REASONS,
];
