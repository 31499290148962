import React, { useContext } from 'react';

import LoanApplicationProgressBar from 'krea-direct/components/progress-bar';

import { MenuContext } from './index';

const HeaderSteps = () => {
  const context = useContext(MenuContext);

  return (
    <LoanApplicationProgressBar
      isOpen={context.stepsIsVisible}
      hideStepsMenu={context.showSteps}
    />
  );
};

export default HeaderSteps;
