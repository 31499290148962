import { useQuery, useQueryClient } from '@tanstack/react-query';
import { camelCase, transform } from 'lodash';

import { fetchDelegatedFieldRequestsData } from '@krea/common/store/field-requests/api';
import {
  CURRENT_LOAN_APPLICATION_STORE_KEY,
  DELEGATED_FIELD_REQUESTS_STORE_KEY,
  ONE_FIELD_REQUEST_STORE_KEY,
  USER_STORE_KEY,
} from '@krea/common/store/queryClient';
import { formatFRFields } from '@krea/common/utils';

export const useGetDelegatedFieldRequests = () => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [DELEGATED_FIELD_REQUESTS_STORE_KEY],
    queryFn: async () => {
      try {
        const response = await fetchDelegatedFieldRequestsData();
        const { fieldRequests = [], loanApplication = {} } =
          response?.data || {};

        const loanApplicationID = loanApplication?.id?.toString();
        const userData = {
          ...loanApplication.contactPerson,
          currentLoanApplication: { id: loanApplicationID },
        };

        const transformedFieldRequests = fieldRequests.map((field) => {
          return transform(field, function (result, val, key) {
            result[camelCase(key)] = val;
          });
        });

        const formattedFieldRequests = formatFRFields(transformedFieldRequests);

        // Set user, loan application and field requests data
        queryClient.setQueryData([USER_STORE_KEY], userData);
        queryClient.setQueryData(
          [CURRENT_LOAN_APPLICATION_STORE_KEY, loanApplicationID],
          loanApplication,
        );

        formattedFieldRequests.forEach((fieldRequest) => {
          queryClient.setQueryData(
            [ONE_FIELD_REQUEST_STORE_KEY, fieldRequest?.id],
            fieldRequest,
          );
        });

        return formattedFieldRequests;
      } catch (err) {
        console.error('Failed to lender responses data: err', err);
      }
    },
  });
};
