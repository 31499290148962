import React from 'react';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextInput from '@krea/common/shared-components/fields/input';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';

import { TEST_IDS } from '../../../utils';

const RealEstateExtendedForm = ({
  control,
  goToPreviousStep,
  goToNextStep,
  currentStep,
}: {
  control: Control<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  currentStep: string;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}) => {
  const { t } = useTranslation();

  const { propertyUnitDesignation, setPropertyUnitDesignation } =
    useLoanApplicationFormStore((state) => ({
      setPropertyUnitDesignation: state.setPropertyUnitDesignation,
      propertyUnitDesignation: state.propertyUnitDesignation,
    }));

  const {
    field: { value, onChange: onInputChange },
    fieldState,
  } = useController({
    name: 'propertyUnitDesignation',
    control,
  });

  const onChange = (value: string) => {
    onInputChange(value);
    setPropertyUnitDesignation(value);
  };

  const innerForm = (
    <div className="tw-mt-4">
      <TextInput
        size="lg"
        id="propertyUnitDesignation"
        type="text"
        name="propertyUnitDesignation"
        label={t(
          'applicationForm.realEstate.fields.propertyUnitDesignation.label',
        )}
        placeholder={`${t(
          'applicationForm.realEstate.fields.propertyUnitDesignation.placeholder',
        )}`}
        autoComplete="off"
        className="lg:tw-mx-6 tw-mb-7"
        value={value}
        error={
          fieldState.isTouched && fieldState.error?.message
            ? fieldState.error?.message
            : undefined
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        data-test-id={
          TEST_IDS.common.forms.realEstateForm.propertyUnitDesignationInput
        }
      />
    </div>
  );

  return (
    <>
      <LoanApplicationFormLayout
        heading={t('applicationForm.common.realEstateExtendedHeading')}
        subheading={null}
        innerForm={innerForm}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        disabledNavigationBeforeFormCompletion={!propertyUnitDesignation}
      />
    </>
  );
};

export default RealEstateExtendedForm;
