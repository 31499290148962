import axios from 'axios';

import { initCommonSettings } from '@krea/common/settings';
import { APPLICATION_NAME } from '@krea/common/utils';

const getKreaBaseUrl = () => {
  let url = 'http://localhost:8000';

  if (import.meta.env.REACT_APP_APPLICATION_ENV === 'production') {
    url = 'https://customer-api.krea.se';
  } else if (import.meta.env.REACT_APP_APPLICATION_ENV === 'staging') {
    url = 'https://staging.krea.se';
  } else if (import.meta.env.REACT_APP_APPLICATION_ENV === 'test') {
    url = 'https://test.krea.se';
  } else if (import.meta.env.REACT_APP_APPLICATION_ENV === 'local-docker') {
    url = 'http://localhost:8000';
  }

  return url;
};

let settings = {
  appName: APPLICATION_NAME.CUSTOMER_WEB,
  environment: import.meta.env.REACT_APP_APPLICATION_ENV || 'local',
  kreaBaseUrl: getKreaBaseUrl(),
  unauthorizedPaths: [
    '/login',
    '/delegated-information',
    '/identify',
    '/identify/success',
    '/direct/loan-application-form',
    '/direct/factoring-application-form',
    '/amount-estimator',
    '/direct/real-estate-application-form',
    '/direct/leasing-application-form',
    '/application',
  ],
};
settings = initCommonSettings(settings);

// Set global axios config
axios.defaults.baseURL = settings.kreaBaseUrl;
axios.defaults.withCredentials = false;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

export default settings;
