import axios from 'axios';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useLeasingObjectsList = () => {
  const { i18n } = useTranslation();
  const [objects, setObjects] = useState([{ display_text: '', value: '' }]);

  useEffect(() => {
    axios
      .get(`api/v1/leasing-product-types`, {
        params: { language: i18n.language },
      })
      .then((res) => setObjects(sortBy(res.data, (r) => r.name)));
  }, [i18n.language]);

  return objects;
};
