import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TrustPilotColorSVG from '@krea/common/images/svg/trustpilot/trustpilot-star-color.svg';
import { Text } from '@krea/common/shared-components/text';

import styles from './ContactsTrustpilot.module.scss';

const trustpilotText = (i18n) => {
  switch (true) {
    case i18n.language === 'sv':
      return { rating: 'Utmärkt', reviews: 'omdömen' };
    case i18n.language === 'en':
      return { rating: 'Excellent', reviews: 'reviews' };
    case i18n.language === 'fi':
      return { rating: 'Erinomainen', reviews: 'arvostelua' };
    default:
      return 'Excellent';
  }
};

const ContactsTrustpilot = ({ className }) => {
  const { i18n } = useTranslation();
  const linkToReviews = 'https://www.trustpilot.com/review/krea.se';

  return (
    <a
      href={linkToReviews}
      target="_blank"
      style={{ textDecoration: 'none', color: 'inherit' }}
      rel="noreferrer"
    >
      <div
        className={classnames(
          styles.text,
          'd-flex align-items-center',
          className,
        )}
      >
        <Text
          size="sm"
          className="shrink-0 text-nowrap font-weight-bold mr-2"
        >{`${trustpilotText(i18n).rating} 4.7 / 5`}</Text>

        <div className={classnames(styles.logo, 'position-relative')}>
          <SVG
            src={TrustPilotColorSVG}
            className="position-absolute w-100 h-auto"
          />
        </div>
        <Text size="sm" className="shrink-0 text-nowrap font-weight-bold ml-1">
          Trustpilot
        </Text>
        <Text
          size="sm"
          className="font-weight-bold mx-2"
          style={{ opacity: '0.6' }}
        >
          |
        </Text>

        <Text
          size="sm"
          className="shrink-0 text-nowrap font-weight-bold"
          style={{ opacity: '0.6' }}
        >
          {`341 ${trustpilotText(i18n).reviews}`}
        </Text>
      </div>
    </a>
  );
};

export default ContactsTrustpilot;
