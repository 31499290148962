import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import { isEmpty, isObject } from 'lodash';
import React from 'react';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useMobile } from '@krea/common/hooks';
import info from '@krea/common/images/svg/information-icon.svg';
import { settings } from '@krea/common/settings';
import EmailInput from '@krea/common/shared-components/loan-application-form/fields/email';
import OrganisationInput from '@krea/common/shared-components/loan-application-form/fields/organisation-number';
import PhoneInput from '@krea/common/shared-components/loan-application-form/fields/phone';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import { Text } from '@krea/common/shared-components/text';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';

import { useHasOngoingApplication } from '../../../store/current-loan-application/hooks/useHasOngoingApplication';
import { COUNTRY_CODE, TEST_IDS } from '../../../utils';

interface IOrganisationFieldStateError {
  organisationNumber?: { message: string };
  name?: { message: string };
}

const BasicDetailsForm = ({
  hasTestimonials,
  control,
  getValues,
  goToNextStep,
  setValue,
}: {
  hasTestimonials: boolean;
  control: Control<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  currentStep: string;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  setValue: UseFormSetValue<FieldValues>;
}) => {
  const {
    organisation: { name: organisationName, organisationNumber },
    email,
    setOrganisation,
    setEmail,
    setMobilePhoneNumber,
    formErrors,
    removeFormError,
  } = useLoanApplicationFormStore(
    useShallow((state) => ({
      organisation: state.organisation,
      email: state.email,
      setOrganisation: state.setOrganisation,
      setEmail: state.setEmail,
      setMobilePhoneNumber: state.setMobilePhoneNumber,
      formErrors: state.formErrors,
      removeFormError: state.removeFormError,
    })),
  );

  const isMobile = useMobile();

  const { t } = useTranslation();

  const { field: organisationField, fieldState: organisationFieldState } =
    useController({
      name: 'organisation',
      control,
      defaultValue: { name: '', organisationNumber: '' },
    });

  const { field: emailField, fieldState: emailFieldState } = useController({
    name: 'email',
    control,
    defaultValue: '',
  });

  const { field: phoneNumberField, fieldState: phoneNumberFieldState } =
    useController({
      name: 'mobilePhoneNumber',
      control,
      defaultValue: '',
    });

  const { data } = useHasOngoingApplication(
    settings.countryCode,
    email,
    organisationNumber,
  );
  const ongoingApplicationExists = data?.hasHit;

  let isLimitedCompany = false;

  if (organisationName && isEmpty(organisationFieldState.error)) {
    const organisationSplitName = organisationName?.toLowerCase().split(' ');

    switch (settings.countryCode) {
      case COUNTRY_CODE.SE:
        isLimitedCompany =
          organisationName.replace(' ', '') !== 'ab' &&
          organisationSplitName.includes('ab');
        break;
      case COUNTRY_CODE.FI:
        isLimitedCompany =
          organisationName.replace(' ', '') !== 'oy' &&
          organisationSplitName.includes('oy');
        break;
      default:
        isLimitedCompany = false;
    }
  }

  const organisationFieldOnBlur = () => {
    const field = 'organisation';
    const fieldValue: Record<string, string> = getValues(field);

    if (isObject(fieldValue)) {
      Object.keys(fieldValue).forEach((key) => {
        const nestedKey = `${field}.${key}`;
        setValue(nestedKey, fieldValue[key], {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      });
    } else {
      setValue(field, fieldValue, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const innerForm = (
    <>
      <div className="tw-mt-2">
        <OrganisationInput
          value={organisationField.value}
          errors={
            !isEmpty(organisationFieldState.error) && {
              organisationNumber: (
                organisationFieldState.error as IOrganisationFieldStateError
              )?.organisationNumber?.message,
              name: (
                organisationFieldState.error as IOrganisationFieldStateError
              )?.name?.message,
            }
          }
          touched={
            organisationFieldState.isTouched && {
              organisationNumber: organisationFieldState.isTouched,
              name: organisationFieldState.isTouched,
            }
          }
          setInputValue={(name: string, value: string) => {
            let updatedOrganisation = null;
            const prevValues = getValues('organisation');

            if (name.includes('organisation.name')) {
              updatedOrganisation = { ...prevValues, name: value };
            } else if (name.includes('organisation.organisationNumber')) {
              updatedOrganisation = {
                ...prevValues,
                organisationNumber: value,
              };
            }

            if (updatedOrganisation) {
              organisationField.onChange(updatedOrganisation);
              setOrganisation(updatedOrganisation);
            }
          }}
          isLimitedCompany={isLimitedCompany}
          onBlur={organisationFieldOnBlur}
        />
      </div>
      <div className="tw-mt-5">
        <EmailInput
          label={t('applicationForm.commons.emailLabel', { ns: 'common' })}
          value={emailField.value}
          errors={emailFieldState.error?.message || formErrors.email}
          touched={formErrors.email || emailFieldState.isTouched}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            emailField.onChange(e.target.value);
            setEmail(e.target.value);
            removeFormError('email');
          }}
          onBlur={emailField.onBlur}
        />
      </div>

      {ongoingApplicationExists && (
        <div
          className={classNames('tw-rounded tw-mt-3 tw-mb-5 tw-flex', {
            'tw-p-1': isMobile,
            'tw-py-2 tw-px-1': !isMobile,
          })}
          style={{ border: '1px solid #EBE8EF' }}
          data-test-id={
            TEST_IDS.common.forms.loanApplication.hasOngoingApplication
          }
        >
          <SVG
            src={info}
            style={
              isMobile
                ? { width: '22.5px', marginRight: '10px' }
                : { width: '20px' }
            }
            className="tw-ml-3 tw-h-auto"
          ></SVG>

          <div className={isMobile ? 'tw-ml-1' : 'tw-ml-3'}>
            <Text style={{ lineHeight: '1.5' }} size={isMobile ? 'xs' : 'sm'}>
              {t('applicationForm.common.applicationExists')}
            </Text>

            <Text size={isMobile ? 'xs' : 'sm'} className="tw-font-bold">
              <a href="/login#bankid-container">
                {t('applicationForm.common.loginToApplication')}
              </a>
            </Text>
          </div>
        </div>
      )}

      <div className="tw-mb-4 tw-mt-5">
        <PhoneInput
          label={t('applicationForm.commons.phoneLabel', { ns: 'common' })}
          value={phoneNumberField.value}
          touched={phoneNumberFieldState.isTouched}
          errors={phoneNumberFieldState.error?.message}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setMobilePhoneNumber(e.target?.value);
            phoneNumberField.onChange(e.target?.value);
          }}
          onBlur={phoneNumberField.onBlur}
        />
      </div>
    </>
  );

  const infoText: React.ReactNode = (
    <span>
      {t('applicationForm.common.businessNotFoundContactSupport')}
      {isMobile && <br />}
      {t('applicationForm.common.callUs')}{' '}
      <a
        style={{ whiteSpace: 'nowrap' }}
        className="tw-font-bold tw-text-primary"
        onClick={() =>
          window.open(`tel:${settings.contacts.phone.link}`, '_self')
        }
      >
        {settings.contacts.phone.value}
      </a>
    </span>
  );

  return (
    <LoanApplicationFormLayout
      heading={t('applicationForm.common.startApplicationHeading')}
      subheading={t('applicationForm.common.startApplicationSubheading')}
      innerForm={innerForm}
      hideAdvisorCTABanner={true}
      bottomInfoText={infoText}
      hasTestimonials={hasTestimonials}
      goToNextStep={goToNextStep}
      goToPreviousStep={() => {}}
      disabledNavigationBeforeFormCompletion={
        !(
          (organisationField.value.name ||
            organisationField.value.organisationNumber) &&
          emailField.value &&
          phoneNumberField.value &&
          isEmpty(organisationFieldState.error) &&
          isEmpty(emailFieldState.error) &&
          isEmpty(phoneNumberFieldState.error)
        )
      }
    />
  );
};

export default BasicDetailsForm;
