export const FormatNumber = (value: number) => {
  const formattedValue = new Intl.NumberFormat('sv', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);

  return formattedValue ? formattedValue.replace(/\./g, ' ') : formattedValue; // edge formats with dots instead of spaces
};

export const FormatPhoneNumber = (value: string) => {
  let cleaned = value.replace(/[^\d]/g, '');

  return cleaned;
};
