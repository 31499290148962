import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { parseIncompletePhoneNumber } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  getUtmDataJSONString,
  setApplicationFlowFormErrors,
} from '@krea/common/services/formService';
import { settings } from '@krea/common/settings';
import { useBankIDStore } from '@krea/common/store/bankID/hooks';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { getApplicationPayload } from '@krea/common/store/loan-application-form/utils';
import {
  BANKID_AUTHENTICATE_STORE_KEY,
  USER_STORE_KEY,
} from '@krea/common/store/queryClient';
import { BANKID_FLOW_STATES, HINT_CODE_STATES } from '@krea/common/utils';

import { createApplication } from 'store/loan-application-form/api';

export const useCreateApplication = (onCancel) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const resetBankIDStore = useBankIDStore(
    ({ resetBankIDStore }) => resetBankIDStore,
  );

  const { formErrors, resetFormState } = useLoanApplicationFormStore(
    useShallow((state) => ({
      formErrors: state.formErrors,
      resetFormState: state.resetFormState,
    })),
  );

  const appPayload = getApplicationPayload();

  return useMutation({
    mutationFn: () => createApplication(appPayload),
    onMutate: () => {
      // Clear bankIDStore to stop collect calls ones this is called
      resetBankIDStore();
    },
    onSuccess: async () => {
      // Since we now have created and set a new "current_loan_application" on the user
      // we need to invalidate the user query so that we fetch new data
      // and don't resolve an old "current_loan_application" on the user.
      await queryClient.invalidateQueries([USER_STORE_KEY]);

      resetFormState();
      resetBankIDStore();

      navigate('/');
    },
    onError: (err, _variables) => {
      const hasFormErrors = setApplicationFlowFormErrors(err);

      if (formErrors.email) {
        // Here we wana go back to show the email field to show this error
        queryClient.resetQueries({ queryKey: [BANKID_AUTHENTICATE_STORE_KEY] });
      } else {
        // Otherwise we want to show the error in the bankID flow
        queryClient.setQueryData([BANKID_AUTHENTICATE_STORE_KEY], {
          status: BANKID_FLOW_STATES.FAILED,
          hintCode: HINT_CODE_STATES.ERROR,
          errorCode: err?.messages?.[0]?.code,
        });
      }

      if (hasFormErrors) {
        onCancel();
      } else {
        // If we dont catch any custom errors we want to log the error
        console.error(
          `couldn't create application, err: ${JSON.stringify(err)}. Payload: ${JSON.stringify(_variables)}`,
        );
        Sentry.captureException(err, { extra: { payload: _variables } });
      }
    },
  });
};
