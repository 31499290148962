import {
  Control,
  FieldValues,
  UseFormGetValues,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import { ApplicationTypeSelectorButton } from '@krea/common/shared-components/application-type-button';
import { Error } from '@krea/common/shared-components/fields/error';
import TextInput from '@krea/common/shared-components/fields/input';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';

import { FormatNumber, TEST_IDS, numberFromFormat } from '../../../utils';

import { settings } from '@krea/common/settings';
import { useFormatCurrency } from '@krea/common/hooks';
import { chunkArray } from '@krea/common/shared-components/application-form/utils';

export const dueDateOptions = [14, 21, 30, 45, 60, 90, 120];

const FactoringBaseForm = ({
  control,
  goToPreviousStep,
  goToNextStep,
}: {
  control: Control<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  currentStep: string;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}) => {
  const { setUnpaidInvoices, unpaidInvoices } = useLoanApplicationFormStore(
    (state) => ({
      unpaidInvoices: state.unpaidInvoices,
      setUnpaidInvoices: state.setUnpaidInvoices,
    }),
  );
  const isMobile = useMobile();
  const { t } = useTranslation();
  const buttonStylingOnMobile = 'tw-w-full tw-my-2 tw-rounded tw-text-primary';

  const estimatedInvoiceAmountCtrl = useController({
    name: 'unpaidInvoices.estimatedInvoiceAmount',
    control,
  });

  const onAmountChange = (value: string) => {
    const updatedValue = numberFromFormat(value);

    estimatedInvoiceAmountCtrl.field.onChange(updatedValue);
    setUnpaidInvoices({
      ...unpaidInvoices,
      estimatedInvoiceAmount: updatedValue,
    });
  };

  const numberOfInvoicesCtrl = useController({
    name: 'unpaidInvoices.numberOfInvoices',
    control,
  });

  const onNumberOfInvoicesChange = (value: string) => {
    numberOfInvoicesCtrl.field.onChange(value);
    setUnpaidInvoices({ ...unpaidInvoices, numberOfInvoices: value });
  };

  const averagePaymentTimeDaysCtrl = useController({
    name: 'unpaidInvoices.averagePaymentTimeDays',
    control,
  });

  const onAveragePaymentTimeDaysChange = (value: number) => {
    averagePaymentTimeDaysCtrl.field.onChange(value);
    setUnpaidInvoices({ ...unpaidInvoices, averagePaymentTimeDays: value });
  };
  const currency = settings.currency;
  const [, currencyValue] = useFormatCurrency({ currency });

  const innerForm = (
    <div>
      <p className="tw-mt-6 tw-mb-3 tw-font-bold tw-text-[18px]">
        {t('applicationForm.common.totalInvoiceValue')}
      </p>
      <TextInput
        value={FormatNumber(estimatedInvoiceAmountCtrl.field.value)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onAmountChange(e.target.value)
        }
        size="lg"
        id="estimatedInvoiceAmount"
        type="text"
        name="unpaidInvoices.estimatedInvoiceAmount"
        error={estimatedInvoiceAmountCtrl.fieldState.error?.message}
        placeholder={`${t(
          'applicationForm.common.valuePlaceholder',
        )} ${currencyValue}`}
        autoComplete="on"
        className="tw-mb-6"
        inputMode="numeric"
        data-test-id={TEST_IDS.common.forms.factoringForm.invoiceAmountInput}
      />
      <p className="tw-mb-3 tw-font-bold tw-text-[18px]">
        {t('applicationForm.common.numberOfInvoices')}
      </p>
      <TextInput
        value={numberOfInvoicesCtrl.field.value || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onNumberOfInvoicesChange(e.target.value)
        }
        size="lg"
        id="numberOfInvoices"
        type="text"
        name="unpaidInvoices.numberOfInvoices"
        error={numberOfInvoicesCtrl.fieldState.error?.message}
        placeholder={`${t('applicationForm.common.invoicesAmountPlaceholder')}`}
        autoComplete="on"
        className="tw-mb-6"
        inputMode="numeric"
        data-test-id={TEST_IDS.common.forms.factoringForm.invoiceNumberInput}
      />
      <p className="tw-mb-0 tw-font-bold tw-text-[18px]">
        {t('applicationForm.common.averagePaymentTime')}
      </p>
      {!isMobile && (
        <div>
          {chunkArray(dueDateOptions, 3).map((group, groupIndex) => (
            <div
              key={groupIndex}
              className={`tw-flex tw-justify-evenly tw-h-[75px] ${
                groupIndex === chunkArray(dueDateOptions, 3).length - 1
                  ? 'tw-mb-7'
                  : ''
              }`}
            >
              {group.map((item) => (
                <ApplicationTypeSelectorButton
                  key={item}
                  text={t('iaq.unpaidInvoices.dueDateOptions', { days: item })}
                  id={`${item}`}
                  setInputValue={(averagePaymentTimeDays: string) =>
                    onAveragePaymentTimeDaysChange(
                      parseInt(averagePaymentTimeDays),
                    )
                  }
                  value={averagePaymentTimeDaysCtrl.field.value}
                  data-test-id={TEST_IDS.common.forms.factoringForm.invoiceDueDate(
                    item,
                  )}
                />
              ))}
            </div>
          ))}
        </div>
      )}

      {isMobile && (
        <div className="tw-pt-4 tw-pb-6">
          {dueDateOptions.map((item) => (
            <ApplicationTypeSelectorButton
              key={item}
              text={t('iaq.unpaidInvoices.dueDateOptions', { days: item })}
              id={`${item}`}
              setInputValue={(averagePaymentTimeDays: string) =>
                onAveragePaymentTimeDaysChange(parseInt(averagePaymentTimeDays))
              }
              value={averagePaymentTimeDaysCtrl.field.value}
              className={buttonStylingOnMobile}
              data-test-id={TEST_IDS.common.forms.factoringForm.invoiceDueDate(
                item,
              )}
            />
          ))}
        </div>
      )}

      {averagePaymentTimeDaysCtrl.fieldState.error?.message && (
        <Error className="tw-ml-2">
          {averagePaymentTimeDaysCtrl.fieldState.error?.message}
        </Error>
      )}
    </div>
  );

  return (
    <>
      <LoanApplicationFormLayout
        heading={t('applicationForm.common.invoiceFinanceHeading')}
        subheading={t('applicationForm.common.invoiceFinanceSubheading')}
        innerForm={innerForm}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        disabledNavigationBeforeFormCompletion={
          !(
            unpaidInvoices.estimatedInvoiceAmount &&
            unpaidInvoices.numberOfInvoices &&
            unpaidInvoices.averagePaymentTimeDays
          )
        }
      />
    </>
  );
};

export default FactoringBaseForm;
