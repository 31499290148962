import { isObject } from 'lodash';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import {
  Control,
  FieldValues,
  UseFormTrigger,
  UseFormGetValues,
  UseFormSetValue,
  UseFormSetError,
} from 'react-hook-form';

import { useMobile } from '@krea/common/hooks';
import BasicDetailsForm from '@krea/common/shared-components/application-form/steps/BasicDetailsForm';
import FactoringBaseForm from '@krea/common/shared-components/application-form/steps/FactoringBaseForm';
import FactoringExtendedForm from '@krea/common/shared-components/application-form/steps/FactoringExtendedForm';
import FinancingAmountForm from '@krea/common/shared-components/application-form/steps/FinancingAmountForm';
import LeasingBaseForm from '@krea/common/shared-components/application-form/steps/LeasingBaseForm';
import PrimaryPurposeForm from '@krea/common/shared-components/application-form/steps/PrimaryPurposeForm';
import RealEstateExtendedForm from '@krea/common/shared-components/application-form/steps/RealEstateExtendedForm';
import RealEstateFinancingAmountForm from '@krea/common/shared-components/application-form/steps/RealEstateFinancingAmountForm';
import RealEstateForm from '@krea/common/shared-components/application-form/steps/RealEstateForm';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import AppFormPromotion from '@krea/common/shared-components/loan-application-form/AppFormPromotion';

import { useCreateLead } from '../../store/leads/hooks/mutations/useCreateLead';
import { useUpdateLead } from '../../store/leads/hooks/mutations/useUpdateLead';

interface IApplicationFormProps {
  control: Control<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
}

export const FORM_STEP = {
  BASIC_DETAILS: 'BASIC_DETAILS',
  PRIMARY_PURPOSE: 'PRIMARY_PURPOSE',
  REAL_ESTATE_BASE: 'REAL_ESTATE_BASE',
  REAL_ESTATE_EXTENDED: 'REAL_ESTATE_EXTENDED',
  REAL_ESTATE_FINANCING_AMOUNT: 'REAL_ESTATE_FINANCING_AMOUNT',
  FACTORING_BASE: 'FACTORING_BASE',
  FACTORING_EXTENDED: 'FACTORING_EXTENDED',
  LEASING_BASE: 'LEASING_BASE',
  FINANCING_AMOUNT: 'FINANCING_AMOUNT',
  COMPLETED: 'COMPLETED',
};

export const FLOW_STEP_FIELDS = {
  [FORM_STEP.BASIC_DETAILS]: ['organisation', 'email', 'mobilePhoneNumber'],
  [FORM_STEP.PRIMARY_PURPOSE]: ['reason'],
  [FORM_STEP.REAL_ESTATE_BASE]: ['realEstateReason'],
  [FORM_STEP.REAL_ESTATE_EXTENDED]: ['propertyUnitDesignation'],
  [FORM_STEP.REAL_ESTATE_FINANCING_AMOUNT]: ['requestedLoanAmount'],
  [FORM_STEP.FACTORING_BASE]: ['unpaidInvoices'],
  [FORM_STEP.FACTORING_EXTENDED]: ['unpaidInvoicesDescription'],
  [FORM_STEP.LEASING_BASE]: ['leasing'],
  [FORM_STEP.FINANCING_AMOUNT]: ['requestedLoanAmount'],
};

export const useApplicationFormNavigation = () => {
  const { reason, currentStep, currentSteps, setCurrentStep } =
    useLoanApplicationFormStore();

  // Always start at the first step if no currentStep is stored
  useEffect(() => {
    if (!currentStep) {
      setCurrentStep(FORM_STEP.BASIC_DETAILS);
    }
  }, [currentStep, setCurrentStep]);

  const getNextStep = () => {
    switch (currentStep) {
      case FORM_STEP.BASIC_DETAILS:
        return FORM_STEP.PRIMARY_PURPOSE;

      case FORM_STEP.PRIMARY_PURPOSE:
        if (reason === 'real_estate_financing') {
          return FORM_STEP.REAL_ESTATE_BASE;
        } else if (reason === 'invoice_financing') {
          return FORM_STEP.FACTORING_BASE;
        } else if (reason === 'object_financing') {
          return FORM_STEP.LEASING_BASE;
        } else {
          return FORM_STEP.FINANCING_AMOUNT;
        }

      case FORM_STEP.REAL_ESTATE_BASE:
        return FORM_STEP.REAL_ESTATE_EXTENDED;

      case FORM_STEP.REAL_ESTATE_EXTENDED:
        return FORM_STEP.REAL_ESTATE_FINANCING_AMOUNT;

      case FORM_STEP.FACTORING_BASE:
        return FORM_STEP.FACTORING_EXTENDED;

      case FORM_STEP.FINANCING_AMOUNT:
      case FORM_STEP.REAL_ESTATE_FINANCING_AMOUNT:
      case FORM_STEP.FACTORING_EXTENDED:
      case FORM_STEP.LEASING_BASE:
        return FORM_STEP.COMPLETED;
      default:
        return currentStep;
    }
  };

  const getPreviousStep = () => {
    let previousStep = currentSteps[currentSteps.length - 2];

    if (!previousStep) {
      previousStep = FORM_STEP.BASIC_DETAILS;
    }

    return previousStep;
  };

  return { currentStep, getPreviousStep, getNextStep, setCurrentStep };
};

export const ApplicationForm = ({
  applicationFormProps,
  onComplete,
  shouldCreateLead = true,
}: {
  applicationFormProps: IApplicationFormProps;
  onComplete: () => void;
  shouldCreateLead: boolean;
}) => {
  const { mutate: createLead } = useCreateLead();
  const { mutate: updateLead } = useUpdateLead();

  const { currentStep, getPreviousStep, getNextStep, setCurrentStep } =
    useApplicationFormNavigation();
  const {
    organisation,
    email,
    mobilePhoneNumber,
    currentSteps,
    formErrors,
    preApplicationLead,
  } = useLoanApplicationFormStore(
    useShallow((state) => ({
      organisation: state.organisation,
      email: state.email,
      mobilePhoneNumber: state.mobilePhoneNumber,
      currentSteps: state.currentSteps,
      formErrors: state.formErrors,
      preApplicationLead: state.preApplicationLead,
    })),
  );

  const { control, trigger, getValues, setValue, setError } =
    applicationFormProps;

  const handleLeadCreation = async (nextStep: string) => {
    const valid = await trigger(FLOW_STEP_FIELDS[FORM_STEP.BASIC_DETAILS]);

    if (
      shouldCreateLead &&
      nextStep !== FORM_STEP.BASIC_DETAILS &&
      valid &&
      organisation.organisationNumber &&
      organisation.name &&
      organisation.organisationNumber !== organisation.name &&
      email &&
      mobilePhoneNumber
    ) {
      // === Extra note on the organisation number/name check ===
      // We check if organisation.organisationNumber !== organisation.name
      // to avoid creating a lead with the same name as the org number
      // which happens when we don't get a hit in the search result from the API.
      // If we don't get a hit
      // - we populate the name with the org number.
      // - we assume it's a private person or some "non-organisation" entity that tries to make
      //   a application, and that is not a relevant lead.

      if (preApplicationLead?.id) {
        updateLead(preApplicationLead.id);
      } else {
        createLead();
      }
    }
  };

  const goToNextStep = async (): Promise<void> => {
    const nextStep = getNextStep();

    let invalidSteps = [];
    let allCurrentStepsFields: Array<string> = [];

    for (const step of currentSteps) {
      const fieldsInStep = FLOW_STEP_FIELDS[step];
      allCurrentStepsFields = allCurrentStepsFields.concat(fieldsInStep);

      // Trigger/touch all fields in step
      for (const field of fieldsInStep) {
        const fieldValue: Record<string, string> = getValues(field);

        if (isObject(fieldValue)) {
          Object.keys(fieldValue).forEach((key) => {
            const nestedKey = `${field}.${key}`;
            setValue(nestedKey, fieldValue[key], {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
          });
        } else {
          setValue(field, fieldValue, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }
      }

      const valid = await trigger(allCurrentStepsFields);

      if (!valid) {
        invalidSteps.push(step);
      }
    }

    if (invalidSteps.length > 0) {
      setCurrentStep(invalidSteps[0]);
    } else {
      handleLeadCreation(nextStep);

      if (nextStep === FORM_STEP.COMPLETED) {
        onComplete();
      }

      setCurrentStep(nextStep);
    }
  };

  const goToPreviousStep = () => {
    const previousStep = getPreviousStep();

    setCurrentStep(previousStep);
  };

  const isMobile = useMobile();

  useEffect(() => {
    if (formErrors.email) {
      setError('email', { message: formErrors.email });
      setCurrentStep(FORM_STEP.BASIC_DETAILS);
    }
  }, [formErrors, setError, setCurrentStep]);

  if (currentStep === FORM_STEP.BASIC_DETAILS) {
    return (
      <div className="tw-flex">
        <div style={isMobile ? { width: '100%' } : { width: '65%' }}>
          <div className="tw-mx-auto" style={!isMobile ? { width: '80%' } : {}}>
            <BasicDetailsForm
              control={control}
              getValues={getValues}
              currentStep={currentStep}
              goToPreviousStep={goToPreviousStep}
              goToNextStep={goToNextStep}
              hasTestimonials={true}
              setValue={setValue}
            />
          </div>
        </div>

        {!isMobile && (
          <div
            className="tw-mx-2"
            style={{
              minWidth: '400px',
              maxWidth: '500px',
              minHeight: '855px',
              maxHeight: '950px',
            }}
          >
            <AppFormPromotion />
          </div>
        )}
      </div>
    );
  } else if (currentStep === FORM_STEP.PRIMARY_PURPOSE) {
    return (
      <PrimaryPurposeForm
        control={control}
        getValues={getValues}
        currentStep={currentStep}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
      />
    );
  } else if (currentStep === FORM_STEP.REAL_ESTATE_BASE) {
    return (
      <RealEstateForm
        control={control}
        getValues={getValues}
        currentStep={currentStep}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
      />
    );
  } else if (currentStep === FORM_STEP.REAL_ESTATE_EXTENDED) {
    return (
      <RealEstateExtendedForm
        control={control}
        getValues={getValues}
        currentStep={currentStep}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
      />
    );
  } else if (currentStep === FORM_STEP.REAL_ESTATE_FINANCING_AMOUNT) {
    return (
      <RealEstateFinancingAmountForm
        control={control}
        getValues={getValues}
        currentStep={currentStep}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
      />
    );
  } else if (currentStep === FORM_STEP.FACTORING_BASE) {
    return (
      <FactoringBaseForm
        control={control}
        getValues={getValues}
        currentStep={currentStep}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
      />
    );
  } else if (currentStep === FORM_STEP.FACTORING_EXTENDED) {
    return (
      <FactoringExtendedForm
        control={control}
        getValues={getValues}
        currentStep={currentStep}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
      />
    );
  } else if (currentStep === FORM_STEP.LEASING_BASE) {
    return (
      <LeasingBaseForm
        control={control}
        getValues={getValues}
        currentStep={currentStep}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
      />
    );
  } else if (currentStep === FORM_STEP.FINANCING_AMOUNT) {
    return (
      <FinancingAmountForm
        control={control}
        getValues={getValues}
        currentStep={currentStep}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
      />
    );
  } else if (currentStep === FORM_STEP.COMPLETED) {
    return null;
  }
};
