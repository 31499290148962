import { useMutation } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';

import { trackLoanApplicationRateForecastExecuted } from 'store/current-loan-application/api';

export const useTrackLoanApplicationRateForecastExecuted = () => {
  return useMutation({
    mutationFn: ({ loanApplicationID }) =>
      trackLoanApplicationRateForecastExecuted({ loanApplicationID }),
    onSuccess: (data) => {
      const conversionData =
        data?.trackLoanApplicationRateForecastExecuted?.tracking;

      TagManager.dataLayer({
        dataLayer: {
          event: 'LoanApplicationRateForecastExecuted',
          loanApplicationRateForecastExecutedConversion: conversionData,
        },
      });
    },
  });
};
