import axios from 'axios';

import { settings } from '@krea/common/settings';
import { APPLICATION_NAME } from '@krea/common/utils';

export const hasOngoingApplication = async (
  countryCode,
  email,
  organisationNumber,
) => {
  const response = await axios.post('/api/graphql', {
    query: `
      query HasOngoingApplication($input: HasOngoingApplicationInput!) {
        hasOngoingApplication(input: $input) {
          hasHit
        }
      }
  `,
    variables: {
      input: {
        countryCode,
        email,
        organisationNumber,
      },
    },
  });

  return response.data.data?.hasOngoingApplication?.hasHit;
};

export const submitApplicationAndDeleteDuplicates = async (
  loanApplicationID,
) => {
  const isCustomerWeb = settings.appName === APPLICATION_NAME.CUSTOMER_WEB;
  const URL = isCustomerWeb
    ? '/api/v1/current-loan-application/submit-and-delete-previous-applications'
    : `partner/api/v1/loan-applications/${loanApplicationID}/submit-and-delete-previous-applications`;

  return axios.post(URL);
};

export const submitApplication = async (loanApplicationID) => {
  const isCustomerWeb = settings.appName === APPLICATION_NAME.CUSTOMER_WEB;
  const URL = isCustomerWeb
    ? '/api/v1/current-loan-application/submit'
    : `partner/api/v1/loan-applications/${loanApplicationID}/submit`;

  return axios.post(URL);
};

export const changeCustomerPreferredRate = async (
  loanApplicationID,
  customer_preferred_rate,
) => {
  const isCustomerWeb = settings.appName === APPLICATION_NAME.CUSTOMER_WEB;
  const URL = isCustomerWeb
    ? '/api/v1/current-loan-application/change-customer-preferred-rate'
    : `partner/api/v1/loan-applications/${loanApplicationID}/change-customer-desired-rate`;

  return axios.post(URL, { customer_preferred_rate });
};
