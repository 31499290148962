import React, { useState } from 'react';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLeasingObjectsList, useMobile } from '@krea/common/hooks';
import { ApplicationTypeSelectorButton } from '@krea/common/shared-components/application-type-button';
import { Error } from '@krea/common/shared-components/fields/error';
import TextInput from '@krea/common/shared-components/fields/input';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import { FormatNumber, TEST_IDS, numberFromFormat } from '@krea/common/utils';

import { settings } from '@krea/common/settings';
import { useFormatCurrency } from '@krea/common/hooks';

import { useLoanApplicationFormStore } from '../../../store/loan-application-form/hooks';
import { chunkArray } from '../utils';

const LeasingBaseForm = ({
  control,
  goToPreviousStep,
  goToNextStep,
}: {
  control: Control<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  currentStep: string;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}) => {
  const isMobile = useMobile();
  const buttonStylingOnMobile = 'w-100 my-2 rounded text-primary';
  const buttonStylingOnDesktop =
    'w-33 rounded mx-2 justify-content-center text-primary mt-2';
  const { t } = useTranslation();
  const leasingObjectsList = useLeasingObjectsList();

  const { setLeasing, leasing } = useLoanApplicationFormStore((state) => ({
    leasing: state.leasing,
    setLeasing: state.setLeasing,
  }));

  const amountController = useController({
    name: 'leasing.leasing_amount',
    control,
  });

  const onAmountChange = (value: string) => {
    const updatedValue = numberFromFormat(value);

    amountController.field.onChange(updatedValue);
    setLeasing({ ...leasing, leasing_amount: updatedValue });
  };

  const objectTypeController = useController({
    name: 'leasing.leasing_object_type',
    control,
  });

  const onObjectTypeChange = (value: string) => {
    objectTypeController.field.onChange(value);
    setLeasing({ ...leasing, leasing_object_type: value });
  };
  const currency = settings.currency;
  const [, currencyValue] = useFormatCurrency({ currency });

  const chunkSize = 3;
  const leasingChunks = chunkArray(leasingObjectsList, chunkSize);

  const innerForm = (
    <div>
      <p className="tw-mt-6 tw-mb-3 tw-font-bold" style={{ fontSize: '18px' }}>
        {t('applicationForm.common.price')}
      </p>
      <TextInput
        value={FormatNumber(amountController.field.value)}
        error={amountController.fieldState.error?.message}
        onChange={(e) => onAmountChange(e.target.value)}
        size="lg"
        id="price"
        type="text"
        name="price"
        placeholder={`${t(
          'applicationForm.common.valuePlaceholder',
        )} ${currencyValue}`}
        className="tw-mx-0 tw-mb-6"
        autoComplete="off"
        inputMode="numeric"
        data-test-id={TEST_IDS.common.forms.leasingForm.amountInput}
      />
      <p className="tw-mb-3 tw-font-bold" style={{ fontSize: '18px' }}>
        {t('applicationForm.common.objectType')}
      </p>
      {objectTypeController.fieldState.error?.message && (
        <Error>{objectTypeController.fieldState.error?.message}</Error>
      )}
      {!isMobile && (
        <div className="tw-mx-0">
          {' '}
          {leasingChunks.map((chunk, index) => (
            <div
              key={index}
              className={`tw-flex ${index === leasingChunks.length - 1 ? 'tw-mb-6' : ''}`}
              style={{ justifyContent: 'space-evenly', height: '75px' }}
            >
              {chunk.map((item, index) => (
                <ApplicationTypeSelectorButton
                  key={index}
                  value={objectTypeController.field.value || ''}
                  setInputValue={(val: string) => onObjectTypeChange(val)}
                  text={item.display_text}
                  id={item.value}
                  className={buttonStylingOnDesktop}
                  data-test-id={TEST_IDS.common.forms.leasingForm.leasingObjectValue(
                    item.value,
                  )}
                />
              ))}
            </div>
          ))}
        </div>
      )}
      {isMobile && (
        <div className="tw-mx-0 tw-pb-8">
          {leasingObjectsList?.map((item, index) => (
            <ApplicationTypeSelectorButton
              key={index}
              value={objectTypeController.field.value || ''}
              setInputValue={(val) => onObjectTypeChange(val)}
              text={`${item.display_text}`}
              id={`${item.value}`}
              className={buttonStylingOnMobile}
              data-test-id={TEST_IDS.common.forms.leasingForm.leasingObjectValue(
                'item.value',
              )}
            />
          ))}
        </div>
      )}
    </div>
  );

  return (
    <>
      <LoanApplicationFormLayout
        heading={t('applicationForm.common.buyOrLeaseHeading')}
        subheading={t('applicationForm.common.buyOrLeaseSubheading')}
        innerForm={innerForm}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        disabledNavigationBeforeFormCompletion={
          !(leasing.leasing_amount && leasing.leasing_object_type)
        }
      />
    </>
  );
};

export default LeasingBaseForm;
