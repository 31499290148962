import { isArray, isEmpty } from 'lodash';
import { getI18n } from 'react-i18next';

import { getUtmParamsFromCookie } from '@krea/common/services/queryParamsService';
import { settings } from '@krea/common/settings';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import {
  APPLICATION_NAME,
  COUNTRY_CODE,
  PRODUCT_TYPE,
} from '@krea/common/utils';

const cleanSSNValueForMultipartData = (ssn) =>
  ssn?.replace(/-/g, '')?.trim() || '';
const cleanValue = (it) => it?.trim() || null;

const requestedLoanAmountValues = [
  0, 20000, 35000, 50000, 75000, 100000, 125000, 150000, 200000, 250000, 300000,
  350000, 400000, 450000, 500000, 600000, 700000, 800000, 900000, 1000000,
  1250000, 1500000, 1750000, 2000000, 2500000, 3000000, 4000000, 5000000,
  6000000, 7000000, 8000000, 9000000, 10000000, 15000000, 20000000, 25000000,
  30000000,
];

const requestedLoanAmountValuesDefault = [
  0, 20000, 35000, 50000, 75000, 100000, 125000, 150000, 200000, 250000, 300000,
  350000, 400000, 450000, 500000, 600000, 700000, 800000, 900000, 1000000,
  1250000, 1500000, 1750000, 2000000, 2500000, 3000000,
];

const requestedLoanAmountValuesLarge = [
  3 * 1000 * 1000,
  4 * 1000 * 1000,
  5 * 1000 * 1000,
  6 * 1000 * 1000,
  7 * 1000 * 1000,
  8 * 1000 * 1000,
  9 * 1000 * 1000,
  10 * 1000 * 1000,
  15 * 1000 * 1000,
  20 * 1000 * 1000,
  25 * 1000 * 1000,
  30 * 1000 * 1000,
];

const requestedRealEstateLoanAmountValues = [
  0, 100000, 200000, 500000, 1000000, 1500000, 2000000, 2500000, 3000000,
  3500000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000,
  15000000, 20000000, 25000000, 30000000, 35000000, 40000000, 50000000,
  60000000, 75000000, 100000000,
];

const requestedRealEstateLoanAmountValuesDefault = [
  0, 100000, 200000, 500000, 1000000, 1500000, 2000000, 2500000, 3000000,
  3500000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000,
];

const requestedRealEstateLoanAmountValuesLarge = [
  10 * 1000 * 1000,
  15 * 1000 * 1000,
  20 * 1000 * 1000,
  25 * 1000 * 1000,
  30 * 1000 * 1000,
  35 * 1000 * 1000,
  40 * 1000 * 1000,
  50 * 1000 * 1000,
  60 * 1000 * 1000,
  75 * 1000 * 1000,
  100 * 1000 * 1000,
];

const setApplicationFlowFormErrors = (err) => {
  const { t } = getI18n();
  let errorCode = parseInt(err?.data?.errors?.[0]?.code);

  if (!errorCode) {
    errorCode = parseInt(err?.messages?.[0]?.code);
  }
  const { formErrors, setFormErrors } = useLoanApplicationFormStore.getState();
  let hasFormErrors = false;
  if (errorCode) {
    setFormErrors({
      ...formErrors,
      errorCode: errorCode,
    });

    hasFormErrors = true;
  }

  return hasFormErrors;
};

export const getUtmDataJSONString = (utm_campaign) => {
  if (settings.appName === APPLICATION_NAME.CUSTOMER_WEB) {
    // If customer web, get utm params from cookie (the actual users utm params)
    return getUtmParamsFromCookie();
  } else if (settings.appName === APPLICATION_NAME.PARTNER_WEB) {
    // If partner web, set utm campaign from the partner choice, if they selected one.
    return utm_campaign ? JSON.stringify({ utm_campaign }) : '';
  }
};

export {
  requestedLoanAmountValues,
  requestedLoanAmountValuesDefault,
  requestedLoanAmountValuesLarge,
  requestedRealEstateLoanAmountValues,
  requestedRealEstateLoanAmountValuesDefault,
  requestedRealEstateLoanAmountValuesLarge,
  setApplicationFlowFormErrors,
};
