import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState, useEffect, RefObject } from 'react';
import SVG from 'react-inlinesvg';

import ArrowDownSvg from '@krea/common/images/svg/arrow-down-colorless.svg';

const motionItem = {
  hidden: { zIndex: 0 },
  visible: {
    zIndex: 1,
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
};

const motionIcon = {
  hidden: {
    transform: 'rotateZ(0deg)',
    color: '#39008c',
    fill: '#39008c',
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
  visible: {
    transform: 'rotateZ(180deg)',
    color: '#39008c',
    fill: '#39008c',
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
};

const AccordionItemIcon = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <motion.div
      className={classnames('tw-relative tw-shrink-0 tw-mt-1 tw-w-4 tw-h-4')}
      variants={motionIcon}
      initial="hidden"
      animate={isOpen ? 'visible' : 'hidden'}
    >
      <SVG
        src={ArrowDownSvg}
        className="tw-absolute tw-w-full tw-h-auto tw-min-w-[16px] tw-min-h-[16px]"
      />
    </motion.div>
  );
};

export const AccordionItem = ({
  title,
  children,
  id,
  className,
  titleStyling,
}: {
  title: string;
  children: React.ReactNode;
  id?: string;
  className?: string;
  titleStyling?: string;
}) => {
  const [expanded, setExpanded] = useState(false);
  const refContainer = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (expanded) refContainer?.current?.focus();
  }, [expanded]);

  return (
    <motion.section
      className={classnames(
        'tw-position-relative tw-border tw-overflow-hidden tw-cursor-pointer tw-rounded',
        className,
      )}
      variants={motionItem}
      initial="hidden"
      animate={expanded ? 'visible' : 'hidden'}
      id={`accordion${id}id`}
      aria-expanded={expanded ? 'true' : 'false'}
      aria-disabled={expanded ? false : undefined}
      tabIndex={0}
    >
      <div
        aria-hidden="true"
        className="tw-flex tw-items-center tw-justify-between tw-m-0 tw-px-4 tw-py-4  md:tw-px-6 md:tw-py-4"
        role="button"
        aria-controls={`sect-${id}`}
        onClick={() => setExpanded(!expanded)}
      >
        <p
          className={classnames(
            {
              ['tw-pb-0 tw-m-0 tw-pr-4 tw-text-xl tw-font-semibold']:
                !titleStyling,
            },
            titleStyling,
          )}
        >
          {title}
        </p>

        <AccordionItemIcon isOpen={expanded} />
      </div>

      <AnimatePresence>
        <motion.div
          ref={refContainer as RefObject<HTMLDivElement>}
          id={`sect-${id}`}
          role="region"
          aria-labelledby={`accordion${id}id`}
          tabIndex={-1}
          className={classnames('overflow-hidden')}
          initial={false}
          animate={expanded ? 'open' : 'collapsed'}
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.6, ease: 'easeOut' }}
        >
          <motion.div
            className="px-4 pt-3 pb-3 px-md-6 pt-md-3 pb-md-7"
            animate={expanded ? 'open' : 'collapsed'}
            variants={{
              open: { visibility: 'visible' },
              collapsed: {
                visibility: 'hidden',
                transition: {
                  delay: 0.6,
                },
              },
            }}
          >
            {children}
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </motion.section>
  );
};
