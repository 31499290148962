import { parseIncompletePhoneNumber } from 'libphonenumber-js';
import { useShallow } from 'zustand/react/shallow';

import { getUtmDataJSONString } from '../../services/formService';
import { settings } from '../../settings';
import { APPLICATION_NAME } from '../../utils';

import { useLoanApplicationFormStore } from './hooks';

export const getApplicationPayload = () => {
  const formData = useLoanApplicationFormStore();

  const appPayload = {
    countryCode: settings.countryCode,
    requestedLoanAmount: formData.requestedLoanAmount,
    reason: formData.reason,
    customerCompany: {
      organisationNumber: formData.organisation.organisationNumber,
      name: formData.organisation.name,
    },
    contactPerson: {
      email: formData.email,
      mobilePhoneNumber: parseIncompletePhoneNumber(formData.mobilePhoneNumber),
    },
  };

  // Add payload specific to the reason/product flow the user took

  if (
    formData.reason === 'real_estate_financing' &&
    formData.realEstateReason
  ) {
    appPayload.reason = formData.realEstateReason;
    appPayload.requestedLoanAmount = formData.realEstateRequestedLoanAmount;
    appPayload.realEstate = {
      propertyUnitDesignation: formData.propertyUnitDesignation,
    };
  }

  if (formData.reason === 'invoice_financing') {
    const unpaidInvoices = formData.unpaidInvoices;

    appPayload.factoring = {
      estimatedInvoiceAmount: unpaidInvoices.estimatedInvoiceAmount,
      averagePaymentTimeDays: unpaidInvoices.averagePaymentTimeDays,
      numberOfInvoices: unpaidInvoices.numberOfInvoices,
      invoicesDescription: formData.unpaidInvoicesDescription,
    };

    appPayload.requestedLoanAmount =
      appPayload.factoring.estimatedInvoiceAmount;
  }

  if (formData.reason === 'object_financing') {
    appPayload.leasing = {
      leasingAmount: formData.leasing.leasing_amount,
      productType: formData.leasing.leasing_object_type,
    };

    appPayload.requestedLoanAmount = appPayload.leasing.leasingAmount;
  }

  // Add utm data to payload
  try {
    const utm_data_json_string = getUtmDataJSONString(formData.utmCampaign);
    appPayload.utmData = JSON.parse(utm_data_json_string);
  } catch (e) {}

  // == Only for partner initiated applications ==

  // Add ssn to payload if partner web and ssn is populated (Over the phone BankID)
  if (settings.appName === APPLICATION_NAME.PARTNER_WEB) {
    if (formData.ssn) {
      appPayload.contactPerson.ssn = formData.ssn;
    }

    // Add remoteIdentificationType to payload
    appPayload.remoteIdentificationType = formData.remoteIdentification?.type;

    // Add isCustomerApplicationFlowAllowed to payload
    // Used if a partner/admin wants to en/dis-able the customer from ending up inside the application flow.
    appPayload.isCustomerApplicationFlowAllowed =
      formData.remoteIdentification?.isCustomerApplicationFlowAllowed;
  }

  return appPayload;
};
