import loadable from '@loadable/component';
import { isEmpty } from 'lodash';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { storeUtmParamsInCookie } from '@krea/common/services/queryParamsService';
import { settings } from '@krea/common/settings';
import LayoutOneColumn from '@krea/common/shared-components/app/one-column';
import Button from '@krea/common/shared-components/button';
import { Dialog } from '@krea/common/shared-components/dialog';
import Preloader from '@krea/common/shared-components/preloader';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';

import ApplicationSelector from 'components/application-selector';
import HeaderSteps from 'components/header/HeaderSteps';
import Layout from 'components/layout';
import { ApplicationFormPage } from 'pages/application-form-page';
import { prefetchLoanApplications } from 'store/loan-applications/hooks/queries';

import ScrollToTop from './ScrollToTop';
import DirectApp from './krea-direct/App';
import PrivateRoute from './krea-direct/private-route';
import { CurrentApplicationPage } from './pages/current-application';
import SentryBoundary from './sentry';
import { PreliminaryBidFormPage } from './components/preliminary-bid-form';
import { PreliminaryBidResultPage } from './components/preliminary-bid-form/preliminaryBidResultPage';
import { DelegatedInformation } from './pages/delegated-information';
const LoginPage = loadable(
  () => import('@krea/common/shared-components/login'),
);
const NotFound = loadable(() => import('./pages/not-found'));

const IdentifyCustomer = loadable(() => import('./pages/identify-customer'));

const CustomerWeb = () => {
  const { t } = useTranslation();
  const { data: user = {}, isLoading, isFetching } = useGetUserData();

  const { appTitle } = settings;

  useEffect(() => {
    if (!isEmpty(user)) {
      prefetchLoanApplications();
    }
  }, [user]);

  useEffect(() => {
    storeUtmParamsInCookie();
  }, []);

  if (isLoading && isFetching) {
    return (
      <Preloader
        className="text-primary bg-white position-fixed w-100 h-100"
        size="xl"
      />
    );
  }

  return (
    <SentryBoundary contactPersonId={user.id}>
      <Layout
        MiddleNavbarComponent={HeaderSteps}
        applicationSelector={<ApplicationSelector />}
      >
        <Suspense fallback={null}>
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route index element={<Navigate replace={true} to="direct" />} />

              <Route
                path="/current-application/:loanApplicationID"
                element={
                  <PrivateRoute>
                    <CurrentApplicationPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="application"
                element={
                  <>
                    <Helmet
                      title={`${appTitle} - ${t('commons.app.title.application')}`}
                    />
                    <ApplicationFormPage />
                  </>
                }
              />

              <Route
                path="amount-estimator"
                element={
                  <>
                    <Helmet
                      title={`${appTitle} - ${t('commons.app.title.preliminaryBidForm')}`}
                    />
                    <PreliminaryBidFormPage />
                  </>
                }
              />

              <Route
                path="amount-estimator/result"
                element={
                  <>
                    <Helmet
                      title={`${appTitle} - ${t('commons.app.title.preliminaryBidForm')}`}
                    />
                    <PreliminaryBidResultPage />
                  </>
                }
              />

              <Route
                path="preliminary-bid-form"
                element={<Navigate replace={true} to={'/amount-estimator'} />}
              />

              <Route
                path="loan-application-form"
                element={<Navigate replace={true} to="/application" />}
              />
              <Route
                path="factoring-application-form"
                element={<Navigate replace={true} to="/application" />}
              />
              <Route
                path="real-estate-application-form"
                element={<Navigate replace={true} to="/application" />}
              />
              <Route
                path="leasing-application-form"
                element={<Navigate replace={true} to="/application" />}
              />

              <Route
                path="improve-application-questions"
                element={
                  <Navigate
                    replace={true}
                    to="/direct/improve-application-questions"
                  />
                }
              />

              <Route
                path="dashboard"
                element={<Navigate replace to="/direct/dashboard" />}
              />

              <Route
                path="selected-lender-response"
                element={
                  <Navigate
                    replace={true}
                    to="/direct/selected-lender-response"
                  />
                }
              />

              <Route
                path="delegated-information"
                element={
                  <>
                    <Helmet title={`${appTitle} - Delegated Information`} />
                    <DelegatedInformation />
                  </>
                }
              />

              <Route
                path="identify"
                element={
                  <>
                    <Helmet
                      title={`${appTitle} - ${t('remoteIdentification.identifyYourself')}`}
                    />
                    <Outlet />
                  </>
                }
              >
                <Route
                  path="success"
                  element={
                    <LayoutOneColumn className="text-center">
                      <h2 className="mb-6">
                        {t('login.identificationSuccessful')}
                      </h2>

                      <Button
                        size="lg"
                        variant="outlined"
                        onClick={() => {
                          window.location.href = settings.countryWebsiteUrl;
                        }}
                      >
                        {t('errors.404.cta.notUser')}
                      </Button>
                    </LayoutOneColumn>
                  }
                />

                <Route index path="*" element={<IdentifyCustomer />} />
              </Route>

              <Route path="direct/*" element={<DirectApp />} />

              <Route
                path="login"
                element={
                  // TODO: rethink this condition as on login we are not redirecting from the path stored in local storage
                  // isEmpty(user) ? (
                  <>
                    <Helmet title={`${appTitle} - ${t('login.heading')}`} />
                    <LoginPage />
                  </>
                  // ) : (
                  //   <Navigate replace={true} to="/direct" />
                  // )
                }
              />

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Suspense>

        <ScrollToTop />

        <Dialog />
      </Layout>
    </SentryBoundary>
  );
};

export default CustomerWeb;
