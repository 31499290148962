import i18n from 'i18next';
import * as Yup from 'yup';

export const ReasonValidation = () => {
  return Yup.string()
    .required(
      `${i18n.t('applicationForm.commons.requestedAmountErrors.typeError')}`,
    )
    .required(`${i18n.t('applicationForm.commons.reasonErrors.required')}`);
};
