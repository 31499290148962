import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';

import { Error } from '../../fields/error';
import { Label } from '../../fields/label';

import styles from './TextArea.module.scss';

const TextArea = ({
  className,
  id,
  label = undefined,
  error,
  disabled = false,
  ...rest
}) => {
  return (
    <div className={classnames(styles.root, className)} disabled={disabled}>
      {label && (
        <Label className="mb-2" htmlFor={id}>
          {label}
        </Label>
      )}

      <textarea
        {...rest}
        id={id}
        disabled={disabled}
        className={classnames(
          styles.textarea,
          'd-block py-3 px-4 w-100 mw-100 rounded placeholder',
          {
            [styles.error]: error,
          },
        )}
      />

      {error && <Error className="mt-1">{error}</Error>}
    </div>
  );
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.node,
  id: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextArea;
