import React from 'react';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@krea/common/hooks';
import { ApplicationTypeSelectorButton } from '@krea/common/shared-components/application-type-button';
import { Error } from '@krea/common/shared-components/fields/error';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';

import { useLoanApplicationFormStore } from '../../../store/loan-application-form/hooks';
import { TEST_IDS } from '../../../utils';
import { REAL_ESTATE_REASONS } from '../../loan-application-form/fields/reason';

const RealEstateForm = ({
  control,
  goToPreviousStep,
  goToNextStep,
}: {
  control: Control<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  currentStep: string;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}) => {
  const isMobile = useMobile();
  const { t } = useTranslation();

  const { field: { value, onChange: onInputChange } = {}, fieldState } =
    useController({
      name: 'realEstateReason',
      control,
    });

  const { realEstateReason, setRealEstateReason } = useLoanApplicationFormStore(
    (state) => ({
      realEstateReason: state.realEstateReason,
      setRealEstateReason: state.setRealEstateReason,
    }),
  );

  const onChange = (value: string) => {
    onInputChange!(value);
    setRealEstateReason(value);
  };

  const buttonStylingOnMobile = 'tw-w-full tw-my-2 tw-rounded tw-text-primary';

  const innerForm = (
    <div>
      {fieldState.error?.message && fieldState.isTouched && (
        <Error className="tw-mt-2 tw-text-center">
          {fieldState.error?.message}
        </Error>
      )}
      {!isMobile && (
        <div>
          <div
            className="tw-flex tw-mt-4"
            style={{ justifyContent: 'space-evenly', height: '75px' }}
          >
            {/* redo me */}
            {REAL_ESTATE_REASONS.slice(0, 3).map((reason) => (
              <ApplicationTypeSelectorButton
                key={reason.value}
                text={reason.name}
                id={reason.value}
                setInputValue={onChange}
                value={value}
                data-test-id={TEST_IDS.common.forms.realEstateForm.reasonValue(
                  reason.value,
                )}
              />
            ))}
          </div>
          <div
            className="tw-flex tw-mb-8"
            style={{ justifyContent: 'space-evenly', height: '75px' }}
          >
            {/* redo me */}
            {REAL_ESTATE_REASONS.slice(3, 6).map((reason) => (
              <ApplicationTypeSelectorButton
                key={reason.value}
                text={reason.name}
                id={reason.value}
                setInputValue={onChange}
                value={value}
                data-test-id={TEST_IDS.common.forms.realEstateForm.reasonValue(
                  reason.value,
                )}
              />
            ))}
          </div>
        </div>
      )}
      {isMobile && (
        <div className="tw-pt-4 tw-pb-4">
          {REAL_ESTATE_REASONS.map((reason) => (
            <ApplicationTypeSelectorButton
              key={reason.value}
              text={reason.name}
              id={reason.value}
              setInputValue={onChange}
              value={value}
              className={buttonStylingOnMobile}
              data-test-id={TEST_IDS.common.forms.realEstateForm.reasonValue(
                reason.value,
              )}
            />
          ))}
        </div>
      )}
    </div>
  );

  return (
    <>
      <LoanApplicationFormLayout
        heading={t('applicationForm.common.realEstateHeading')}
        subheading={t('applicationForm.common.realEstateSubheading')}
        innerForm={innerForm}
        infoText={t('applicationForm.common.selectMainReason')}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        disabledNavigationBeforeFormCompletion={!realEstateReason}
      />
    </>
  );
};

export default RealEstateForm;
