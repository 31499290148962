import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { useMobile } from '@krea/common/hooks';
import Footer from '@krea/common/shared-components/app/footer';

import Header from './../header/index';
import styles from './Layout.module.scss';

const Layout = ({ children, MiddleNavbarComponent, applicationSelector }) => {
  const { pathname } = useLocation();
  const isNotLoginPage = pathname !== '/login';
  const isDelegate = pathname === '/delegated-information';
  const isIdentifyCustomer = pathname.includes('/identify');
  const isApplicationForm = pathname === '/application/';
  const isPreliminaryBidForm = pathname.includes('/amount-estimator');
  const isMobile = useMobile();

  return (
    <div className="d-flex flex-column h-100 w-100 overflow-hidden">
      <Header
        MiddleNavbarComponent={!isMobile && MiddleNavbarComponent}
        applicationSelector={applicationSelector}
      />
      <main
        className={classnames(
          { 'mt-12': (isApplicationForm && isMobile) || isPreliminaryBidForm },
          {
            [styles.main]: !(
              (isApplicationForm && isMobile) ||
              isPreliminaryBidForm
            ),
          },
          {
            [styles.login]:
              !isNotLoginPage ||
              isDelegate ||
              isIdentifyCustomer ||
              !isApplicationForm,
          },

          'flex-grow-1 shrink-0',
        )}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
