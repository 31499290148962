import loadable from '@loadable/component';
import classnames from 'classnames';
import i18n from 'i18next';
import React, { useRef, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import { TEST_IDS, isMobile } from '@krea/common/utils';

import Button from '../button';
import TextInput from '../fields/input';
import { Label } from '../fields/label';
import { Helper } from '../helper';
import { Error } from '../fields/error';

import styles from './AppFormSlider.module.scss';
import { Direction } from 'react-range';

const Range = loadable(() => import('react-range').then((mod) => mod.Range));

interface ISlider {
  id: string;
  label: string;
  value: number;
  htmlFor: string;
  values: number[];
  error: string;
  onSliderChange: (values: number[]) => void;
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  postfix?: string | null;
  formatter: (value: number) => string;
  inputFormatter?: (value: number) => string;
  isCustomInput: boolean;
  animating: boolean;
  labelClassName?: string;
  tooltip?: string;
  period?: boolean;
  name?: string;
  onCustomToggle: () => void;
  inputOnBlur: () => void;
}

export const Slider = (props: ISlider) => {
  const {
    id,
    label,
    value,
    htmlFor,
    values,
    error,
    onSliderChange,
    onValueChange,
    placeholder,
    postfix,
    formatter,
    inputFormatter,
    isCustomInput,
    animating,
    labelClassName,
    tooltip,
    period,
    onCustomToggle,
    inputOnBlur,
  } = props;

  const thumbRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const valueIndex = values.indexOf(value);
  const valuesLength = values.length;

  const inputValue = (value: number) => {
    if (value !== 0) {
      if (inputFormatter && value) {
        return inputFormatter(value);
      } else {
        return value;
      }
    } else {
      return '';
    }
  };

  const onLabelClick = () => {
    thumbRef.current?.focus();
  };

  const onInputLabelClick = () => {
    inputRef.current?.focus();
  };

  const onCustomToggleClick = async () => {
    await onCustomToggle();
    inputRef.current && inputRef.current?.focus();
  };

  return (
    <div
      className={styles.root}
      id={id}
      tabIndex={0}
      aria-label={`${label} ${value.toString().replace(/\s/g, '')}`}
    >
      <div className="tw-flex tw-flex-col tw-w-full">
        <div
          className={classnames(
            {
              'tw-items-center': isCustomInput,
              'tw-flex-col sm:tw-flex-row tw-items-start sm:tw-items-center':
                !isCustomInput,
            },
            'tw-flex tw-flex-wrap tw-justify-between',
          )}
        >
          {!isCustomInput ? (
            <label
              className={classnames(
                styles.label,
                'tw-flex tw-flex-wrap tw-m-0 tw-p-0',
                labelClassName,
              )}
              onClick={onLabelClick}
              htmlFor={htmlFor}
              aria-labelledby={htmlFor}
              style={isMobile() ? { fontSize: '15px' } : { fontSize: '16px' }}
            >
              <span className="tw-block tw-mr-2">{label}</span>

              {!isCustomInput && (
                <span
                  className={classnames('tw-block', styles.value, {
                    'tw-mr-1': tooltip,
                  })}
                >
                  {formatter(value)}
                </span>
              )}
              {tooltip && (
                <Helper
                  className="tw-inline-block tw-align-middle"
                  content={tooltip}
                />
              )}
            </label>
          ) : (
            <Label
              className="tw-block tw-mr-2"
              htmlFor={htmlFor}
              aria-label={htmlFor}
            >
              <span
                className={classnames('tw-align-middle', {
                  'tw-mr-1': tooltip,
                })}
                onClick={onInputLabelClick}
              >
                {label}
              </span>
              {tooltip && (
                <Helper
                  className="tw-inline-block tw-align-middle"
                  content={tooltip}
                />
              )}
            </Label>
          )}

          <Button
            variant="text"
            size="sm"
            className="tw-mb-0 tw-shrink-0"
            onClick={onCustomToggleClick}
            data-test-id={TEST_IDS.common.forms.loanAmountSliderOrInputSwitch}
          >
            {isCustomInput
              ? i18n.t('slider.backToSlider')
              : period
                ? i18n.t('applicationForm.commons.exactRequestedPeriodButton')
                : i18n.t('applicationForm.commons.exactRequestedAmountButton')}
          </Button>
        </div>

        {isCustomInput ? (
          <TextInput
            ref={inputRef}
            size="lg"
            type="text"
            inputMode="numeric"
            textAppend={postfix}
            id={htmlFor}
            className="tw-my-2"
            autoComplete="off"
            onChange={onValueChange}
            value={inputValue(value)}
            placeholder={placeholder}
            isFocused={isCustomInput}
            error={error}
            onBlur={inputOnBlur}
            data-test-id={TEST_IDS.common.forms.loanAmountInput}
          />
        ) : (
          <div
            className="tw-mx-4 tw-px-4 tw-mb-1 tw-overflow-hidden"
            data-test-id={TEST_IDS.common.forms.loanAmountSlider}
          >
            <Range
              step={1}
              min={0}
              max={valuesLength > 0 ? valuesLength - 1 : 0}
              values={[valueIndex < 0 ? 0 : valueIndex]}
              onChange={onSliderChange}
              renderTrack={({ props, children }) => (
                <div
                  className={classnames(
                    styles.range,
                    'tw-flex tw-items-center tw-mb-0 tw-w-full',
                  )}
                >
                  <div
                    {...props}
                    className={classnames(
                      styles.track,
                      'tw-relative tw-rounded tw-w-full',
                      {
                        [styles.animating]: animating,
                      },
                    )}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props }) => {
                return (
                  <Fragment key="0">
                    <div
                      {...props}
                      ref={thumbRef}
                      className={classnames(styles.thumb, 'tw-rounded-full', {
                        [styles.animating]: animating,
                      })}
                      style={{ ...props.style, zIndex: '2' }}
                      aria-valuenow={parseInt(
                        value.toString().replace(/\s/g, ''),
                      )}
                      aria-valuetext={value.toString().replace(/\s/g, '')}
                      aria-valuemax={
                        valuesLength > 0 && values[values.length - 1]
                          ? values[values.length - 1]
                          : 0
                      }
                      aria-valuemin={
                        valuesLength > 0 && values[0] ? values[0] : 0
                      }
                      id={htmlFor}
                    />
                    <div
                      className={classnames(
                        styles.activeRange,
                        'tw-absolute tw-h-full',
                        {
                          [styles.animating]: animating,
                        },
                      )}
                      style={{
                        width: `${Math.min(100, (valueIndex / (valuesLength - 1)) * 100)}%`,
                      }}
                    />
                  </Fragment>
                );
              }}
              direction={Direction.Right}
              allowOverlap={false}
              disabled={false}
              rtl={false}
            />
            {error && <Error className="tw-mt-1">{error}</Error>}
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(Slider);
