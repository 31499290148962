import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useMobile } from '@krea/common/hooks';
import { settings } from '@krea/common/settings';
import LoanApplicationFormLayout from '@krea/common/shared-components/loan-application-form-layout';
import { Text } from '@krea/common/shared-components/text';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { useModalStore } from '@krea/common/store/modal/hooks';
import {
  APPLICATION_NAME,
  COUNTRY_CODE,
  termsOfServiceLink,
} from '@krea/common/utils';

import BankIDFlow from '../bankID/BankIDFlow';
import FIBankIDFlow from '../bankID-fi/FIBankIDFlow';
import Button from '../button';
import LoginState from '../login/LoginState';

const IdentificationStep = ({
  onBack,
  onSuccessBankID,
  onCancel,
}: {
  onBack: () => void;
  onSuccessBankID: () => void;
  onCancel: () => void;
}) => {
  const isMobile = useMobile();
  const { t, i18n } = useTranslation();
  const companyName = useLoanApplicationFormStore(
    (state) => state.organisation.name,
  );
  const { openModal, closeModal } = useModalStore(
    useShallow(({ openModal, closeModal }) => ({
      openModal,
      closeModal,
    })),
  );

  const { errorCode, removeFormError } = useLoanApplicationFormStore(
    useShallow((state) => ({
      errorCode: state.formErrors.errorCode,
      removeFormError: state.removeFormError,
    })),
  );

  const innerForm = (
    <div className="tw-pt-8 tw-px-4 tw-pb-8">
      {errorCode === 1007 ? (
        <>
          <LoginState className="tw-mt-2" error>
            <div>
              <p>
                {i18n.t(
                  'remoteIdentification.fraudClientErrorPage.description',
                )}
              </p>
              <span>
                {i18n.t(
                  'remoteIdentification.fraudClientErrorPage.descriptionNewLine',
                )}
              </span>
            </div>
          </LoginState>
          <div className="tw-mt-4 tw-flex tw-justify-center">
            <Button
              onClick={() => {
                openModal({
                  modal: null,
                  closeOnBackgroundClick: false,
                  onCloseSideEffects: null,
                  isSupportTabOpen: true,
                });
                // setFormErrors({ errorCode: undefined });
              }}
            >
              {i18n.t(
                'commons.contactCustomerServiceDialog.contactCustomerService',
              )}
            </Button>
          </div>
        </>
      ) : null}

      {settings.countryCode === COUNTRY_CODE.SE && !errorCode ? (
        <BankIDFlow
          onSuccess={onSuccessBankID}
          onCancel={onCancel}
          authenticationContext={APPLICATION_NAME.CUSTOMER_WEB}
          hideHeading
        />
      ) : settings.countryCode === COUNTRY_CODE.FI && !errorCode ? (
        <FIBankIDFlow onSuccess={onSuccessBankID} onCancel={onCancel} />
      ) : null}

      <Text size="sm" className="tw-text-center tw-pr-1 tw-mt-4">
        <Trans i18nKey="applicationForm.common.acceptTermsAndConditions">
          Placeholder: By identifying you accept our &nbsp;
          <a
            target="_blank"
            href={termsOfServiceLink(
              settings.countryCode,
              settings.countryWebsiteUrl,
              i18n.language,
            )}
            rel="noopener noreferrer"
            className="font-weight-bold"
          >
            Placeholder: terms and conditions
          </a>
        </Trans>
      </Text>
    </div>
  );

  return (
    <>
      <LoanApplicationFormLayout
        heading={t('applicationForm.common.identificationHeading')}
        subheading={t('applicationForm.common.weAreObligedToIdentifyYou', {
          companyName,
        })}
        upperInfoText={
          <span className="tw-font-bold">
            {settings.countryCode === COUNTRY_CODE.SE
              ? t('applicationForm.common.noApplicationOrCreditCheck')
              : t('applicationForm.common.noApplication')}
          </span>
        }
        innerForm={innerForm}
        hideAdvisorCTABanner
        hideNavigation
        warningInfoTextIndex={1}
        goToNextStep={() => {}}
        goToPreviousStep={() => {}}
      />
    </>
  );
};

export default IdentificationStep;
