import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  LENDER_RESPONSES_STORE_KEY,
  USER_STORE_KEY,
} from '@krea/common/store/queryClient';
import { IMeUser } from '@krea/common/types/user';
import { fetchLenderResponsesData } from 'store/lender-responses/api';

const useGetQueryArguments = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<IMeUser>([USER_STORE_KEY]);
  const loanApplicationID = user?.currentLoanApplication?.id;

  return {
    queryKey: [LENDER_RESPONSES_STORE_KEY, loanApplicationID],
    queryFn: async () => {
      const response = await fetchLenderResponsesData();
      const responseData = response?.me?.currentLoanApplication || {};

      return responseData;
    },
    enabled: !!loanApplicationID,
  };
};

const useGetLenderResponses = () => {
  const queryArguments = useGetQueryArguments();

  return useQuery({
    ...queryArguments,
    refetchInterval: 20 * 1000,
    refetchIntervalInBackground: true,
  });
};

const usePrefetchLenderResponses = () => {
  const queryClient = useQueryClient();

  const queryArguments = useGetQueryArguments();

  return () => queryClient.prefetchQuery(queryArguments);
};

export { useGetLenderResponses, usePrefetchLenderResponses };
