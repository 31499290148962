import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';

export const getDefaultValues = () => {
  const state = useLoanApplicationFormStore.getState();
  const {
    organisation,
    email,
    mobilePhoneNumber,
    reason,
    realEstateReason,
    unpaidInvoices,
    unpaidInvoicesDescription,
    propertyUnitDesignation,
    leasing,
    requestedLoanAmount,
    realEstateRequestedLoanAmount,
  } = state;

  return {
    organisation,
    email,
    mobilePhoneNumber,
    reason,
    realEstateReason,
    unpaidInvoices,
    unpaidInvoicesDescription,
    propertyUnitDesignation,
    leasing,
    requestedLoanAmount,
    realEstateRequestedLoanAmount,
  };
};

export const chunkArray = (array: Array<any>, size: number) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};
