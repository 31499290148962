import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { getPrimaryLanguage } from '@krea/common/utils';

// Disable localStorage usage for language detection and rely on cookies instead
// localStorage is not available in some browsers some times and causes crashes.
const languageDetector = new LanguageDetector(null, {
  caches: ['cookie'],
  lookupLocalStorage: null,
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        customerWeb: require('./translations/customer-web/en.json'),
        common: require('@krea/common/translations/common/en.json'),
      },
      sv: {
        customerWeb: require('./translations/customer-web/sv.json'),
        common: require('@krea/common/translations/common/sv.json'),
      },
      fi: {
        customerWeb: require('./translations/customer-web/fi.json'),
        common: require('@krea/common/translations/common/fi.json'),
      },
    },
    lng: getPrimaryLanguage(),
    fallbackLng: 'en',
    supportedLngs: ['sv', 'en', 'fi'],
    ns: ['customerWeb', 'common'],
    defaultNS: 'customerWeb',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'ul', 'li', 'a'],
    },
  });

export default i18n;
