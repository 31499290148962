import axios from 'axios';

import { deleteUtmParamsFromCookie } from '@krea/common/services/queryParamsService';

export const createApplication = async (payload) => {
  const GQL = `
    mutation CreateLoanApplication($input: CreateApplicationInput!) {
      createLoanApplication(input: $input) {
      __typename,
      ... on OperationInfo {
        messages {
          message
          code
          kind
          field
        }
      }
      ... on NoContent {
        ok
      }
    }
  }
  `;

  try {
    const response = await axios.post('/api/graphql', {
      query: GQL,
      variables: { input: payload },
    });

    if (response.data.errors) {
      throw response.data;
    }
    const data = response.data.data?.createLoanApplication;

    if (data?.__typename === 'OperationInfo') {
      throw data;
    }

    deleteUtmParamsFromCookie();

    return data;
  } catch (err) {
    throw err;
  }
};
