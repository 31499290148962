import axios from 'axios';

import { client } from '@krea/common/graphql';
import {
  IInvoicePurchaseBid,
  ICompanyLoanBid,
  ILeasingBid,
  IRealEstateBid,
  IRevolvingCreditBid,
} from '@krea/common/types/lenderResponse';

export interface IFetchLenderResponsesResponse {
  me: {
    currentLoanApplication: {
      invoicePurchaseBids: IInvoicePurchaseBid[];
      companyLoanBids: ICompanyLoanBid[];
      realEstateBids: IRealEstateBid[];
      leasingBids: ILeasingBid[];
      revolvingCreditBids: IRevolvingCreditBid[];
    };
  };
}

export const fetchLenderResponsesData = async () => {
  return await client.request<IFetchLenderResponsesResponse>(`{
    me {
      currentLoanApplication {
        invoicePurchaseBids {
          id,
          totalPaidOut,
          purchasedInvoiceAmount,
          commissionPercent,
          handlingFeePerInvoice,
          monthlyFee,
          purchasedAmountPercent,
          totalMonthlyCost,
          purchaseLimit,
          limitFeePerYearPercent,
          setupFee,
          withRegress,
          regressNumDaysAfterDueDate,
          paidOutPerInvoice,
          paidOutPerInvoicePercent,
          comparableYearlyInterestRate,
          firstMonthInterestRate,
          comparableMonthlyInterestRate,
          firstMonthCost,  
          lenderResponse {
            id,
            type,
            benefits {
              type,
              displayName,
              explanation
            },
            attractivenessScore,
            isRecommendedSelect,
            fieldRequests {
              id,
              type,
              displayName,
              field,
              isSubmitted,
              isCollectedByLender,
              lenderComment
            },
            lenderApplication {
              id,
              createdAt,
              updatedAt,
              lenderCompany {
                id,
                name,
                description,
                factoringCompanyDescription,
                registeredAtFinansinspektionenAt,
                signingUrlTemplate,
                image {
                  lowResolutionUrl
                }
              }
            }
          }
        },
        companyLoanBids {
          id,
          amount,
          paybackTimeMonths,
          lenderMonthlyInterest
          monthlyCostWithoutSetupFee,
          amortizationMethod,
          amortizationMethodDisplayName,
          amortizationMethodDescription,
          monthlyCost,
          totalLoanCost,
          createdAt,
          updatedAt,
          postalFee,
          setupFee,
          totalCost,
          earlyRepaymentFee,
          directDebitFee,
          isRepayableWithoutFee,
          comparableYearlyInterestRate,
          firstMonthInterestRate,
          comparableMonthlyInterestRate, 
          firstMonthCost,
          otherFees {
            id,
            name,
            type,
            interval,
            feeAmount,
            feePercent,
          },
          lenderResponse {
            id,
            type,
            benefits {
              type,
              displayName,
              explanation
            },
            attractivenessScore,
            isRecommendedSelect,
            fieldRequests {
              id,
              type,
              displayName,
              field,
              isSubmitted,
              isCollectedByLender,
              lenderComment
            },
            lenderApplication {
              id,
              createdAt,
              updatedAt,
              lenderCompany {
                id,
                name,
                description,
                registeredAtFinansinspektionenAt,
                signingUrlTemplate,
                image {
                  lowResolutionUrl
                }
              }
            }
          }
        },
        realEstateBids {
          id,
          amount,
          paybackTimeMonths,
          lenderMonthlyInterest
          monthlyCostWithoutSetupFee,
          amortizationMethod,
          amortizationMethodDisplayName,
          amortizationMethodDescription,
          monthlyCost,
          totalLoanCost,
          createdAt,
          updatedAt,
          postalFee,
          setupFee,
          totalCost,
          earlyRepaymentFee,
          directDebitFee,
          isRepayableWithoutFee,
          loanToValueRatio,
          comparableYearlyInterestRate,
          firstMonthInterestRate,
          comparableMonthlyInterestRate,
          firstMonthCost,
          lenderResponse {
            id,
            type,
            benefits {
              type,
              displayName,
              explanation
            },
            attractivenessScore,
            isRecommendedSelect,
            fieldRequests {
              id,
              type,
              displayName,
              field,
              isSubmitted,
              isCollectedByLender,
              lenderComment
            },
            lenderApplication {
              id,
              createdAt,
              updatedAt,
              lenderCompany {
                id,
                name,
                description,
                registeredAtFinansinspektionenAt,
                signingUrlTemplate,
                image {
                  lowResolutionUrl
                }
              }
            }
          }
        },
        leasingBids {
          id,
          amount,
          paybackTimeMonths,
          monthlyPayment,
          totalCost,
          downPayment,
          terminalValue,
          setupFee,
          contractType,
          postalFee,
          insuranceMonthlyCost,
          comparableYearlyInterestRate,
          firstMonthInterestRate,
          comparableMonthlyInterestRate,
           firstMonthCost,
          lenderResponse {
            id,
            type,
            benefits {
              type,
              displayName,
              explanation
            },
            attractivenessScore,
            isRecommendedSelect,
            fieldRequests {
              id,
              type,
              displayName,
              field,
              isSubmitted,
              isCollectedByLender,
              lenderComment
            },
            lenderApplication {
              id,
              createdAt,
              updatedAt,
              lenderCompany {
                id,
                name,
                description,
                registeredAtFinansinspektionenAt,
                signingUrlTemplate,
                image {
                  lowResolutionUrl
                }
              }
            }
          }
        },
        revolvingCreditBids {
          id,
          createdAt,
          updatedAt,
          earlyRepaymentFee,
          postalFee,
          directDebitFee,
          setupFee,
          amount,
          monthlyAmortisation,
          lenderMonthlyInterest,
          withdrawalFee,
          setupFee,
          limitFeePerYear,
          postalFee,
          monthlyPayment,
          monthlyCost,
          comparableYearlyInterestRate,
          firstMonthInterestRate,
          comparableMonthlyInterestRate,
          firstMonthCost,
          lenderResponse {
            id,
            type,
            benefits {
              type,
              displayName,
              explanation
            },
            attractivenessScore,
            isRecommendedSelect,
            fieldRequests {
              id,
              type,
              displayName,
              field,
              isSubmitted,
              isCollectedByLender,
              lenderComment
            },
            lenderApplication {
              id,
              createdAt,
              updatedAt,
              lenderCompany {
                id,
                name,
                description,
                registeredAtFinansinspektionenAt,
                signingUrlTemplate,
                image {
                  lowResolutionUrl
                }
              }
            }
          }
        }
      }
    }
  }`);
};

export const selectLenderResponse = async (lenderResponseID: string) => {
  return axios.post(`/api/v1/lender-response/${lenderResponseID}/select`);
};

export const eligibleForPayment = async (lenderResponseID: string) => {
  return axios.post(
    `/api/v1/lender-response/${lenderResponseID}/mark-as-eligible-for-payment`,
  );
};
