import classnames from 'classnames';

import { Text } from '@krea/common/shared-components/text';
import { isMobile } from '@krea/common/utils';

const LabeledRadioButton = ({ title, name, value, checked, onChange }) => {
  return (
    <label
      role="button"
      tabIndex="0"
      aria-label={title}
      aria-pressed={checked}
      className={classnames(
        'd-flex align-items-center border border-primary-ultralight rounded position-relative w-100 py-3 px-3',
        {
          'bg-gray': checked,
        },
      )}
    >
      <input
        type="radio"
        name={name}
        value={value}
        className="position-absolute invisible"
        checked={checked}
        onChange={onChange}
      />
      <div
        className={classnames(
          'd-flex shrink-0 mr-3 border rounded-circle justify-content-center align-items-center',
          {
            'border-primary': checked,
            'border-primary-ultralight': !checked,
          },
        )}
        style={{ width: '20px', height: '20px' }}
        ref={(el) => {
          if (el) {
            el.style.setProperty('border-width', '2px', 'important');
          }
        }}
      >
        {checked && (
          <div
            className="rounded-circle bg-primary"
            style={{ width: '8px', height: '8px' }}
          />
        )}
      </div>
      <Text
        size={isMobile ? 'sm' : 'md'}
        className={classnames('font-weight-bold', { 'text-primary': !checked })}
      >
        {title}
      </Text>
    </label>
  );
};

export default LabeledRadioButton;
