import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import * as CookieConsentPlugin from 'vanilla-cookieconsent';

import { getCookieDomain } from '@krea/common/services/cookieService';
import './CookieConsent.scss';
// Documentation on third party lib: https://github.com/orestbida/cookieconsent

const COOKIE_CONSENT_NAME = 'cookieConsents';

const CONSENT_STORAGE_STATE = {
  DENIED: 'denied',
  GRANTED: 'granted',
};

const COOKIE_CONSENT_CATEGORY = {
  ADS: 'ad_storage',
  ANALYTICS: 'analytics_storage',
  PERSONALIZATION: 'ad_personalization',
  USER_DATA: 'ad_user_data',
};

export const getConsentedCategories = () => {
  let categories: string[] = [];

  try {
    const cookie = CookieConsentPlugin.getCookie();

    if (!cookie) {
      return categories;
    }

    if (cookie.categories && cookie.categories.length > 0) {
      categories = cookie.categories;
    }
  } catch (e) {
    console.error('Error while parsing cookieConsents cookie', e);
  }

  return categories;
};

export const hasCookiesConsent = () => {
  const categories = getConsentedCategories();

  return [COOKIE_CONSENT_CATEGORY.ADS, COOKIE_CONSENT_CATEGORY.ANALYTICS].every(
    (category) => categories.includes(category),
  );
};

const removeAdCookies = () => {
  const adCookieNames = ['_ga', '_gid'];

  try {
    CookieConsentPlugin.eraseCookies(adCookieNames);
  } catch (e) {
    console.error('Error while removing ad cookies', e);
  }
};

const removeAnalyticsCookies = () => {
  const analyticsCookieNames = ['_ga', '_gid'];

  try {
    CookieConsentPlugin.eraseCookies(analyticsCookieNames);
  } catch (e) {
    console.error('Error while removing analytics cookies', e);
  }
};

const updateGTMConsents = (categories: string[]) => {
  const dataLayer: Record<string, string> = {
    event: 'update_gmt_consents',
  };

  // Handle ad_storage
  const adStorageGranted = categories.includes(COOKIE_CONSENT_CATEGORY.ADS);

  if (adStorageGranted) {
    dataLayer[COOKIE_CONSENT_CATEGORY.ADS] = CONSENT_STORAGE_STATE.GRANTED;
    dataLayer[COOKIE_CONSENT_CATEGORY.PERSONALIZATION] =
      CONSENT_STORAGE_STATE.GRANTED;
    dataLayer[COOKIE_CONSENT_CATEGORY.USER_DATA] =
      CONSENT_STORAGE_STATE.GRANTED;
  } else {
    dataLayer[COOKIE_CONSENT_CATEGORY.ADS] = CONSENT_STORAGE_STATE.DENIED;
    dataLayer[COOKIE_CONSENT_CATEGORY.PERSONALIZATION] =
      CONSENT_STORAGE_STATE.DENIED;
    dataLayer[COOKIE_CONSENT_CATEGORY.USER_DATA] = CONSENT_STORAGE_STATE.DENIED;
    removeAdCookies();
  }

  // Handle analytics_storage
  const analyticsStorageGranted = categories.includes(
    COOKIE_CONSENT_CATEGORY.ANALYTICS,
  );

  if (analyticsStorageGranted) {
    dataLayer[COOKIE_CONSENT_CATEGORY.ANALYTICS] =
      CONSENT_STORAGE_STATE.GRANTED;
  } else {
    dataLayer[COOKIE_CONSENT_CATEGORY.ANALYTICS] = CONSENT_STORAGE_STATE.DENIED;

    removeAnalyticsCookies();
  }

  TagManager.dataLayer({
    dataLayer,
  });
};

const CookieConsent = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const acceptedCategories = getConsentedCategories();
    const initialConsentIsMissing = acceptedCategories.length === 0;

    if (initialConsentIsMissing) {
      CookieConsentPlugin.run({
        autoClearCookies: true,
        // autoShow: false,
        // root: 'body',
        // autoShow: true,
        // disablePageInteraction: true,
        hideFromBots: false,
        // mode: 'opt-in',
        // revision: 0,

        cookie: {
          name: COOKIE_CONSENT_NAME,
          domain: getCookieDomain(),
          // path: '/',
          // sameSite: "Lax",
          // expiresAfterDays: 365,
        },

        // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
        guiOptions: {
          consentModal: {
            layout: 'box inline',
            position: 'bottom right',
            equalWeightButtons: false,

            flipButtons: true,
          },
          preferencesModal: {
            layout: 'box',
            equalWeightButtons: false,
            flipButtons: true,
          },
        },

        onConsent: ({ cookie }) => {
          const categories = cookie.categories;
          updateGTMConsents(categories);
        },

        onChange: ({ cookie }) => {
          const categories = cookie.categories;
          updateGTMConsents(categories);
        },

        categories: {
          necessary: {
            enabled: true, // this category is enabled by default
            readOnly: true, // this category cannot be disabled
          },
          analytics_storage: {
            autoClear: {
              cookies: [
                {
                  name: /^_ga/, // regex: match all cookies starting with '_ga'
                },
                {
                  name: '_gid', // string: exact cookie name
                },
              ],
            },

            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
          },
          ad_storage: {},
        },

        language: {
          default: i18n.resolvedLanguage ?? 'sv',
          translations: {
            en: {
              consentModal: {
                description:
                  'We use cookies to improve your experience on our website, analyze our traffic, and show you personalized ads and content. By clicking “Allow all,” you consent to <a href="https://krea.se/en/cookie-policy/" target="_blank">our use of cookies</a>.',
                acceptAllBtn: 'Accept all',
                showPreferencesBtn: 'Manage Individual preferences',
              },
              preferencesModal: {
                title: 'Cookie preferences',
                acceptAllBtn: 'Accept all',
                savePreferencesBtn: 'Accept current selection',
                closeIconLabel: 'Close',
                sections: [
                  {
                    description:
                      'We use cookies to improve your experience on our website, analyze our traffic, and show you personalized ads and content. By clicking “Allow all,” you consent to <a href="https://krea.se/en/cookie-policy/" target="_blank">our use of cookies</a>.',
                  },
                  {
                    title: 'Strictly necessary',
                    description:
                      'These cookies are essential for the proper functioning of our website. Without these cookies, the website would not work properly and therefore they can not be turned off.',
                    linkedCategory: 'necessary',
                  },
                  {
                    title: 'Performance and Analytics',
                    description:
                      'These cookies save information about how our website is used and enable us to improve our products.',
                    linkedCategory: 'analytics_storage',
                  },
                  {
                    title: 'Advertisement and Personalization',
                    description:
                      'These cookies are used to enhance your experience on our website and for marketing purposes. By sharing this information with our marketing partners, such as Google and Microsoft, we can show more relevant information and advertisements to you. They also help us create a more personalized experience by analyzing your interaction with our website, which allows us to customize the content and offers to your preferences and interests.',
                    linkedCategory: 'ad_storage',
                  },
                ],
              },
            },
            sv: {
              consentModal: {
                description:
                  'Vi använder cookies för att förbättra din upplevelse på vår webbplats, analysera vår trafik, samt för att visa personligt anpassade annonser och innehåll. Genom att klicka på “Tillåt alla” samtycker du till <a href="https://krea.se/information/cookies/" target="_blank">vår användning av cookies</a>.',
                acceptAllBtn: 'Tillåt alla',
                showPreferencesBtn: 'Hantera cookies',
              },
              preferencesModal: {
                title: 'Inställningar för cookies',
                acceptAllBtn: 'Tillåt alla',
                savePreferencesBtn: 'Spara inställningar',
                closeIconLabel: 'Close',
                sections: [
                  {
                    description:
                      'Vi använder cookies för att förbättra din upplevelse på vår webbplats, analysera vår trafik, samt för att visa personligt anpassade annonser och innehåll. Genom att klicka på “Tillåt alla” samtycker du till <a href="https://krea.se/information/cookies/" target="_blank">vår användning av cookies</a>.',
                  },
                  {
                    title: 'Nödvändiga',
                    description:
                      'Dessa cookies är nödvändiga för att vår webbplats ska fungera korrekt. Utan dessa cookies skulle webbplatsen inte fungera korrekt och därför kan de inte stängas av.',
                    linkedCategory: 'necessary',
                  },
                  {
                    title: 'Statistik',
                    description:
                      'Dessa cookies sparar information om hur vår webbplats används och ger oss möjligheter att förbättra våra produkter.',
                    linkedCategory: 'analytics_storage',
                  },
                  {
                    title: 'Marknadsföring och Personalisering',
                    description:
                      'Dessa cookies används för att förbättra din upplevelse på vår webbplats och för marknadsföringsändamål. Genom att dela denna information med våra marknadsföringspartners, såsom Google och Microsoft, kan vi visa mer relevant information och annonser för dig. De hjälper oss även att skapa en mer personlig upplevelse genom att analysera din interaktion med vår webbplats, vilket gör det möjligt för oss att personalisera innehållet och erbjudandena till dina preferenser och intressen.',
                    linkedCategory: 'ad_storage',
                  },
                ],
              },
            },
            fi: {
              consentModal: {
                description:
                  'Käytämme evästeitä parantaaksemme käyttökokemustasi verkkosivustollamme, analysoidaksemme liikennettämme sekä näyttääksemme sinulle personoituja mainoksia ja sisältöä. Klikkaamalla “Salli kaikki” annat suostumuksesi <a href="https://kredita.fi/kaytannot/evasteet/" target="_blank">evästeiden käyttöön</a>.',
                acceptAllBtn: 'Hyväksy kaikki',
                showPreferencesBtn: 'Hallinnoi evästeitä',
              },
              preferencesModal: {
                title: 'Evästeasetukset',
                acceptAllBtn: 'Hyväksy kaikki',
                savePreferencesBtn: 'Tallenna asetukset',
                closeIconLabel: 'Close',
                serviceCounterLabel: 'Service|Services',
                sections: [
                  {
                    description:
                      'Käytämme evästeitä parantaaksemme käyttökokemustasi verkkosivustollamme, analysoidaksemme liikennettämme sekä näyttääksemme sinulle personoituja mainoksia ja sisältöä. Klikkaamalla “Salli kaikki” annat suostumuksesi <a href="https://kredita.fi/kaytannot/evasteet/" target="_blank">evästeiden käyttöön</a>.',
                  },
                  {
                    title: 'Välttämättömät evästeet',
                    description:
                      'Nämä evästeet ovat välttämättömiä verkkosivustomme oikeanlaisen toiminnan kannalta. Ilman näitä evästeitä verkkosivusto ei toimisi kunnolla, eikä niitä siksi voi laittaa pois.',
                    linkedCategory: 'necessary',
                  },
                  {
                    title: 'Suorituskyky ja analytiikka',
                    description:
                      'Nämä evästeet tallentavat tietoja verkkosivustomme käytöstä ja auttavat meitä parantamaan tuotteitamme.',
                    linkedCategory: 'analytics_storage',
                  },
                  {
                    title: 'Markkinointi ja Personointi',
                    description:
                      'Näitä evästeitä käytetään parantamaan kokemustasi verkkosivustollamme ja markkinointitarkoituksiin. Jakamalla nämä tiedot markkinointikumppaneidemme, kuten Googlen ja Microsoftin kanssa, voimme näyttää sinulle merkityksellisempää tietoa ja mainoksia. Evästeet auttavat meitä analysoimaan vuorovaikutusta verkkosivuillamme ja luomaan henkilökohtaisemman kokemuksen, joka mahdollistaa sisällön ja tarjousten räätälöinnin mieltymyksesi ja kiinnostuksenkohteidesi mukaan.',
                    linkedCategory: 'ad_storage',
                  },
                ],
              },
            },
          },
        },
      });
    }
  }, [i18n.resolvedLanguage]);

  return null;
};

export default CookieConsent;
