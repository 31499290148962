import { useQuery } from '@tanstack/react-query';

import { hasCookiesConsent } from '@krea/common/shared-components/cookie-consent';
import { CURRENT_LOAN_APPLICATION_STORE_KEY } from '@krea/common/store/queryClient';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';

import { fetchCurrentLoanApplicationData } from 'store/current-loan-application/api';

import {
  useTrackLoanApplicationDraftConversion,
  useTrackLoanApplicationRateForecastExecuted,
} from '../mutations';

export const useGetCurrentLoanApplication = () => {
  const { data } = useGetUserData();
  const loanApplicationID = data?.currentLoanApplication?.id;

  const { mutate: trackLoanApplicationDraftConversion } =
    useTrackLoanApplicationDraftConversion();
  const { mutate: trackLoanApplicationForecastExecuted } =
    useTrackLoanApplicationRateForecastExecuted();

  return useQuery({
    queryKey: [CURRENT_LOAN_APPLICATION_STORE_KEY, loanApplicationID],
    queryFn: async () => {
      try {
        const response = await fetchCurrentLoanApplicationData();
        const currentLoanApplication =
          response?.me?.currentLoanApplication || {};

        const {
          shouldTrackDraftConversion,
          shouldTrackRateForecastExecutedConversion,
        } = currentLoanApplication;

        if (hasCookiesConsent()) {
          if (shouldTrackDraftConversion) {
            trackLoanApplicationDraftConversion({ loanApplicationID });
          }

          if (shouldTrackRateForecastExecutedConversion) {
            trackLoanApplicationForecastExecuted({ loanApplicationID });
          }
        }

        return currentLoanApplication;
      } catch (err) {
        console.error(
          'Failed to fetch current loan application data: err',
          err,
        );

        return {};
      }
    },
    enabled: !!loanApplicationID,
  });
};
