import loadable from '@loadable/component';
import { isEmpty } from 'lodash';
import React, { Suspense, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { settings } from '@krea/common/settings';
import Preloader from '@krea/common/shared-components/preloader';
import { prefetchFieldRequests } from '@krea/common/store/field-requests/hooks/queries';
import { useGetUserData } from '@krea/common/store/user/hooks/queries';

import { useGetCurrentLoanApplication } from 'store/current-loan-application/hooks/queries';
import { usePrefetchLenderResponses } from 'store/lender-responses/hooks/queries';

import PrivateRoute from './private-route';
import RootRedirect from './root-redirect';

const ApplicationQuestions = loadable(
  () => import('./pages/improve-application-questions'),
);
const AdvicePage = loadable(() => import('./pages/advice'));
const Dashboard = loadable(() => import('./pages/dashboard'));
const PostSelectBid = loadable(() => import('./pages/post-select'));
const NotFound = loadable(() => import('../pages/not-found'));

function DirectApplicationFlow() {
  const { t } = useTranslation();
  const { data: user = {} } = useGetUserData();
  const { data: currentLoanApplication = {}, isLoading } =
    useGetCurrentLoanApplication();

  const {
    id: currentLoanApplicationID,
    state,
    applyingCompany,
  } = currentLoanApplication;
  const customerCompanyID = applyingCompany?.customerCompany?.id;

  const { appTitle } = settings;

  useEffect(() => {
    // for google tag manager

    !isEmpty(user) &&
      currentLoanApplicationID &&
      customerCompanyID &&
      TagManager.dataLayer({
        dataLayer: {
          event: 'login',
          user_id: user.id,
          customer_company_id: customerCompanyID,
          loan_application_id: currentLoanApplicationID,
        },
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentLoanApplicationID, customerCompanyID]);

  const prefetchLenderResponses = usePrefetchLenderResponses();

  useEffect(() => {
    if (currentLoanApplicationID) {
      prefetchLenderResponses();
      prefetchFieldRequests(currentLoanApplicationID);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLoanApplicationID]);

  if (!isEmpty(user) && (isLoading || !state)) {
    return (
      <div
        className="d-flex justify-content-center align-items-center bg-white w-100"
        style={{ minHeight: '400px' }}
      >
        <Preloader className="text-primary" size="xl" />
      </div>
    );
  }

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<RootRedirect />} />

          <Route
            path="loan-application-form"
            element={<Navigate replace={true} to="/application" />}
          />

          <Route
            path="factoring-application-form"
            element={<Navigate replace={true} to="/application" />}
          />

          <Route
            path="real-estate-application-form"
            element={<Navigate replace={true} to="/application" />}
          />

          <Route
            path="leasing-application-form"
            element={<Navigate replace={true} to="/application" />}
          />

          <Route
            path="preliminary-bid-form"
            element={<Navigate replace={true} to={'/amount-estimator'} />}
          />

          <Route
            path="improve-application-questions/*"
            element={
              <PrivateRoute>
                <Helmet
                  title={`${appTitle} - ${t('commons.app.title.improveApp')}`}
                />
                <ApplicationQuestions />
              </PrivateRoute>
            }
          />

          <Route
            path="dashboard"
            element={
              <PrivateRoute restrictedBasedOnState>
                <Helmet
                  title={`${appTitle} - ${t('commons.app.title.dashboard')}`}
                />
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="advice"
            element={
              <PrivateRoute restrictedBasedOnState>
                <Helmet
                  title={`${appTitle} - ${t('commons.app.title.advice')}`}
                />
                <AdvicePage />
              </PrivateRoute>
            }
          />

          <Route
            path="selected-lender-response"
            element={
              <PrivateRoute restrictedBasedOnState>
                <Helmet
                  title={`${appTitle} - ${t('commons.app.title.selectedLenderResponse')}`}
                />
                <PostSelectBid />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default DirectApplicationFlow;
