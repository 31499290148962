import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useFormatCurrency, useMobile } from '@krea/common/hooks';
import { settings } from '@krea/common/settings';
import { Text } from '@krea/common/shared-components/text';
import { useLoanApplicationFormStore } from '@krea/common/store/loan-application-form/hooks';
import { COUNTRY_CODE } from '@krea/common/utils';

const PreliminaryBidResult = ({ isOnResultPage = false }) => {
  const { t } = useTranslation();
  const [formattedCurrencyValue] = useFormatCurrency({
    currency: settings.currency,
  });

  const { organisationName, preliminaryBid = {} } = useLoanApplicationFormStore(
    useShallow((state) => ({
      organisationName: state.organisation.name,
      preliminaryBid: state.preliminaryBid,
    })),
  );

  const probabilityOptions = {
    low: t('preliminaryBidForm.probabilityOptions.low'),
    medium: t('preliminaryBidForm.probabilityOptions.medium'),
    high: t('preliminaryBidForm.probabilityOptions.high'),
  };
  const estimatedLowerAmount = preliminaryBid.estimatedMinLoanAmount;
  const estimatedAmount = preliminaryBid.estimatedMaxLoanAmount;
  const exceedsMaxEstimateAmount = preliminaryBid.useEdgeCaseLogic;
  const maxAccuracyAmount =
    settings.countryCode === COUNTRY_CODE.SE ? 3000000 : 300000;
  const probability =
    probabilityOptions[preliminaryBid.probabilityLoanApproved];

  const isMobile = useMobile();
  const fontSize = isMobile ? 'lg' : 'xxl';

  return !isEmpty(preliminaryBid) ? (
    <>
      {isOnResultPage ? (
        <div className="w-100 p-5 p-lg-7 border-gray-light tw-border tw-my-7">
          <div>
            <Text size="xxl" className="mb-2 font-weight-bold">
              {t('preliminaryBidForm.ourEstimateFor', { organisationName })}
            </Text>

            <br />
            {!exceedsMaxEstimateAmount && (
              <Text size={fontSize} className="font-weight-normal">
                {t('preliminaryBidForm.estimatedCreditLimit')}{' '}
                <b className="text-primary">
                  {' '}
                  {formattedCurrencyValue(estimatedLowerAmount)}
                  {' - '}
                  {formattedCurrencyValue(estimatedAmount)}
                </b>
              </Text>
            )}
            {exceedsMaxEstimateAmount && (
              <Text size={fontSize} className="my-3 font-weight-normal">
                {t('preliminaryBidForm.ourEstimateIsAccurateUpTo')}{' '}
                {formattedCurrencyValue(maxAccuracyAmount)}
              </Text>
            )}
          </div>
        </div>
      ) : !exceedsMaxEstimateAmount ? (
        <div className="w-100 px-7 py-3" style={{ backgroundColor: '#FBF2FF' }}>
          <p className="mb-0">
            {t('preliminaryBidForm.weEstimateThatCompanyCanReceive', {
              organisationName,
            })}{' '}
            <b>{formattedCurrencyValue(estimatedAmount)}</b> &nbsp;
          </p>
        </div>
      ) : null}
    </>
  ) : null;
};

export default PreliminaryBidResult;
