import i18n from 'i18next';
import * as Yup from 'yup';

export const PropertyUnitValidation = () => {
  return Yup.string().required(
    `${i18n.t(
      'applicationForm.realEstate.fields.propertyUnitDesignation.errors.required',
    )}`,
  );
};
