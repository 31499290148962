import i18n from 'i18next';
import * as Yup from 'yup';

export const RequestedLoanAmountValidation = () => {
  return Yup.number()
    .moreThan(
      0,
      `${i18n.t('applicationForm.commons.requestedAmountErrors.invalidAmount')}`,
    )
    .typeError(
      `${i18n.t('applicationForm.commons.requestedAmountErrors.typeError')}`,
    )
    .required(
      `${i18n.t('applicationForm.commons.requestedAmountErrors.required')}`,
    );
};
